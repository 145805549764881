import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '@app/_services/menu.service';
import {AlertService,} from '@app/_services';
import { EquiposService } from '@app/_services/equipos.service';
import { Temporada } from '@app/_models/equipo';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-temporadas',
  templateUrl: './temporadas.component.html'
})
export class TemporadasComponent implements OnInit {

  listaTemporadas:Temporada[]=[];
  seleccionados:number[];
  idEquipo:number;
  idOrg:string;
  translate;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    private equiposService: EquiposService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.idEquipo =this.route.snapshot.params['idequipo'];
    this.idOrg =this.route.snapshot.params['idorg'];
  }

  ngOnInit(): void {
    this.equiposService.getAllTemporadas(this.idEquipo).subscribe((result)=>{
      this.listaTemporadas = result.data;
    });
    console.log('Temporadas cargadas');
  }
  onClickNuevo(){
    console.log('Creando Temporada: ');
    
  }

  onClickEditar(){
    if(this.seleccionados.length>0)
    this.router.navigate([
      'temporada-editar',
      { idorg: this.idOrg, idequipo:this.idEquipo,idtemporada:this.seleccionados[0]},
    ]);
  }
  cellClick(event){
    this.router.navigate([
      'temporada-editar',
      { idorg: this.idOrg, idequipo:this.idEquipo,idtemporada:event.dataItem.id},
    ]);
  }
  onClickEliminar(content){
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }
  eliminarRegistro(contentloading){
    console.log(this.seleccionados);
    this.equiposService.deleteTemporadas({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.equiposService.getAllTemporadas(this.idEquipo).subscribe((result) => {
            this.listaTemporadas = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }
  
  goBack(){
    this.router.navigate([
      'equipos-editar',
      { idorg: this.idOrg, idEquipo:this.idEquipo },
    ]);
  }

  
}
