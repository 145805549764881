import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import { Idioma } from '@app/_models/idioma';
import {Rss} from '@app/_models/rss';
import { AlertService, RssService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-rss',
  templateUrl: './rss.component.html'
})
export class RssComponent implements OnInit {

  listaRss:Rss[]=[];
  selectedOrg:Ente;
  listaEntesAdministrados:Ente[]=[];
  listaIdiomas:Idioma[]=[];
  selectedIdioma:Idioma;
  public seleccionados: number[] = [];
  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  
  constructor(
    public rssService:RssService,
    public idiomasService:IdiomasService,
    public publicacionesService:PublicacionesService,
    public router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private alertService: AlertService) 
    { }

  ngOnInit(): void {
    this.publicacionesService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
      if(this.listaEntesAdministrados.length>0){
        this.selectedOrg=this.listaEntesAdministrados[0];
        this.idiomasService.getIdiomasFromEnte(this.selectedOrg.id).subscribe((res)=>{
          this.listaIdiomas = res.data;
          if(this.listaIdiomas.length>0){
            this.selectedIdioma = this.listaIdiomas[0];
            this.rssService.getRssFromEnte(this.selectedOrg.id,this.selectedIdioma.codigo).subscribe((res)=>{
              this.listaRss = res.data;
          });
          }
      });
      }
    });

  }

  loadRss(value, dropDownControl: DropDownListComponent) {
    this.rssService.getRssFromEnte(this.selectedOrg.id,this.selectedIdioma.codigo).subscribe((res)=>{
        this.listaRss = res.data;
    });
  }

  loadIdiomas(value, dropDownControl: DropDownListComponent) {
    this.idiomasService.getIdiomasFromEnte(this.selectedOrg.id).subscribe((res)=>{
        this.listaIdiomas = res.data;
        if(this.listaIdiomas.length>0){
          this.selectedIdioma = this.listaIdiomas[0];
          this.rssService.getRssFromEnte(this.selectedOrg.id,this.selectedIdioma.codigo).subscribe((res)=>{
            this.listaRss = res.data;
        });
        }
    });
  }

  cellClick(event){
    console.log(event);
    this.router.navigate(['rss-editar',{idorg:this.selectedOrg.id, idrss:event.dataItem.id }]);
  }

  onClickEditar(){
    this.router.navigate(['rss-editar',{idorg:this.selectedOrg.id, idtopic:this.seleccionados[0] }]);
  }

  onClickNuevo(){
    this.router.navigate(['rss-crear',{idorg:this.selectedOrg.id }]);
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }
  eliminarRegistro(contentloading){
    console.log('Eliminar registro');
    this.rssService.deleteRss({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.rssService.getRssFromEnte(this.selectedOrg.id).subscribe((result) => {
            this.listaRss = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

}
