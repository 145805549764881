    <div class="card">
    <div class="card-header">
        <h3>{{'menu-main.conf' | translate}}</h3>
    </div>
    <div class="card-body">
        <div class="row p-4">
            <div class="col-6">
                <button id="btnnuevo" (click)="onClickNuevo()" Class="btnBerria btn btn-primary btn-sm mr-1">{{
                    'botones.nuevo' | translate }}</button>
                <button id="btneliminar" Class="btnEzabatu btn btn-danger btn-sm mr-1" (click)="onClickEliminar()">{{
                    'botones.eliminar' | translate }}</button>
            </div>
            <div class="col-12 mt-1">
                <kendo-treeview textField="nombre" [nodes]="data" childrenField="listaHijos" kendoTreeViewHierarchyBinding [kendoTreeViewCheckable]="{ mode: checkMode }"
                    kendoTreeViewExpandable kendoTreeViewDragAndDrop kendoTreeViewDragAndDropEditing
                    kendoTreeViewFlatDataBinding (nodeDrop)="handleDrop($event)"
                    [(checkedKeys)]="selectedData" checkBy="id" idField="id"
                    parentIdField="idPadre">
                </kendo-treeview>
            </div>
        </div>
    </div>
</div>

<ng-template #popupBorrar let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'restaurante.preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'botones.cancelar' |
            translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnBorrarAceptar()">{{ 'botones.aceptar' | translate
            }}</button>
    </div>
</ng-template>