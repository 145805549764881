import { Component, Input, AfterViewInit } from '@angular/core';

import { FilterService } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import * as moment from 'moment';

@Component({
  selector: 'filtro-fechas',
  templateUrl: './filtro-fechas.component.html',
})
export class FiltroFechasComponent implements AfterViewInit {
  // Current set filter.
  @Input() public currentFilter: any;
  // Value in charge of setting the filter.
  @Input() public filterService: FilterService;
  // Field we want to filter.
  @Input() public field: string;
  @Input() public format: string = 'YYYY/MM/DD'
  public DaysInMonths = [];
  public fechaIni: Date;
  public fechaFin: Date;
  private today: Date;
  public mostrarCalendario: boolean = false;
  constructor() {
    this.today = new Date();
  }
  public ngAfterViewInit(): void {
    const currentColumnFilters: FilterDescriptor[] =
      this.currentFilter.filters.map(
        (filter: FilterDescriptor) => {
          if (filter.field === this.field) {
            return filter;
          }
        }
      );
    if (currentColumnFilters.length > 0) {
      this.fechaIni = new Date(currentColumnFilters[0].value);
      this.fechaFin = new Date(currentColumnFilters[1].value);
    }
  }
  showCalendar() {
    this.mostrarCalendario = !this.mostrarCalendario;
  }
  /**
   * Función para agregar los días seleccionados
   * al periodo correspondiente (el periodo seleccionado)
   */
  valueClickCalendar(month, event) {
    if (!event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) {
      return;
    }
    if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.fechaIni != undefined && this.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, de seleccionar
      this.fechaFin = this.DaysInMonths[month];
      this.crearFiltro();
      this.mostrarCalendario = false;
    } else {
      if (this.fechaIni == undefined && this.fechaFin == undefined) {
        this.fechaIni = this.DaysInMonths[month];
      }
      else if (this.fechaIni != undefined && this.fechaFin == undefined) {
        this.fechaFin = this.DaysInMonths[month];
        if (this.fechaIni > this.fechaFin) {
          var aux = new Date(this.fechaIni.getTime());
          this.fechaIni = new Date(this.fechaFin.getTime());
          this.fechaFin = aux;
        }
        this.mostrarCalendario = false;
        this.crearFiltro();
      }
      else {
        //en este caso había dos y se borran para empezar a seleccionar otra vez
        //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
        this.fechaIni = this.DaysInMonths[month];
        this.fechaFin = undefined;
      }
    }
  }
  //Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    this.DaysInMonths.push(this.today);
  }
  //Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
      return;
    }
    if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }

  //Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(date) {
    if (this.fechaIni == undefined && this.fechaFin == undefined) {
      return false;
    }
    if (this.fechaIni != undefined && this.fechaFin == undefined) {
      return date.getFullYear() == this.fechaIni.getFullYear() && date.getMonth() == this.fechaIni.getMonth() && date.getDate() == this.fechaIni.getDate();
    }
    if (this.fechaIni != undefined && this.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.fechaIni.getFullYear(), this.fechaIni.getMonth(), this.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth(), this.fechaFin.getDate());
    }
  };

  ultimas24HButton() {
    this.fechaIni = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1);
    this.fechaFin = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1);
    this.mostrarCalendario = false;
    this.crearFiltro();
  }
  ultimos7DiasButton() {
    this.fechaFin = this.today;
    this.fechaIni = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth(), this.fechaFin.getDate() - 6);
    this.mostrarCalendario = false;
    this.crearFiltro();
  }
  ultimos30DiasButton() {
    this.fechaFin = this.today;
    this.fechaIni = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth() - 1, this.fechaFin.getDate());
    this.mostrarCalendario = false;
    this.crearFiltro();
  }
  ultimos60DiasButton() {
    this.fechaFin = this.today;
    this.fechaIni = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth() - 2, this.fechaFin.getDate());
    this.mostrarCalendario = false;
    this.crearFiltro();
  }
  ultimos90DiasButton() {
    this.fechaFin = this.today;
    this.fechaIni = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth() - 3, this.fechaFin.getDate());
    this.mostrarCalendario = false;
    this.crearFiltro();
  }

  private crearFiltro() {
    let fechaIniFormateada: string = moment(this.fechaIni).format(this.format);
    let fechaFinFormateada: string = moment(this.fechaFin).format(this.format);
    let filters = [
      { field: this.field, operator: "gte", value: fechaIniFormateada },
      { field: this.field, operator: "lte", value: fechaFinFormateada }
    ]
    this.filterService.filter({
      logic: 'and',
      filters: filters,
    });
  }
}
