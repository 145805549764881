import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import { PlantillaEstadisticas } from '@app/_models/equipo';
import { Organizacion } from '@app/_models/organizacion';
import { AlertService } from '@app/_services';
import { EquiposPlantillaService } from '@app/_services/equipos-plantilla.service';
import { EquiposService } from '@app/_services/equipos.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html'
})
export class PlantillasComponent implements OnInit {

  listaPlantillas:PlantillaEstadisticas[]=[];
  selectedOrg:Ente;
  seleccionados:number[]=[];
  listaEntesAdministrados: Ente[] = [];
  
  constructor(
    private equipoService:EquiposService,
    private plantillaService:EquiposPlantillaService,
    private publicacionesService:PublicacionesService,
    private translateService:TranslateService,
    private alertService:AlertService,
    public router: Router,
    private route: ActivatedRoute) { 
    }

  ngOnInit(): void {
    this.publicacionesService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
      if(this.listaEntesAdministrados.length>0){
        this.selectedOrg = this.listaEntesAdministrados[0];
        this.plantillaService.getPlantillasFromEnte(this.selectedOrg.id).subscribe((result)=>{
          this.listaPlantillas = result.data;
        });
      }
    });
  }

  loadPlantillas(value, dropDownControl: DropDownListComponent) {
    this.plantillaService.getPlantillasFromEnte(this.selectedOrg.id).subscribe((result)=>{
      this.listaPlantillas = result.data;
    });
  }

  onCLickSeleccionPlantilla(){
   if(this.seleccionados.length!=1){
    this.alertService.info(this.translateService.instant('equipos.validarseleccion'));
   }

   this.plantillaService.setPlantillaEnte(this.seleccionados[0],this.selectedOrg.id).subscribe((res)=>{
    if(res.error)
    this.alertService.error(this.translateService.instant('equipos.errorsetplantilla'));
    else
    this.alertService.success(this.translateService.instant('equipos.plantillafijada'));
   });


  }

  onClickNuevo(){
    this.router.navigate([
      'jugadores-estadisticas',
      {
        idorg: this.selectedOrg.id,
      },
    ]);
  }
  onClickEditar(){
  }
  cellClick(e){
    this.router.navigate([
      'plantilla-editar',
      {
        idorg: this.selectedOrg.id,
        idplantilla:e.dataItem.id
      },
    ]);
  }

  onClickEliminar(){

  }
}
