import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService, DepartamentosService } from '@app/_services';
import { EventosService } from '@app/_services/eventos.service';
import {ChartData} from '@app/_models/chart';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { Evento } from '@app/_models/evento';

@Component({
  selector: 'app-inscripciones-evento',
  templateUrl: './inscripciones-evento.component.html'
})
export class InscripcionesEventoComponent implements OnInit {


  idEvento:number=0;
  seleccionados:number[]=[];
  myEvento:Evento;
  //Charts
  dataAforoEvento:any[]=[];
  chartAforoInscripciones:any;
  lang;
  private translate: TranslateService;
  listaInscripciones=[];
  datosInscripciones:ChartData[]=[];

  constructor(
    private eventosService: EventosService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    public router: Router,
    public route:ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('eventos.cabecera');
  }

  ngOnInit(): void {
    this.idEvento = this.route.snapshot.params['idevento'];
    this.eventosService.getEventoWithLang(this.idEvento,-1).subscribe((res)=>{
      this.myEvento = res.data[0];
      console.log(this.myEvento);
      this.eventosService.getInscripciones(this.idEvento).subscribe((res)=>{
        console.log('Inscripciones:');
        console.log(this.listaInscripciones);
        this.listaInscripciones = res.data;
  
        this.dataAforoEvento = [['inscritos', this.listaInscripciones.length], ['plazas_libres',  this.myEvento.aforo- this.listaInscripciones.length]];
        this.chartAforoInscripciones.load({ columns: this.dataAforoEvento});
        //c3.selectAll("#chartInscripciones .c3-chart-arcs path").style("stroke-width", "0px");
      });
    });

    this.generateCharts();
  }

   generateCharts(){
     this.chartAforoInscripciones = c3.generate({
      bindto: '#chartInscripciones',
      data: {
          columns: this.dataAforoEvento,
          type : 'donut',
          onclick: function (d, i) { console.log("onclick", d, i); },
          onmouseover: function (d, i) { console.log("onmouseover", d, i); },
          onmouseout: function (d, i) { console.log("onmouseout", d, i); }
      },
      donut: {
          title: this.translate.instant("eventos.porcentaje"),
      },
  });
  }
  cancelarInscripcion(inscripcion){
    console.log('Cancelando Inscripción...');
    console.log(inscripcion);
    this.listaInscripciones= this.listaInscripciones.filter((x)=>x.id!=inscripcion.id);
    this.eventosService.cancelarInscripcion(inscripcion.id).subscribe((res)=> {
      if(res.error){
        this.alertService.error("Error al cancelar subscripción de "+inscripcion.nombre);
        this.listaInscripciones.push(inscripcion);
      }
      else{
        this.dataAforoEvento = [['inscritos', this.listaInscripciones.length], ['plazas_libres',  this.myEvento.aforo- this.listaInscripciones.length]];
        this.chartAforoInscripciones.load({ columns: this.dataAforoEvento });
      }
    });
  }

  onClickEditar(){
  }
  cellClick(e){
  }
  onClickNuevo(){
  }
  onClickEliminar(){
  }

  goBack(){
    this.router.navigate(['eventos']);
  }
  gobackInside(){
    console.log('Generate new Intersectrion');
  }

}
