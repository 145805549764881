import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '@app/_services/menu.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import { v4 as uuid } from 'uuid';
import {
  AlertService,
} from '@app/_services';
import  {BloqueInput, BloqueInputTrad, CategoriaBloque, CategoriaBloqueTrad, Equipo, EquipoTrad, Jugador, JugadorTrad, LineasBloques, PlantillaEstadisticas, TipoComputable} from '@app/_models/equipo'
import { EquiposService } from '@app/_services/equipos.service';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { EquiposPlantillaService } from '@app/_services/equipos-plantilla.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { convertLowercaseIcon } from '@progress/kendo-svg-icons';
@Component({
  selector: 'app-jugadores-plantilla',
  templateUrl: './jugadores-plantilla.component.html',
})
export class JugadoresPlantillaComponent implements OnInit {

    //Datos
    idOrg:string="0A0A0A0A-A4CB-4E0A-97B7-1784C9735130";
    listaIdiomas: Idioma[] = [];
    listaIdiomasTotales: Idioma[] = [];
    listaTraduccionesCategoriasEnviar: CategoriaBloqueTrad[] = [];
    listaTraduccionesInputsEnviar: BloqueInputTrad[] = [];
    listaTraducciones: Idioma[] = [];
    selectedIdioma: Idioma;

    nombrePlantilla:string='';
    translate;
    listaInputsCategoriaActual=[];
    myPlantilla:PlantillaEstadisticas=new PlantillaEstadisticas();
    myListaCategorias:CategoriaBloque[] = [];
    myCategoriaTMP:CategoriaBloque= new CategoriaBloque();
    myBloqueTMP:BloqueInput= new BloqueInput();

    listaTipposComputables:TipoComputable[]=[];
    selectedComputable:TipoComputable;
    selectedCategoria:CategoriaBloque;
    selectedLinea:LineasBloques;
    selectedInput:BloqueInput;
    selectedBloque:BloqueInput;
    //Desplegable
    showPanelCategoria:boolean=false;

    showPanelInput:boolean=false;
    showUpdateInput:boolean=false;

    isBusy:boolean=false;

  constructor(
    private equiposService: EquiposService,
    private equiposPlantillaService:EquiposPlantillaService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.idOrg = this.route.snapshot.params['idorg'];
  }

  ngOnInit(): void {
        //Load Langs
        this.idiomaService.GetAll().subscribe((result) => {
          this.listaIdiomasTotales = result.data;
          console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
          this.listaIdiomasTotales.forEach((x) => {
            const trad = new CategoriaBloqueTrad();
            trad.filled = false;
            trad.idIdioma = x.codigo;
            trad.nombre='';
            trad.nombreIdioma = x.nombre;
            this.listaTraduccionesCategoriasEnviar.push(trad);

            const tradBloque = new BloqueInputTrad();
            tradBloque.filled = false;
            tradBloque.idIdioma = x.codigo;
            tradBloque.nombre='';
            tradBloque.nombreIdioma = x.nombre;
            this.listaTraduccionesInputsEnviar.push(tradBloque);
          });
        });
        this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
          this.listaIdiomas = result.data;
          if (this.listaIdiomas.length > 0)
            this.selectedIdioma = this.listaIdiomas[0];
        });
        //Temporal
        this.listaTipposComputables = this.equiposPlantillaService.getTiposComputables();
        this.selectedComputable = this.listaTipposComputables[0];
        this.myPlantilla.idOrg=this.idOrg;
  }

  addInputsBlock(num){
    console.log('Agregando bloque inputs :'+num );
  }
  addBloqueInput(){

    if(this.listaTraducciones.length<=0){
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }
    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: BloqueInputTrad = new BloqueInputTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesInputsEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesInputsEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
      }
    });

      this.selectedInput.listaTraducciones =  [...this.listaTraduccionesInputsEnviar];
      this.selectedInput.nombre = this.listaTraduccionesInputsEnviar[0].nombre;
      this.selectedInput.idCategoria = this.selectedCategoria.id;
      this.selectedInput.filled =true;
      this.selectedInput.idTipoComputoValor =this.selectedComputable.id;
      this.selectedInput.nombreTipoComputable =this.selectedComputable.nombre;
      this.selectedInput.isCustom =this.myBloqueTMP.isCustom;
      this.selectedInput.valorCustom =this.myBloqueTMP.valorCustom==undefined?"0":this.myBloqueTMP.valorCustom;



    this.myBloqueTMP = new BloqueInput();
    this.listaTraducciones=[];
    this.selectedIdioma = this.listaIdiomas[0];
    this.listaTraduccionesInputsEnviar =[];
    this.listaIdiomasTotales.forEach((x) => {
      const tradBloque = new BloqueInputTrad();
      tradBloque.filled = false;
      tradBloque.idIdioma = x.codigo;
      tradBloque.nombre='';
      tradBloque.nombreIdioma = x.nombre;
      this.listaTraduccionesInputsEnviar.push(tradBloque);
    });

    this.cerrarDesplegableInput();
    }
  


  
  //Crea un bloque de Categoria Drag&Drop con 3 inputs vacios por defecto
  addCategoryBlock(){
    if(this.listaTraducciones.length<=0){
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: CategoriaBloqueTrad = new CategoriaBloqueTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesCategoriasEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesCategoriasEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
      }
    });
    console.log(this.listaTraduccionesCategoriasEnviar);
    let myCategoria:CategoriaBloque = new CategoriaBloque();
    console.log(this.listaTraduccionesCategoriasEnviar);
    myCategoria.listaTraducciones = Array.from(this.listaTraduccionesCategoriasEnviar);
    myCategoria.nombre = this.listaTraduccionesCategoriasEnviar[0].nombre;
    myCategoria.idPlantilla=0;
    myCategoria.orden=this.myPlantilla.listaCategoriaBloques.length;
    myCategoria.id= this.myPlantilla.listaCategoriaBloques.length;
    let lineaNueva = new LineasBloques();
    lineaNueva.listaInputs =[new BloqueInput(),new BloqueInput(),new BloqueInput()]
    lineaNueva.idCategoria = myCategoria.id;
    lineaNueva.orden =myCategoria.listaBloquesInput.length;
    myCategoria.listaBloquesInput.push(lineaNueva);
    
    this.myPlantilla.listaCategoriaBloques.push(myCategoria);
    this.myCategoriaTMP = new CategoriaBloque();
    this.listaTraducciones=[];

    this.listaTraduccionesCategoriasEnviar = [];
    this.selectedIdioma = this.listaIdiomas[0];
    this.listaIdiomasTotales.forEach((x) => {
      const trad = new CategoriaBloqueTrad();
      trad.filled = false;
      trad.idIdioma = x.codigo;
      trad.nombre='';
      trad.nombreIdioma = x.nombre;
      this.listaTraduccionesCategoriasEnviar.push(trad);
    });

    this.cerrarDesplegableCategoria();
  }

  resetLineaInput(linea:LineasBloques,num){
    if(num==1){
      linea.listaInputs[1].activo=false;
      linea.listaInputs[2].activo=false;
    }
    if(num==2){
      linea.listaInputs[1].activo=true;
      linea.listaInputs[2].activo=false;
    }
    if(num==3){
      linea.listaInputs[1].activo=true;
      linea.listaInputs[2].activo=true;
    }

  }

  addLineaInputs(categoria:CategoriaBloque,num){
    let lineaNueva:LineasBloques= new LineasBloques();
    lineaNueva.listaInputs = [new BloqueInput(),new BloqueInput(),new BloqueInput()]
    lineaNueva.idCategoria = categoria.listaBloquesInput.length;
    if(num==1){
      lineaNueva.listaInputs[1].activo=false;
      lineaNueva.listaInputs[2].activo=false;
    }
    if(num==2){
      lineaNueva.listaInputs[2].activo=false;
    }
    lineaNueva.id = uuid();
    categoria.listaBloquesInput.push(lineaNueva);
    console.log(categoria.listaBloquesInput);
  }

  abrirDesplegableCategoria() {
    this.showPanelInput =false;
    this.showPanelCategoria =true;
  }
  cerrarDesplegableCategoria() {
    this.showPanelCategoria =false;
  }
  abrirDesplegableInput(categoria?:CategoriaBloque,linea?:LineasBloques,input?:BloqueInput) {
    this.selectedCategoria = categoria;
    this.selectedLinea = linea;
    this.selectedInput = input;
    this.showPanelCategoria =false;
    this.showPanelInput =true;
  }
  cerrarDesplegableInput() {
    this.showPanelInput =false;
  }

  abrirDesplegableInputUpdate(categoria?:CategoriaBloque,linea?:LineasBloques,num?:number) {
    this.showPanelInput =true;
    // this.listaTraducciones = [...this.listaIdiomas];
    
    // if(num==1){
    //   this.listaTraduccionesInputsEnviar = linea.bloque1.listaTraducciones;
    //   this.myBloqueTMP.nombre = linea.bloque1.listaTraducciones[0].nombre;
    //   this.myBloqueTMP.isCustom = linea.bloque1.isCustom;
    //   this.myBloqueTMP.valorCustom = linea.bloque1.valorCustom;
    //   this.myBloqueTMP.idTipoComputoValor = linea.bloque1.idTipoComputoValor;
    //   this.selectedComputable = this.listaTipposComputables.filter(x=>x.id=linea.bloque1.idTipoComputoValor)[0];
    // }
    // if(num==2){
    //   this.listaTraduccionesInputsEnviar = linea.bloque2.listaTraducciones;
    //   this.myBloqueTMP.nombre = linea.bloque2.listaTraducciones[0].nombre;
    //   this.myBloqueTMP.isCustom = linea.bloque2.isCustom;
    //   this.myBloqueTMP.valorCustom = linea.bloque2.valorCustom;
    //   this.myBloqueTMP.idTipoComputoValor = linea.bloque2.idTipoComputoValor;
    //   this.selectedComputable = this.listaTipposComputables.filter(x=>x.id=linea.bloque2.idTipoComputoValor)[0];
    // }
    // if(num==3){
    //   this.listaTraduccionesInputsEnviar = linea.bloque3.listaTraducciones;
    //   this.myBloqueTMP.nombre = linea.bloque3.listaTraducciones[0].nombre;
    //   this.myBloqueTMP.isCustom = linea.bloque3.isCustom;
    //   this.myBloqueTMP.valorCustom = linea.bloque3.valorCustom;
    //   this.myBloqueTMP.idTipoComputoValor = linea.bloque3.idTipoComputoValor;
    //   this.selectedComputable = this.listaTipposComputables.filter(x=>x.id=linea.bloque3.idTipoComputoValor)[0];
    // }

  }

  btmELiminarCategoria(categoria){
    this.myPlantilla.listaCategoriaBloques = this.myPlantilla.listaCategoriaBloques.filter((cat)=>cat.id !=categoria.id);
  }

  btnCrearPlantilla(){
    if(this.myPlantilla.nombre.length==0){
      this.alertService.info(
        this.translateService.instant('equipos.plantillanombre')
      );
      return;
    }
    this.isBusy=true;
    this.equiposPlantillaService.crearPlantilla(this.myPlantilla).subscribe((result)=>{
      this.isBusy=false;
      if(result.error){
        this.alertService.error(
          this.translateService.instant('equipos.error')
        );
      }
      else{
        this.alertService.success(
          this.translateService.instant('equipos.creadaplantilla')
        );
        this.router.navigate([
          'plantillas',
          {
            idorg: this.idOrg,
          },
        ]);
      }
    });
  }

  //Traducciones 
  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.myCategoriaTMP.nombre.length <= 0 
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesCategoriasEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = '' +this.myCategoriaTMP.nombre;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesCategoriasEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );
    this.listaTraduccionesCategoriasEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );
    console.log(this.listaTraduccionesCategoriasEnviar);
  }

  btnAddTraduccionInput(){
    if (
      this.selectedIdioma == null ||
      this.myBloqueTMP.nombre.length <= 0 
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);
      this.listaTraduccionesInputsEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.myBloqueTMP.nombre;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }
    console.log(this.listaInputsCategoriaActual);
  }

  btnDeleteTraduccionInput(idioma: Idioma){
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );
    this.listaTraduccionesInputsEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );
  }
  
  cerrarUpdateInput(){
    this.showUpdateInput = false;
  }

  abrirUpdateInput(categoria,linea:LineasBloques,input:BloqueInput){
    this.showUpdateInput=true;

      this.selectedBloque = input;
      this.myBloqueTMP = this.cloneObject(input);
    

  }
  idiomaChange(value:Idioma, dropDownControl: DropDownListComponent) {
    console.log(this.selectedBloque);
    let tmp = this.selectedBloque.listaTraducciones.filter((trad)=>trad.idIdioma==value.codigo);
    if(tmp.length>0)
   this.myBloqueTMP.nombre= tmp[0].nombre;
  }
  updateInputData(){
    console.log('Actualizando Input...');
    debugger
    this.selectedBloque.listaTraducciones.forEach((x)=>{
      if(this.selectedIdioma.codigo==x.idIdioma){
        x.nombre = this.myBloqueTMP.nombre;
      }
    });
    this.selectedBloque.idTipoComputoValor = this.selectedComputable.id;
    this.selectedBloque.nombreTipoComputable = this.selectedComputable.nombre;
    this.selectedBloque.isCustom = this.myBloqueTMP.isCustom;
    this.selectedBloque.valorCustom = this.myBloqueTMP.valorCustom;
    this.selectedBloque.nombre = this.myBloqueTMP.nombre;
    this.showUpdateInput=false;
  }

  borrarLinea(categoria:CategoriaBloque,linea){
    if(categoria.listaBloquesInput.length<=1)return;
    categoria.listaBloquesInput = categoria.listaBloquesInput.filter((x)=>x.id!=linea.id);
  }

  cloneObject(obj):any{
    return Object.assign({}, obj)
  }

  //Drag&Drop
  drop(event: CdkDragDrop<string[]>) {
    console.log(event);
    moveItemInArray(this.myPlantilla.listaCategoriaBloques, event.previousIndex, event.currentIndex);
  }
  

}
