import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import { ForoPost, ForoPostTrad, ForoTopic, ForoTopicModel, ForoTopicTrad } from '@app/_models/foro';
import { Idioma } from '@app/_models/idioma';
import { AlertService, ForoService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-foro-post-crear',
  templateUrl: './foro-post-crear.component.html'
})
export class ForoPostCrearComponent implements OnInit {

  idTopic:number;
  idOrg:string;
  
  //Listas
  listaEntesAdministrados: Ente[] = [];
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];

  //Traducciones
  listaTraduccionesEnviar: ForoPostTrad[] = [];
  //Data
  title: string = '';
  message: string = '';
  superficieRecinto: string = '';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  recintoActivo: boolean = true;
  myPost: ForoPost = new ForoPost();
  filePath: string;

  lang;
  private translate: TranslateService;
  public pasteCleanupSettings = {
    convertMsLists: true,
    removeHtmlComments: true,
    // stripTags: ['span', 'h1'], //Para replace de <br> por ejemplo
    // removeAttributes: ['lang'], // para supresión de tags
    //removeMsClasses: true,
    //removeMsStyles: true,
    //removeInvalidHTML: true,
  };

  constructor(
    private foroService: ForoService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('foro.cabecera');
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idTopic = this.route.snapshot.params['idtopic'];
    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new ForoPostTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0)
        this.selectedIdioma = this.listaIdiomas[0];
    });
  }

  btnCrearPost() {
    console.log('Creando Publicacion');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }

    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: ForoPostTrad = new ForoPostTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.title = seleccionado.title;
        trad.post = seleccionado.post;
      }
    });

    console.log(this.listaTraduccionesEnviar);
    //Subir Foto  y EnviarNoticia
    this.myPost.idTopic = this.idTopic;
    this.myPost.title = this.title;
    this.myPost.post = this.message;
    this.myPost.listaTraducciones = this.listaTraduccionesEnviar;

    this.foroService.crearPost(this.myPost).subscribe((result) => {
      if (!result.error) {
        this.alertService.success(
          this.translateService.instant('recintos.success')
        );
        this.router.navigate(['foro-posts',{ idorg:this.idOrg ,idtopic:this.idTopic}]);
      } else {
        console.log('Error enviando traducciones publicacion');
        this.alertService.error(
          this.translateService.instant('recintos.error')
        );
        return;
      }
    });
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.title.length <= 0 ||
      this.message.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.title = this.title;
          x.post = this.message;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar() {
    this.router.navigate(['foro-posts',{ idorg:this.idOrg ,idtopic:this.idTopic}]);
  }
}
