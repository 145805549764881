<div class="form-group p-3">
  <div class="card-body">
    <div class="row">
      <div class="column">
        <!-- Switches Dias de la semana-->
        <div class="form-group">
          <kendo-label class="k-checkbox-label" text="{{ 'restaurante.lunes' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="activoLunes"
              onLabel="{{ 'switch.activo' | translate }}" offLabel="{{ 'switch.inactivo' | translate }}">
            </kendo-switch>
          </div>
        </div>
        <div class="form-group">
          <kendo-label class="k-checkbox-label" text="{{ 'restaurante.martes' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="activoMartes"
              onLabel="{{ 'switch.activo' | translate }}" offLabel="{{ 'switch.inactivo' | translate }}">
            </kendo-switch>
          </div>
        </div>
        <div class="form-group">
          <kendo-label class="k-checkbox-label" text="{{ 'restaurante.miercoles' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="activoMiercoles"
              onLabel="{{ 'switch.activo' | translate }}" offLabel="{{ 'switch.inactivo' | translate }}">
            </kendo-switch>
          </div>
        </div>
        <div class="form-group">
          <kendo-label class="k-checkbox-label" text="{{ 'restaurante.jueves' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="activoJueves"
              onLabel="{{ 'switch.activo' | translate }}" offLabel="{{ 'switch.inactivo' | translate }}">
            </kendo-switch>
          </div>
        </div>
        <div class="form-group">
          <kendo-label class="k-checkbox-label" text="{{ 'restaurante.viernes' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="activoViernes"
              onLabel="{{ 'switch.activo' | translate }}" offLabel="{{ 'switch.inactivo' | translate }}">
            </kendo-switch>
          </div>
        </div>
        <div class="form-group">
          <kendo-label class="k-checkbox-label" text="{{ 'restaurante.sabado' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="activoSabado"
              onLabel="{{ 'switch.activo' | translate }}" offLabel="{{ 'switch.inactivo' | translate }}">
            </kendo-switch>
          </div>
        </div>
        <div class="form-group">
          <kendo-label class="k-checkbox-label" text="{{ 'restaurante.domingo' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="activoDomingo"
              onLabel="{{ 'switch.activo' | translate }}" offLabel="{{ 'switch.inactivo' | translate }}">
            </kendo-switch>
          </div>
        </div>
      </div>
      <!-- Calendario -->
      <div class="column">
          <div class="card-body">
            <kendo-calendar selection="multiple" [weekNumber]="true" (valueChange)="onChange($event)"
              [(ngModel)]="today">
              <ng-template kendoCalendarMonthCellTemplate let-date>
                <span [ngClass]="isWorkingDay(date)">{{date.getDate()}}</span>
              </ng-template>
            </kendo-calendar>
          </div>
      </div>
      <div class="column">
        <div class="card-body">
          <h4>{{ "restaurante.asignarcomidas" | translate }}</h4>
          <div *ngFor="let comida of listaComidasDiaSeleccionado">
            {{comida.nombre}}
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [style.marginBottom.px]="10"
              (valueChange)="onComidaActiva($event,comida)" [(ngModel)] = "comida.activo"></kendo-switch>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Botones Footer-->
  <div class="row m-1">
    <button kendoButton (click)="guardarEstadoDelCalendario()" class="btn mr-1 btn-success btn-sm mr-1">
      {{ "restaurante.guardar" | translate }}
    </button>
    <button kendoButton (click)="activatePeriodo()" class="btn mr-1 btn-info btn-sm mr-1">
      {{ "restaurante.activar" | translate }}
    </button>
    <button kendoButton (click)="disablePeriodo()" class="btn mr-1 btn-warning btn-sm mr-1">
      {{ "restaurante.desactivar" | translate }}
    </button>
  </div>
</div>

  <style>
    .laboral {
      position: relative;
      color: #1364ce;
    }

    .nolaboral {
      position: relative;
      color: #c5c5c5;
    }
  </style>