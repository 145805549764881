<div class="card-body">
<!--MULTISELECT USUARIOS-->
    <kendo-multiselect
      [autoClose]="true"
      [filterable]="true"
      (filterChange)="handleFilter($event)"
      (valueChange)="valueChange($event)"
      (removeTag)="removeTag($event)"
      [data]="listaFiltrados"
      textField="nombre"
      valueField="id"
      [(ngModel)]="listaSeleccionados"
      placeholder="Búsca los usuarios que quieras seleccionar"
      (keyup.enter)="addUsuarios()"
    >
    <ng-template kendoMultiSelectItemTemplate let-dataItem>
      <span class="template">{{ dataItem.value }}</span>  <pre> <b>{{ dataItem.nombreUsuario }}</b>    Email:{{ dataItem.email }} </pre>
    </ng-template>
    <ng-template kendoMultiSelectNoDataTemplate>
      <h4>
        <span class="k-icon k-i-warning"></span><br /><br />
        {{ "recintos.lbl_emptyusers" | translate }}
      </h4>
    </ng-template>
    </kendo-multiselect>
<!--GRID USUARIOS SELECCIONADOS-->
    <kendo-grid
    [kendoGridBinding]="listaUsuariosGrid"
    kendoGridSelectBy="id"
    filterable="menu"
    [pageSize]="10"
    [pageable]="true"
    [navigable]="true"
    [sortable]="true"
    [filterable]="true"
    scrollable="virtual"
    [rowHeight]="36"
    [height]="500"
    [resizable]="true"
    >

    <kendo-grid-column
    field="nombreUsuario"
    title="{{ 'organizaciones.nombre' | translate }}"
    width="15%"
    ></kendo-grid-column>
    <kendo-grid-column
    field="email"
    title="{{ 'agentes.email' | translate }}"
    width="20%"
    ></kendo-grid-column>
    <kendo-grid-column
    title="{{ 'agentes.asignado' | translate }}"
    width="10%"
    >
    <ng-template kendoGridCellTemplate let-dataItem>
        <button
        type="button"
        class="btn btn-danger btn-sm mr-1"
        (click)="eliminarUsuario(dataItem)"
    >X</button>
    </ng-template>
    </kendo-grid-column>
    </kendo-grid>

</div>