import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import { ForoTopic, ForoTopicModel, ForoTopicTrad } from '@app/_models/foro';
import { Idioma } from '@app/_models/idioma';
import { AlertService, ForoService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-foro-topics-editar',
  templateUrl: './foro-topics-editar.component.html'
})
export class ForoTopicsEditarComponent implements OnInit {
  listaIdiomas: Idioma[] = [];
  myTopic: ForoTopic = new ForoTopic();
  nombreRecinto: string = '';
  descripcionRecinto: string = '';
  superficieRecinto: string = "";
  imagenB64: string = '';
  selectedIdioma: Idioma;
  recintoActivo: boolean = true;
  filePath: string;

  lang;
  private translate: TranslateService;
  idOrg: string;
  idTopic: number;
  constructor(
    private foroService: ForoService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('recintos.cabecera');
  }

  ngOnInit(): void {
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idTopic = this.route.snapshot.params['idtopic'];
    this.idiomaService
      .getIdiomasFromEnte(this.idOrg)
      .subscribe((resultidiomas) => {
        this.listaIdiomas = resultidiomas.data;
        if (this.listaIdiomas.length > 0) {
          this.selectedIdioma = this.listaIdiomas[0];
          //GET RECINTO
          this.foroService
            .getTopicByIdLang(this.idTopic, this.selectedIdioma.codigo)
            .subscribe((resultRec) => {
              console.log(resultRec);
              this.myTopic = resultRec.data[0];
              this.nombreRecinto = resultRec.data[0].topicName;
              this.descripcionRecinto = resultRec.data[0].topicDetails;
              this.filePath=resultRec.data[0].avatar;
            });
        }
      });
  }

  loadTopic(value, dropDownControl: DropDownListComponent) {
    this.foroService
      .getTopicByIdLang(this.idTopic, this.selectedIdioma.codigo)
      .subscribe((result) => {
        this.myTopic = result.data[0];
        this.nombreRecinto = result.data[0].topicName;
        this.descripcionRecinto = result.data[0].topicDetails;
        this.filePath=result.data[0].avatar;
      });
  }

  btnActualizarRecinto() {
    if (this.nombreRecinto==null || this.nombreRecinto.length < 1 || this.descripcionRecinto==null || this.descripcionRecinto.length < 1) {
      this.alertService.info(
        this.translateService.instant('recintos.validacion')
      );
      return;
    }


    if(this.filePath!=this.myTopic.avatar){
      console.log('Obteniendo en BASE64...');
      this.myTopic.avatar = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.myTopic.updateFoto=true;
    }

    this.myTopic.topicName = this.nombreRecinto;
    console.log(this.superficieRecinto);
    this.myTopic.topicDetails = this.descripcionRecinto;
    this.myTopic.idIdioma = this.selectedIdioma.codigo;

    this.foroService.editTopic(this.myTopic)
      .subscribe((resultUpdate) => {
        console.log(resultUpdate);
        if (!resultUpdate.error) {
          this.alertService.success(
            this.translateService.instant('recintos.successupdate')
          );
        } else {
          this.alertService.error(
            this.translateService.instant('recintos.errorupdate')
          );
        }
      });
  }

  //Imagen

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnCancelar() {
    this.router.navigate(['foro-topics',{ idorg:this.idOrg }]);
  }
}
