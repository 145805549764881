import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import { Idioma } from '@app/_models/idioma';
import {Rss,RssTrad} from '@app/_models/rss';
import { AlertService, RssService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-rss-editar',
  templateUrl: './rss-editar.component.html'
})
export class RssEditarComponent implements OnInit {

      //Data
      nombreRss:string='';
      descripcionRss:string='';
      urlRss:string='';
      myRss:Rss=new Rss();
      //Listas
      listaEntesAdministrados: Ente[] = [];
      listaIdiomas: Idioma[] = [];
      listaIdiomasTotales: Idioma[] = [];
      listaTraducciones: Idioma[] = [];
      //Traducciones
      listaTraduccionesEnviar: RssTrad[] = [];
      selectedIdioma: Idioma;
    
      lang;
      private translate: TranslateService;
      idOrg: string;
      idRss:number;

  constructor(
    public rssService:RssService,
    public idiomasService:IdiomasService,
    public publicacionesService:PublicacionesService,
    public router: Router,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private alertService: AlertService) 
    { }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRss = this.route.snapshot.params['idrss'];
    //Load Langs
    this.idiomasService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new RssTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombre = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.idiomasService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0){
        this.selectedIdioma = this.listaIdiomas[0];
        this.rssService.getRssByIdLang(this.idRss,this.selectedIdioma.codigo).subscribe((res)=>{
          this.myRss = res.data[0];
          this.nombreRss = res.data[0].nombre;
          this.descripcionRss = res.data[0].descripcion;
          this.urlRss = res.data[0].urlRss;
        });
      }
    });
  }

  loadRss(value, dropDownControl: DropDownListComponent) {
    this.rssService
      .getRssByIdLang(this.idRss, this.selectedIdioma.codigo)
      .subscribe((result) => {
        console.log('RSS que llega');
        console.log(result);
        this.nombreRss = result.data[0].nombre;
        this.descripcionRss = result.data[0].descripcion;
        this.urlRss = result.data[0].urlRss;
      });
  }

  btnUpdateRss(){
    console.log('Actualizando Rss');
    this.myRss.nombre = this.nombreRss;
    this.myRss.descripcion = this.descripcionRss;
    this.myRss.urlRss = this.urlRss;
    this.myRss.idIdioma = this.selectedIdioma.codigo;
    this.myRss.id = this.idRss;
    this.rssService.updateRss(this.myRss).subscribe((res)=>{
      if(!res.error){
        this.alertService.success(this.translateService.instant('registros.updated'));
      }
      else{
        this.alertService.error(this.translateService.instant('registros.error'));
      }
    });
  } 

  btnCancelar(){
    this.router.navigate(['rss']);
  }

}
