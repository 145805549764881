        <!--Lista Entes-->
        <div class="form-group">
            <kendo-dropdownlist #dropdown
                                [data]="listaEntesAdministrados"
                                textField="nombre"
                                valueField="id"
                                [(ngModel)]="selectedOrg"
                                (valueChange)="loadPlantillas($event, dropdown)">
            </kendo-dropdownlist>
          </div>

<kendo-grid
[kendoGridBinding]="listaPlantillas"
[sortable]="true"
[pageable]="true"
[pageSize]="14"
kendoGridSelectBy="id"
[navigable]="true"
filterable="menu"
(cellClick)="cellClick($event)"
[(selectedKeys)]="seleccionados"
>
<ng-template kendoGridNoRecordsTemplate>
{{ "equipos.sinplantillas" | translate }}

</ng-template>
<ng-template kendoGridToolbarTemplate position="top">
  <button
    kendoButton
    (click)="onClickEditar()"
    class="btn mr-1 btn-success btn-sm mr-1"
  >
    {{ "botones.editar" | translate }}
  </button>
  <button
    kendoButton
    (click)="onClickNuevo()"
    class="btn mr-1 btn-primary btn-sm mr-1"
  >
    {{ "botones.nuevo" | translate }}
  </button>
  <button
    kendoButton
    (click)="onClickEliminar(content)"
    class="btn mr-1 btn-danger btn-sm mr-1"
    [disabled]="isDeleting"
  >
    {{ "botones.eliminar" | translate }}
  </button>
  <button
  kendoButton
  (click)="onCLickSeleccionPlantilla()"
  class="btn mr-1 btn-primary btn-sm mr-1"
>
  {{ "equipos.seleccionplantilla" | translate }}
</button>
</ng-template>
<kendo-grid-checkbox-column
  width="5%"
  showSelectAll="true"
></kendo-grid-checkbox-column>

<kendo-grid-column
  width="20%"
  [style]="{ 'text-align': 'left' }"
  field="nombre"
  title="{{ 'equipos.nombreplant' | translate }}"
>
</kendo-grid-column>
</kendo-grid>