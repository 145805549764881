<div class="row">
  <div class="col-xl-5 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "encuestas.titulo" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <!--Lista Entes-->
              <kendo-dropdownlist #dropdown
                                  [data]="listaEntesAdministrados"
                                  textField="nombre"
                                  valueField="id"
                                  [(ngModel)]="selectedOrg"
                                  (valueChange)="loadIdiomas($event, dropdown)">
              </kendo-dropdownlist>

            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <!--Lista tipos Encuesta-->
              <div class="clearfix">
                <kendo-label text="{{ 'encuestas.lbl_tipos' | translate }}">
                </kendo-label>
              </div>
              <kendo-dropdownlist #dropdownT
                                  [data]="listaTiposEncuesta"
                                  textField="nombre"
                                  valueField="id"
                                  [(ngModel)]="selectedTipo"
                                  (valueChange)="checkTipoEncuesta($event, dropdownT)"></kendo-dropdownlist>

            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <!--Lista idiomas-->
              <kendo-label text="{{ 'eventos.idiomas' | translate }}"> </kendo-label>
              <div class="caja">
                <kendo-dropdownlist #dropdownI
                                    [data]="listaIdiomas"
                                    textField="nombre"
                                    valueField="codigo"
                                    [(ngModel)]="selectedIdioma"
                                    (valueChange)="loadCategorias($event, dropdownI)"></kendo-dropdownlist>
              </div>
            </div>
          </div>


        </div>





        <div class="form-group">
          <!--Titulo-->
          <kendo-textbox placeholder="{{ 'encuestas.ph_titulo' | translate }}"
                         [clearButton]="true"
                         [(ngModel)]="titulo">
            <ng-template kendoTextBoxPrefixTemplate>
              <button kendoButton look="clear" icon="calendar"></button>
            </ng-template>
          </kendo-textbox>
        </div>


        <!--Cuerpo-->
        <div class="form-group">
          <kendo-label text="{{ 'encuestas.lbl_descripcion' | translate }}">
            <textarea kendoTextArea
                      style="resize: none"
                      rows="5"
                      [(ngModel)]="cuerpo">
        </textarea>
          </kendo-label>
        </div>

        <div class="form-group">
          <kendo-label class="k-checkbox-label"
                       text="{{ 'publicaciones.enviarnotif' | translate }}"></kendo-label>
          <div class="caja">
                   <kendo-switch
                   [(ngModel)]="myEncuesta.sendNotification"
                   onLabel="{{ 'switch.on' | translate }}"
                   offLabel="{{ 'switch.off' | translate }}"
                 ></kendo-switch>
          </div>
        </div>

        <!-- Traducciones -->
        <div class="form-group">
          <button type="button"
                  class="btn btn-primary btn-sm mr-1"
                  (click)="btnAddTraduccion()">
            {{ "eventos.anadir" | translate }}
          </button>
          <div class="listado-traducciones">
            <div class="traduccion" *ngFor="let idioma of listaTraducciones">
              {{ idioma.nombre }}

              <button type="button"
                      class="btn btn-danger btn-sm mr-1"
                      (click)="btnDeleteTraduccion(idioma)">
                X
              </button>
            </div>
          </div>
          <!--Boton Publicar-->
          <button type="button" [disabled]="isBusy" class="btn btn-success btn-sm mr-1" (click)="btnCrearEncuesta()">
            {{ "encuestas.crear" | translate }}
          </button>
          <button type="button"
          class="btn btn-danger btn-sm mr-1"
          (click)="btnCancelar()">
          {{ "botones.cancelar" | translate }}
        </button>

        </div>
      </div>
    </div>
  </div>




  <div class="col-xl-4 col-md-6">
    <div class="card">

      <div class="card-header">
        <h3>{{ "encuestas.titulo" | translate }}</h3>
      </div>
      <div class="card-body">

        <!--Lista Categorias-->
        <div class="form-group">
          <kendo-label class="k-checkbox-label"
                       text="{{ 'eventos.categoria' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-dropdownlist [data]="listaCategorias"
                                textField="nombre"
                                valueField="id"
                                [(ngModel)]="selectedCategoria"></kendo-dropdownlist>


          </div>
        </div>

        <div class="form-group">
          <div *ngIf="isPuntuada">
            <kendo-label class="k-checkbox-label"
                         text="{{ 'encuestas.lbl_saldo' | translate }}"></kendo-label>
            <kendo-numerictextbox placeholder="Valor Distribuible"
                                  [(ngModel)]="saldo">
            </kendo-numerictextbox>
          </div>


        </div>


        <div class="form-group">

          <!--Edad Max Min-->
          <kendo-label class="k-checkbox-label"
                       text="{{ 'encuestas.rangoedad' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-numerictextbox format="n"
                                  [style.marginBottom.px]="10"
                                  [(value)]="edadMin"
                                  [min]="0"
                                  [max]="120"></kendo-numerictextbox>
            <kendo-numerictextbox format="n"
                                  [style.marginBottom.px]="10"
                                  [(value)]="edadMax"
                                  [min]="0"
                                  [max]="120">
            </kendo-numerictextbox>
          </div>
        </div>


        <!-- Encuesta Fechas-->
        <div class="form-group">
          <kendo-label class="k-checkbox-label"
                       text="{{ 'eventos.fechaini' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-datepicker format="dd/MMM/yyyy" [(value)]="fechaInicio">
            </kendo-datepicker>
            <kendo-timepicker format="HH:mm:ss"
                              [(value)]="horaInicio"></kendo-timepicker>
          </div>
        </div>
        <div class="form-group">
          <kendo-label class="k-checkbox-label"
                       text="{{ 'eventos.fechafin' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-datepicker format="dd/MMM/yyyy" [(value)]="fechaFin">
            </kendo-datepicker>
            <kendo-timepicker format="HH:mm:ss"
                              [(value)]="horaFin"></kendo-timepicker>
          </div>
        </div>

        <div class="form-group">
          <!--CheckBoxes ShowResults && IsMembersOnly-->
          <div class="row">
            <div class="col">
              <kendo-label class="k-checkbox-label"
                           text="{{ 'encuestas.showresults' | translate }}"></kendo-label>
              <div class="caja">
                <input type="checkbox" [(ngModel)]="showResults" kendoCheckBox />
              </div>

            </div>

            <div class="col">
              <kendo-label class="k-checkbox-label"
                           text="{{ 'encuestas.membersonly' | translate }}"></kendo-label>
              <div class="caja">
                <input type="checkbox" [(ngModel)]="isMembersOnly" kendoCheckBox />
              </div>
            </div>
          </div>
        </div>

    

      </div>
    </div>
      </div>




      <div class="col-xl-3 col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>{{ "encuestas.titulo" | translate }}</h3>
      </div>
      <div class="card-body">

        <!--Opciones-->
        <div class="form-group anadir-opciones">
          <button kendoButton (click)="btnAddOption()" class="btn btn-success btn-anadir-opcion" icon="plus"></button>

          <div class="linea-opcion" *ngFor="let opcion of listaOpciones">
            <div>
              <kendo-textbox placeholder="Opcion"
                             [clearButton]="true"
                             [(ngModel)]="opcion.opcion">
              </kendo-textbox>

              <button type="button"
                      class="btn btn-danger"
                      (click)="btnDeleteOption(opcion)">
                X
              </button>

              <kendo-numerictextbox *ngIf="isPuntuada"
                                    placeholder="Valor"
                                    [(ngModel)]="opcion.puntuacion">
              </kendo-numerictextbox>
            </div>
          </div>
        </div>


        <div class="form-group">
          <kendo-label class="k-checkbox-label" text="{{ 'encuestas.lbl_numopciones' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-slider incrementTitle="Inc"
                          decrementTitle="Dec"
                          [min]="1"
                          [max]="numOpciones"
                          [(ngModel)]="numOpcionesSeleccionables"></kendo-slider>
          </div>
          {{ numOpcionesSeleccionables }}
        </div>

      </div>

    </div>
  </div>
</div>
