<div class="row">
    <div class="col">
        <div class="card">
            <div class="card-header">
                <h3>{{ "equipos.crearpartido" | translate }}</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <kendo-label text="{{ 'equipos.jornada' | translate }}">
                      <kendo-numerictextbox
                        format="n0"
                        [min]="0"
                        [step]="1"
                        [(ngModel)]="myPartido.jornada"
                      ></kendo-numerictextbox>
                    </kendo-label>
                  </div>
            </div>
        </div>
    </div>
<div class="col">
    <div class="card">
        <div class="card-body">
            <kendo-label
            text="{{ 'restaurante.mesacomedor' | translate }}"
          ></kendo-label>
            <kendo-dropdownlist
            [data]="listaEquiposRivales"
            textField="nombre"
            valueField="id"
              [(ngModel)]="selectedEquipoRival"
            >
            </kendo-dropdownlist>
          </div>
    </div>
</div>

</div>