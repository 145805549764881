import { Component, OnInit } from '@angular/core';
import {
  Organizacion,
  OrganizacionModel,
  OrganizacionTraduccion,
  Recurrencia,
} from '@app/_models/organizacion';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  ConfiguracionService,
  DepartamentosService,
} from '@app/_services';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import {
  FileInfo,
  FileRestrictions,
  SelectEvent,
  RemoveEvent,
} from '@progress/kendo-angular-upload';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IdiomasService } from '@app/_services/idiomas.service';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Idioma } from '../../_models/idioma';
import { CamposIdioma } from '../../_models/camposidiomas';
import { AgentesService } from '@app/_services/agentes.service';
import { ColaboradoresService } from '@app/_services/colaboradores.service';
import { MenuService } from '@app/_services/';
import { DepartamentoEnte } from '@app/_models/departamentoente';
import { DepartamentosEnteService } from '@app/_services/departamentosente.service';

@Component({
  selector: 'app-colaboradores-crear',
  templateUrl: './colaboradores-crear.component.html',
})
export class ColaboradoresCrearComponent implements OnInit {

  public correcto:boolean=true;

  myOrganizacion: Organizacion = new Organizacion();
  myModel: OrganizacionModel = new OrganizacionModel();

  numIdiomasValidado: boolean = false;

  //Data
  nombre: string = '';
  nombreRequerido: boolean = false; //validar
  activoOrganizacion:boolean = true;

  logoDocumentoDocumento: any = "";
  logoDocumentoAgregar: any = "";
  logoDocumentoRequerido: boolean = false; //validar

  cabeceraDocumentoDocumento: any = "";
  cabeceraDocumentoAgregar: any = "";
  cabeceraDocumentoRequerido: boolean = false; //validar

  //EMAIL:
  email: string = '';
  emailValidado: boolean=false;
  smtp: string = '';
  puerto: number;
  emailUsuario: string = '';
  emailUsuarioValidado: boolean=false;
  emailUsuarioRequerido: boolean = false; //validar
  activoGuardarPassword:boolean = false;
  contrasena: string;
  contrasenaRequerido: boolean = false; //validar

  //INFORMACION
  activoNotificaciones:boolean = true;
  activoFarmacias: boolean = true; 
  activoFAQ: boolean = false; 
  activoGesDoc: boolean = true; 
  activoContactos: boolean = false; 

  //AGENDA
  activoAgenda:boolean = true;
  agendaEdadMin: number;

  //MENSAJERIA
  activoMensajeria:boolean = true;
  activoIncidenciasDepUnico:boolean = true;

  //RESERVAS
  activoReservas:boolean = true;
  activoConfirmReserva:boolean = true;
  activoMostrarUsuarioCalendario:boolean  = false;
  mindiasresrva:number;
  apertura:string='Diario';
  correosReservas:string='';
  correosReservasRequerido = false; //validar

  //COORDENADAS
  latitud:string='';
  longitud:string='';
  zoom:number;

  //FORO
  activoForo: boolean =true;
  edadforo:number;
  foroPermUsuariosCrearPost:boolean=true;

  //ENCUESTAS
  activoEncuestas: boolean =true;
  edadencuestasmin:number;

  //RRSS
  activoFacebook: boolean =true;
  fblink:string='';
  fblinkRequerido: boolean=false; //validar

  activoTwitter: boolean =false;
  twlink:string='';
  twlinkRequerido: boolean=false; //validar

  activoFlicker: boolean =false;
  fllink:string='';
  fllinkRequerido: boolean=false; //validar

  activoInstagram: boolean =true;
  iglink:string='';
  iglinkRequerido: boolean=false; //validar

  activoYoutube: boolean =false;
  ytlink='';
  ytlinkRequerido: boolean=false; //validar

  media:string='';
  mediaRequerido: boolean=false; //validar

  //AGENTES
  activoAgentes: boolean =true;
  activoTipos: boolean =true;

  //INTERCOMUNICACION USUARIOS
  permUsuariosPublicar: boolean =true;
  permUsuariosComentar: boolean =true;

  //form: FormGroup;
  loading = false;
  isAddMode: boolean;
  codigo: string;
  imagenAvatar: any;
  imagenCabecera: any;
  apItems: string[];
  public avatarPreview;
  public cabeceraPreview;
  imagenAnt: any;
  submitted = false;
  formImErr = false;
  avatarBorrado = false;
  cabeceraBorrada = false;
  aperturaRangos = [];
  imagenConfiguracion: any;
  isNotInsA = false;
  isNotInsC = false;
  modalReference: NgbModalRef;
  idiomasGuardar: boolean = false;
  idiomaEditando: any;
  public codigos: any[] = [];
  camposSelectedLang: number = 0;
  containersCampos: number[] = [];
  camposValoresDescripcion = [];
  camposValoresColaboradores = [];
  camposValoresFAQ = [];
  camposValoresPrivacidad = [];

  jsonCamposIdomas = [];
  dataLoaded: boolean = false;

  //Combo Departamentos
  public departamentosDatos: DepartamentoEnte[];
  public selectedDepartamento: DepartamentoEnte;
  public departamento: DepartamentoEnte;
  public selectedDepartamentoId: number;

  public listaIdiomas = [];
  public selectedLangs = [];
  public listaCampos = [];

    //Traducciones
    listaIdiomasTotales: Idioma[] = [];
    listaTraducciones: Idioma[] = [];
    listaTraduccionesEnviar: OrganizacionTraduccion[] = [];

  //Permisos
  notificacionesDisabled = false;
  faqDisabled = false;
  gesDocDisabled = false;
  contactosDisabled = false;
  facebookDisabled = false;
  twitterDisabled = false;
  flickrDisabled = false;
  instagramDisabled = false;
  youtubeDisabled = false;
  agendaDisabled = false;
  mensajesDisabled = false;
  foroDisabled = false;
  encuetasDisabled = false;
  reservasDisabled = false;
  agentesDisabled = false;
  colaboradoresDisabled = false;

  //Gestión
  public showAdmins = false;
  usuariosAdminsLista = [];
  listaTMPAdmins = [];
  updateAdminsNeeded = false;

  regexLink =
    '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$';

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpeg', '.jpg'],
    maxFileSize: 1048576,
  };

  constructor(
    //private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private colaboradorService: ColaboradoresService,
    private configuracionService: ConfiguracionService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private idiomasService: IdiomasService,
    private departamentoEnteService: DepartamentosEnteService,
    private router: Router,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.codigo = this.route.snapshot.params['id'];
    this.isAddMode = !this.codigo;
    this.dataLoaded = this.isAddMode;
    //Load Apertura Reservas Options
    this.aperturaRangos = [
      this.translateService.instant('organizaciones.opsiempre'),
      this.translateService.instant('organizaciones.opdiario'),
      this.translateService.instant('organizaciones.opsemanal'),
      this.translateService.instant('organizaciones.opmensual'),
    ];

    //Load Permisos given By Admin
    this.configuracionService
      .GetConfig()
      .pipe()
      .subscribe((result) => {
        console.log('PERMISOS LOADED =');
        console.log(result.data[0]);
        this.notificacionesDisabled = !result.data[0].activoNotificacionesC;
        this.faqDisabled = !result.data[0].activoFAQC;
        this.gesDocDisabled = !result.data[0].activoGesDocC;
        this.contactosDisabled = !result.data[0].activoContactosC;
        this.facebookDisabled = !result.data[0].activoFacebookC;
        this.twitterDisabled = !result.data[0].activoTwitterC;
        this.flickrDisabled = !result.data[0].activoFlickerC;
        this.instagramDisabled = !result.data[0].activoInstagramC;
        this.youtubeDisabled = !result.data[0].activoYoutubeC;
        this.agendaDisabled = !result.data[0].activoAgendaC;
        this.mensajesDisabled = !result.data[0].activoMensajesC;
        this.foroDisabled = !result.data[0].activoForoC;
        this.encuetasDisabled = !result.data[0].activoEncuestasC;
        this.reservasDisabled = !result.data[0].activoReservasC;
        this.agentesDisabled = !result.data[0].activoAgentesC;
        this.colaboradoresDisabled = !result.data[0].activoColaboradoresC;
      });

    //Load all available Langs
    this.idiomasService
      .GetAll()
      .pipe()
      .subscribe((result) => {
        //console.log('ListaIdomas');
        //console.log(result.data)
        for (var i in result.data) {
          // Añadir a idiomas propiedad selected
          result.data[i].selected = false;
          this.listaIdiomas.push(result.data[i]);
        }
        this.dataLoaded = true;

        //Load Organization Selected Langs
        //Must load AFTER getIdiomas
        if (!this.isAddMode) {
          this.idiomasService
            .GetById(this.route.snapshot.params['id'])
            .pipe()
            .subscribe((result) => {
              //console.log('Loading Organization selected Langs ');
              for (var i in result.data) {
                //console.log(result.data[i]);
                this.selectedLangs.push(result.data[i]);
                this.containersCampos.push(result.data[i].codigo);
                for (var j in this.listaIdiomas) {
                  if (this.listaIdiomas[j].codigo == result.data[i].codigo) {
                    this.listaIdiomas[j].selected = true;
                  }
                }
              }
            });
        }
      });
    
  }

  actualizarDepartamento(e) {
    console.log(e);
    this.selectedDepartamentoId = e;
    // MARI
    // this.form.patchValue({
    //   departamento: this.selectedDepartamentoId,
    // });
    // console.log(this.form.get('departamento').value);
  }

  onClickGuardar()
  {
    var reEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    this.nombreRequerido = (this.nombre == '' || this.nombre == null);

    /*
    this.emailUsuarioRequerido = (this.emailUsuario=='' ||this.emailUsuario==null);
    this.contrasenaRequerido = (this.contrasena=='' || this.contrasena==null);
    this.correosReservasRequerido = (this.correosReservas=='' || this.correosReservas==null);

    if (this.activoFacebook)   this.fblinkRequerido = (this.fblink=='' || this.fblink==null);
    if (this.activoTwitter)  this.twlinkRequerido = (this.twlink=='' || this.twlink==null);
    if (this.activoFlicker) this.fllinkRequerido = (this.fllink=='' || this.fllink==null);
    if (this.activoInstagram) this.iglinkRequerido = (this.iglink=='' || this.iglink==null);
    if (this.activoYoutube) this.ytlinkRequerido = (this.ytlink=='' || this.ytlink==null);
    this.mediaRequerido = (this.media=='' || this.media==null);
    */

    if (this.email.length>0)  this.emailValidado = !(reEmail.test(this.email));

    if (this.emailUsuario.length>0)   this.emailUsuarioValidado = !(reEmail.test(this.emailUsuario));

    this.numIdiomasValidado = (this.selectedLangs.length==0);

    this.correcto=!this.nombreRequerido //&& !this.emailValidado
                   //&& !this.emailUsuarioRequerido 
                   //&& !this.emailUsuarioValidado 
                   && !this.numIdiomasValidado;
                  // !this.contrasenaRequerido && !this.correosReservasRequerido &&
                  // !this.fblinkRequerido && !this.twlinkRequerido && !this.fllinkRequerido && 
                  // !this.iglinkRequerido && !this.ytlinkRequerido && !this.mediaRequerido  ;

                  
    if (this.numIdiomasValidado) this.alertService.error(   this.translateService.instant('botones.idiomaRequerido') );

    if (this.correcto)
    {
      this.alertService.clear();
      this.loading = true;
      this.crearColaborador();
    }
    else     this.alertService.error(   this.translateService.instant('botones.faltanDatos') );
  }

  //todo mari
  private crearColaborador() 
  {
    //Generate base64 image strings
    if (!this.isNotInsA) {
      this.createImageFromBase64(this.imagenAvatar, 'avatar');
      this.logoDocumentoAgregar = this.imagenAvatar;
    }
    if (!this.isNotInsC) {
      this.createImageFromBase64(this.imagenCabecera, 'cabecera');
      this.cabeceraDocumentoAgregar = this.imagenCabecera;
    }

    this.rellenarTraducciones();

    //balio guztiak modeloari esleitu
    this.myOrganizacion.nombre = this.nombre;
    this.myOrganizacion.organizacionActiva = this.activoOrganizacion;
    this.myOrganizacion.imagenAvatar = this.imagenAvatar;
    this.myOrganizacion.imagenCabecera = this.imagenCabecera;

    //idiomas
    this.myOrganizacion.idIdiomas=1;
    this.myOrganizacion.idCamposIdiomas=1;

    //email
    this.myOrganizacion.email=this.email;
    this.myOrganizacion.smtp=this.smtp;
    this.myOrganizacion.emailUsuario=this.emailUsuario;
    this.myOrganizacion.emailPassword=this.contrasena;
    this.myOrganizacion.cambiarPasswordActivo=this.activoGuardarPassword;
    this.myOrganizacion.puerto=this.puerto;
    //INFORMACION
    this.myOrganizacion.infoNotificacionesActivas=this.activoNotificaciones;
    this.myOrganizacion.infoFarmaciasActivas=this.activoFarmacias;
    this.myOrganizacion.infoFaqActivo=this.activoFAQ;
    this.myOrganizacion.infoGestionDocumentalActiva=this.activoGesDoc;
    this.myOrganizacion.infoContactosActivo=this.activoContactos;
    //AGENDA
    this.myOrganizacion.agendaActiva= this.activoAgenda;
    this.myOrganizacion.agendaEdadMinima =this.agendaEdadMin;
    //MENSAJERIA
    this.myOrganizacion.mensajeriaActiva=this.activoMensajeria;
    this.myOrganizacion.mensajeriaIncidenciasDepUnico=this.activoIncidenciasDepUnico;
    //if (this.selectedDepartamentoId>0)    this.myOrganizacion.idDepartamento=this.selectedDepartamentoId;
    //else this.myOrganizacion.idDepartamento=1;
    //RESERVAS
    this.myOrganizacion.reservasActivas=this.activoReservas;
    this.myOrganizacion.reservasConfirmacion=this.activoConfirmReserva;
    this.myOrganizacion.reservasUsuarioEnCalendario=this.activoMostrarUsuarioCalendario;
    this.myOrganizacion.reservasDiasMinimos=this.mindiasresrva;
    this.myOrganizacion.reservasApertura=this.apertura; //
    this.myOrganizacion.reservasCorreos=this.correosReservas;
    //coordenadas
    this.myOrganizacion.coordenadasLatitud=this.latitud;
    this.myOrganizacion.coordenadasLongitud=this.longitud;
    this.myOrganizacion.coordenadasZoom=this.zoom;
    //foro
    this.myOrganizacion.foroActivo=this.activoForo;
    this.myOrganizacion.foroEdadMinima=this.edadforo;
    this.myOrganizacion.foroPermUsuariosCrearPost=this.foroPermUsuariosCrearPost;
     //encuestas
     this.myOrganizacion.encuestasActivas=this.activoEncuestas;
     this.myOrganizacion.encuestasEdadMinima=this.edadencuestasmin;
     //RRSS
     this.myOrganizacion.rrssFacebookActivo=this.activoFacebook;
     this.myOrganizacion.rrssFacebookUrl=this.fblink;
     this.myOrganizacion.rrssTwitterActivo=this.activoTwitter;
     this.myOrganizacion.rrssTwitterUrl=this.twlink;
     this.myOrganizacion.rrssFlickerActivo=this.activoFlicker;
     this.myOrganizacion.rrssFlickerUrl=this.fllink;
     this.myOrganizacion.rrssInstagramActivo=this.activoInstagram;
     this.myOrganizacion.rrssInstagramUrl=this.iglink;
     this.myOrganizacion.rrssYoutubeActivo=this.activoYoutube;
     this.myOrganizacion.rrssYoutubeUrl=this.ytlink;
     this.myOrganizacion.rrssMultimedia=this.media;
       //agentes
    this.myOrganizacion.agentesActivos=this.activoAgentes;
    this.myOrganizacion.agentesTiposActivos=this.activoTipos;
    //intercomunicacion usuarios
    this.myOrganizacion.permUsuariosPublicar=this.permUsuariosPublicar;
    this.myOrganizacion.permUsuariosComentar=this.permUsuariosComentar

    
    this.myModel.organizacion = this.myOrganizacion;
    this.myModel.listaTraducciones = this.listaTraduccionesEnviar;

    this.colaboradorService.crearColaborador(this.myModel).subscribe((result) => {
      if (!result.error) {
        //Send language selection creation(We need the Org id)
        console.log('RESPUESTA CREAR');
        console.log(result);

        this.alertService.success(
          this.translateService.instant('botones.creadocorrectamente'),
          { keepAfterRouteChange: true }
        );
         this.router.navigate(['colaboradores']);
      }
      else 
      { //errorea gertatu denean:
        this.alertService.error(
          this.translateService.instant('enlaces.errorcrear')
        );
        this.loading = false;
      }

    }
    );
  }

  private rellenarTraducciones()
  {
    let orgTrad = new OrganizacionTraduccion();

    for (let elem in this.selectedLangs) {
      console.log(this.selectedLangs[elem].codigo);
      orgTrad = new OrganizacionTraduccion();
      orgTrad.filled = false;
      orgTrad.idIdioma = this.selectedLangs[elem].codigo;
      orgTrad.nombreIdioma = this.selectedLangs[elem].nombre;
      orgTrad.descripcion = this.camposValoresDescripcion[this.selectedLangs[elem].codigo];
      orgTrad.colaboradores = this.camposValoresColaboradores[this.selectedLangs[elem].codigo];
      orgTrad.politicaDePrivacidad = this.camposValoresPrivacidad[this.selectedLangs[elem].codigo];
      //orgTrad.faq = this.camposValoresFAQ[this.selectedLangs[elem].codigo]
      orgTrad.id='0';
      this.listaTraduccionesEnviar.push(
        orgTrad
      );
    }
  }


  public selectedLangsChange(isTrue, lang) {
    console.log('SelectedLangs');
    console.log(this.selectedLangs);
    if (isTrue) {
      this.selectedLangs.push(lang);
      //Añadimos un div IdiomasCampos más con los campos
      this.containersCampos.push(lang.codigo);
    } else {
      const index = this.selectedLangs.indexOf(lang);
      this.selectedLangs.splice(index, 1);

      const index2 = this.containersCampos.indexOf(lang.codigo);
      this.containersCampos.splice(index2, 1);
      //Delete that Lang JsonForm
      //TODO this.jsonCamposIdomas.push()
    }
    this.selectedLangs.sort((a, b) => a - b);
    this.idiomasGuardar = true;
  }

  //Procesado de Imagenes
  createImageFromBase64(image, img) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    console.log(objectURL);
    if (img == 'avatar')
      this.avatarPreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    else
      this.cabeceraPreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
  onClickEliminarImagen(content) {
    this.modalReference = this.modalService.open(content, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }
  public selectEventHandler(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {
      if (!file.validationErrors) {
        this.formImErr = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid,
          };

          that.avatarPreview = image.src.toString();
          that.imagenAvatar = image.src.toString().split(',', 2)[1];
          that.avatarBorrado = false;
          that.isNotInsA = false;
        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErr = true;
      }
    });
  }
  public selectEventHandlerCabecera(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {
      if (!file.validationErrors) {
        this.formImErr = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid,
          };

          that.cabeceraPreview = image.src.toString();
          that.imagenCabecera = image.src.toString().split(',', 2)[1];
          that.cabeceraBorrada = false;
          that.isNotInsC = false;
        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErr = true;
      }
    });
  }

  public removeEventHandler(e: RemoveEvent): void {
    this.formImErr = false;
    this.imagenAvatar = '';
    this.avatarPreview = null;
  }
  public removeEventHandlerCabecera(e: RemoveEvent): void {
    this.formImErr = false;
    this.imagenCabecera = '';
    this.cabeceraPreview = null;
  }
  eliminarImagen(opt) {
    if (opt == 'avatar') {
      this.avatarBorrado = true;
      this.imagenAvatar = null;
      this.avatarPreview = null;
    } else {
      this.cabeceraBorrada = true;
      this.imagenCabecera = null;
      this.cabeceraPreview = null;
    }
    
    this.modalReference.close();
  }
  
  
  valueIdiomaCamposChange(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log(value.codigo);
    console.log(this.camposValoresDescripcion);
    console.log(this.camposValoresColaboradores);
    console.log(this.camposValoresPrivacidad);
    console.log(this.camposValoresFAQ);

    this.camposSelectedLang = value.codigo;
  }


}
