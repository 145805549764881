<div class="row">
    <div class="col-lg-4">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "foro.creartopic" | translate }}</h3>
        </div>
        <div class="card-body">
  
          <!--Lista idiomas-->
          <div class="clearfix">
            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>
          </div>
          <kendo-dropdownlist
            #dropdownI
            [data]="listaIdiomas"
            textField="nombre"
            valueField="codigo"
            [(ngModel)]="selectedIdioma"
          ></kendo-dropdownlist>
          <!--Titulo-->
          <kendo-textbox
            placeholder="{{ 'foro.titulotopic' | translate }}"
            [clearButton]="true"
            [(ngModel)]="nombreTopic"
          >
            <ng-template kendoTextBoxPrefixTemplate>
              <button kendoButton look="clear" icon="calendar"></button>
            </ng-template>
          </kendo-textbox>
          <!--Cuerpo-->
          <div class="form-group">
            <kendo-label
              text="{{ 'foro.descripcion' | translate }}"
            >
              <textarea
                kendoTextArea
                style="resize: none"
                rows="5"
                [(ngModel)]="descripcionTopic"
                placeholder="{{ 'recintos.ph_descripcion' | translate }}"
              >
              </textarea>
            </kendo-label>
          </div>
          <!--Imagen Publicacion-->
          <label class="image-upload-container btn btn-bwm">
            <span>Select Image</span>
            <input
              #imageInput
              type="file"
              accept="image/*"
              (change)="imagePreview($event)"
            />
          </label>
  
          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px" />
          </div>
          
        <!--CheckBox Activo-->
        <div class="form-group">
            <kendo-label
              class="k-checkbox-label"
              text="{{ 'foro.activo' | translate }}"
            ></kendo-label>
            <div class="caja">
              <kendo-switch
                [(ngModel)]="topicActivo"
                onLabel="{{ 'switch.activo' | translate }}"
                offLabel="{{ 'switch.inactivo' | translate }}"
              ></kendo-switch>
            </div>
          </div>

        <!-- Traducciones -->
        <div class="form-group">
            <button
              type="button"
              class="btn btn-primary btn-sm mr-1"
              (click)="btnAddTraduccion()"
            >
              {{ "publicaciones.anadir" | translate }}
            </button>
            <div class="listado-traducciones">
              <div class="traduccion" *ngFor="let idioma of listaTraducciones">
                {{ idioma.nombre }}
                <button
                  type="button"
                  class="btn btn-info btn-sm mr-1"
                  (click)="btnDeleteTraduccion(idioma)"
                >
                  X
                </button>
              </div>
            </div>
            <!--Boton Publicar-->
            <button
              type="button"
              class="btn btn-success btn-sm mr-1"
              (click)="btnCrearTopic()"
            >
              {{ "foro.crear" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-danger btn-sm mr-1"
              (click)="btnCancelar()"
            >
              {{ "botones.cancelar" | translate }}
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
  