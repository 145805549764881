import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ForoPost, ForoTopic, ForoTopicModel} from '@app/_models/foro';

const baseUrl = `${environment.apiUrl}/foro`;

@Injectable()
export class ForoService {

  constructor(private http: HttpClient) {
  }

  getTopicsFromEnte(idOrg:string):Observable<any> {
    return this.http.get<any>(`${baseUrl}/`+idOrg, { withCredentials: true });
  }
  
  public crearTopic(model: ForoTopicModel): Observable<any> {
    return this.http.post(
      baseUrl + '/createtopic/',
      JSON.stringify(model),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public editTopic(topic: ForoTopic): Observable<any> {
    return this.http.post(
      baseUrl + '/updatetopic/',
      JSON.stringify(topic),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteTopics(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/delete', formData, {
      withCredentials: true,
    });
  }

  deletePostsFromTopic(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/deleteposts', formData, {
      withCredentials: true,
    });
  }
  
  
  getTopicByIdLang(idTopic:number,lang:number):Observable<any>{
     return this.http.get<any>(`${baseUrl}/admin/topic/`+idTopic + '/'+lang, { withCredentials: true });
  }

  getPostsFromTopic(idTopic:number):Observable<any>{
    return this.http.get<any>(`${baseUrl}/topic/`+idTopic, { withCredentials: true });
  }

  getMensajesFromTopic(idTopic:number,idPost:number):Observable<any>{
    return this.http.get<any>(`${baseUrl}/resp/`+idTopic + '/'+ idPost, { withCredentials: true });
  }
  deleterespuesta(idrespuesta:number):Observable<any>{
    return this.http.get<any>(`${baseUrl}/deleterespuesta/`+idrespuesta , { withCredentials: true });
  }
  
  public crearPost(model: ForoPost): Observable<any> {
    return this.http.post(
      baseUrl + '/createpost/',
      JSON.stringify(model),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }
  
}
