<div class="row" *ngIf="!editMode">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "videos.titulo" | translate }}</h3>
        </div>
        <div class="card-body">

          <div class="form-group">
            <!--Lista idiomas-->
  
            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>
  
            <div class="caja">
              <kendo-dropdownlist #dropdownI
                                  [data]="listaIdiomas"
                                  textField="nombre"
                                  valueField="codigo"
                                  [(ngModel)]="selectedIdioma" ></kendo-dropdownlist>
            </div>
          </div>
  
          <div class="form-group">
            <label>Título</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox placeholder="{{ 'publicaciones.titulo_publi' | translate }}"
                             [clearButton]="true"
                             [(ngModel)]="titulo">
                <ng-template kendoTextBoxPrefixTemplate>
                </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
          <!--Url Video-->
          <div class="form-group">
            <!-- <kendo-label text="{{ 'publicaciones.cuerpo_publi' | translate }}">
              <textarea kendoTextArea style="resize: none" rows="5" [(ngModel)]="cuerpo"> </textarea>
            </kendo-label> -->
            <label>{{ "videos.urlvideo" | translate }}</label>
            <div class="caja">
              <kendo-textbox placeholder="{{ 'publicaciones.urlvideo' | translate }}"
                             [clearButton]="true"
                             [(ngModel)]="urlVideo">
                <ng-template kendoTextBoxPrefixTemplate>
                </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
          <!--Imagen Portada Video-->
          <label class="image-upload-container btn btn-bwm">
           {{ "videos.seleccionimagen" | translate }}
          </label>
            <br>
            <input #imageInput
                   type="file"
                   accept="image/*"
                   (change)="imagePreview($event)">
          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px;">
          </div>

          <!-- Traducciones -->
          <div class="form-group">
            <button type="button"
                    class="btn btn-primary btn-sm mr-1"
                    (click)="btnAddTraduccion()">
              {{ "publicaciones.anadir" | translate }}
            </button>
            <div class="listado-traducciones">
              <div class="traduccion" *ngFor="let idioma of listaTraducciones">
                {{idioma.nombre}}
  
                <button type="button"
                        class="btn btn-info btn-sm mr-1"
                        (click)="btnDeleteTraduccion(idioma)">
                  X
                </button>
  
              </div>
            </div>
  
            <!--Boton Publicar-->
            <button type="button"
                    class="btn btn-success btn-sm mr-1"
                    (click)="btnCrearVideo()">
              {{ "publicaciones.crear" | translate }}
            </button>
            <button type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()">
            {{ "botones.cancelar" | translate }}
          </button>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  <!--MODO EDICIÓN-->
  <div *ngIf="editMode">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "videos.tituloupdate" | translate }}</h3>
        </div>
        <div class="card-body">

          <button type="button"
          class="btn btn-success btn-sm mr-1"
          (click)="btnNuevaPortada()">
          {{ "videos.nuevaportada" | translate }}
        </button>

          <div class="form-group">
            <!--Lista idiomas-->
  
            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>
  
            <div class="caja">
              <kendo-dropdownlist #dropdownI
                                  [data]="listaIdiomas"
                                  textField="nombre"
                                  valueField="codigo"
                                  (valueChange)="loadPortadaLang($event)"
                                  [(ngModel)]="selectedIdioma" ></kendo-dropdownlist>
            </div>
          </div>
  
          <div class="form-group">
            <label>Título</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox placeholder="{{ 'publicaciones.titulo_publi' | translate }}"
                             [clearButton]="true"
                             [(ngModel)]="titulo">
                <ng-template kendoTextBoxPrefixTemplate>
                </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
          <!--Url Video-->
          <div class="form-group">
            <!-- <kendo-label text="{{ 'publicaciones.cuerpo_publi' | translate }}">
              <textarea kendoTextArea style="resize: none" rows="5" [(ngModel)]="cuerpo"> </textarea>
            </kendo-label> -->
            <label>{{ "videos.urlvideo" | translate }}</label>
            <div class="caja">
              <kendo-textbox placeholder="{{ 'publicaciones.urlvideo' | translate }}"
                             [clearButton]="true"
                             [(ngModel)]="urlVideo">
                <ng-template kendoTextBoxPrefixTemplate>
                </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
          <!--Imagen Portada Video-->
          <label class="image-upload-container btn btn-bwm">
           {{ "videos.seleccionimagen" | translate }}
          </label>
            <br>
            <input #imageInput
                   type="file"
                   accept="image/*"
                   (change)="imagePreview($event)">
          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px;">
          </div>
          <div *ngIf="tamMax">
            <label style="color: red;">
              El tamaño máximo permitidio por imagen es de 24MB
             </label>
          </div>
          <!-- Traducciones -->
          <div class="form-group">
            <!--Boton Publicar-->
            <button type="button"
                    class="btn btn-success btn-sm mr-1"
                    (click)="btnUpdatePortada()">
              {{ "publicaciones.actualizar" | translate }}
            </button>
            <button type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()">
            {{ "botones.cancelar" | translate }}
          </button>
          </div>
  
        </div>
      </div>
    </div>
  </div>