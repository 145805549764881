import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Usuario } from '@app/_models';
import { UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'grid-usuarios-admin',
  templateUrl: './grid-usuarios.component.html'
})
export class GridUsuariosComponent implements OnInit {

  listaSeleccionados:Usuario[]=[];
  listaFiltrados: Usuario[] = [];
  isBusy: boolean = false;
  lang;
  private translate: TranslateService;

  //ListaUsuariosInicial
  @Input() listaUsuariosGrid: Usuario[] = [];
  //ListaUsuariosFinal
  @Output() outUsuarios = new EventEmitter<Usuario[]>();
  
  constructor(
    private usuarioService: UsuariosService,
    translate: TranslateService,
  ) {}

  ngOnInit(): void {
  }

  handleFilter(query: string) {
    if (!this.isBusy && query.length > 1) {
      this.isBusy = true;
      this.usuarioService.search(query).subscribe((resultSearch) => {
        this.listaFiltrados = resultSearch.data;
        this.isBusy = false;
      });
    }
  }

  removeTag(e){
  }

  valueChange(e){
  }

  addUsuarios(){
    if(this.listaSeleccionados.length>0){
      this.listaSeleccionados = this.listaSeleccionados.filter((x)=>!this.listaUsuariosGrid.some((u)=>u.id==x.id));
      this.listaUsuariosGrid = [...this.listaUsuariosGrid,...this.listaSeleccionados];
      this.listaSeleccionados=[];
      this.outUsuarios.emit(this.listaUsuariosGrid);
    }
  }
  eliminarUsuario(userEliminar:Usuario){
    this.listaUsuariosGrid = this.listaUsuariosGrid.filter((user)=>user.id!=userEliminar.id);
    this.outUsuarios.emit(this.listaUsuariosGrid);
  }
}
