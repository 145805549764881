export class Equipo {
    id: string;
    idOrg:string;
    urlFoto:string;
    nombre:string;
    descripcion:string;
    localidad:string;
    activo:boolean;
    created:Date;
    updated:Date;
    idIdioma:number;
    listaTraducciones:EquipoTrad[];
    updateFoto:boolean;
}

export class EquipoTrad{
    idIdioma:number;
    nombre:string;
    descripcion:string;
    nombreIdioma:string;
    filled:boolean;
}

export class Temporada{
    id:number;
    nombre:string;
}

export class Jugador{
    id:number;
    idUser:number;//Cuenta Usuario Vinculado
    idEquipo:string;
    idTemporada:number;
    nombreEquipo:string;
    idOrg:string;
    nombre:string='';
    apellidos:string='';
    nacionalidad:string='';
    fechaNacimiento:Date;
    altura:string='';
    peso:string='';
    posicion:string='';
    lateralidad:string='';
    fechaFinContrato:Date;
    observaciones:string;
    hasFacebook:boolean;
    urlFacebook:string='';
    hasTwitter:boolean;
    urlTwitter:string='';
    hasInstagram:boolean;
    urlInstagram:string='';
    hasTikTok:boolean;
    urlTikTok:string='';
    hasYoutube:boolean;
    urlYoutube:string='';
    hasCustomUrl:boolean;
    customUrl:string='';
    urlFoto:string;
    urlFondo:string;
    updateFoto:boolean;
    updateFondo:boolean;
    listaTraducciones:JugadorTrad[]=[];
    idIdioma:number;
    listaDatosPlantilla:BloqueInput[];
}

export class JugadorTrad{
    idIdioma:number;
    posicion:string;
    lateralidad:string;
    nacionalidad:string;
    observaciones:string;
    nombreIdioma:string;
    filled:boolean;
} 


//PLANTILLAS JUGADOR
export class PlantillaEstadisticas{
    id:number=0;
    idOrg:string;
    nombre:string='';
    listaCategoriaBloques:CategoriaBloque[]=[];
}

export class CategoriaBloque{
    id:number;
    idPlantilla:number;
    nombre:string='';
    idIdioma:number;
    isActivo:boolean;
    orden:number;
    listaBloquesInput:LineasBloques[]=[];
    listaTraducciones:CategoriaBloqueTrad[]=[];
}

export class CategoriaBloqueTrad{
idIdioma:number;
idCategoria:number;
nombre:string;
filled:boolean;
nombreIdioma:string;
}

export class LineasBloques{
id:string;
idCategoria:number;
listaInputs:BloqueInput[];
orden:number;
}

export class BloqueInput{
idCategoria:number;
idLinea:number;
isCustom:boolean;
valorCustom:string='';
idTipoComputoValor:number;//dropdown tipos computables
nombreTipoComputable:string='';
listaTraducciones:BloqueInputTrad[]=[];
filled:boolean=false;
activo:boolean=true;
nombre:string='';
nombreCategoria:string='';
idIdioma:number;
}


export class BloqueInputTrad{
    idIdioma:number;
    idBloque:number;
    nombre:string;
    filled:boolean;
    nombreIdioma:string;
}

export class TipoComputable{
    id:number=0;
    nombre:string='';
}

//PARTIDOS
export class Partido{
    id:number;
    jornada:number=1;
    nombreEquipoRival:string;
    fecha:Date;
    fechaInicio:Date;
    fechaFin:Date;
    listaJugadores:Jugador[];
    ganado:boolean;
    resultado:ResultadoPartido;
}
export class ResultadoPartido{
    golesEquipo:number;
    golesRival:number;

}