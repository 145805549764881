import { Component, OnInit } from '@angular/core';
import {
  Organizacion,
  OrganizacionModel,
  OrganizacionTraduccion,
  Recurrencia,
} from '@app/_models/organizacion';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  ConfiguracionService,
  DepartamentosService,
} from '@app/_services';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import {
  FileInfo,
  FileRestrictions,
  SelectEvent,
  RemoveEvent,
} from '@progress/kendo-angular-upload';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IdiomasService } from '@app/_services/idiomas.service';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Idioma } from '../../_models/idioma';
import { CamposIdioma } from '../../_models/camposidiomas';
import { AgentesService } from '@app/_services/agentes.service';
import { MenuService } from '@app/_services/';
import { TipoAgente } from '@app/_models/tipoagente';
import { DepartamentoEnte } from '@app/_models/departamentoente';
import { DepartamentosEnteService } from '@app/_services/departamentosente.service';
import { Align } from '@progress/kendo-angular-popup';
import {
  DataStateChangeEvent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Usuario } from '@app/_models';

@Component({
  selector: 'app-agentes-editar',
  templateUrl: './agentes-editar.component.html',
})
export class AgentesEditarComponent implements OnInit {

  public correcto:boolean=true;

  myOrganizacion: Organizacion = new Organizacion();
  myModel: OrganizacionModel = new OrganizacionModel();

  numIdiomasValidado: boolean = false;

  //Traducciones
  listaTraduccionesEnviar: OrganizacionTraduccion[] = [];

  //Data
  nombre: string = '';
  nombreRequerido: boolean = false; //validar
  activoOrganizacion:boolean = true;
  idPadre: string = '';

  logoDocumentoDocumento: any = "";
  logoDocumentoAgregar: any = "";
  logoDocumentoRequerido: boolean = false; //validar

  cabeceraDocumentoDocumento: any = "";
  cabeceraDocumentoAgregar: any = "";
  cabeceraDocumentoRequerido: boolean = false; //validar

  //EMAIL:
  email: string = '';
  emailValidado: boolean=false;
  smtp: string = '';
  puerto: number;
  emailUsuario: string = '';
  emailUsuarioValidado: boolean=false;
  emailUsuarioRequerido: boolean = false; //validar
  activoGuardarPassword:boolean = false;
  contrasena: string;
  contrasenaRequerido: boolean = false; //validar

  //INFORMACION
  activoNotificaciones:boolean = true;
  activoFarmacias: boolean = true; 
  activoFAQ: boolean = false; 
  activoGesDoc: boolean = true; 
  activoContactos: boolean = false; 

  //AGENDA
  activoAgenda:boolean = true;
  agendaEdadMin: number;

  //MENSAJERIA
  activoMensajeria:boolean = true;
  activoIncidenciasDepUnico:boolean = true;

  //RESERVAS
  activoReservas:boolean = true;
  activoConfirmReserva:boolean = true;
  activoMostrarUsuarioCalendario:boolean  = false;
  mindiasresrva:number;
  apertura:string='Diario';
  correosReservas:string='';
  correosReservasRequerido = false; //validar

  //COORDENADAS
  latitud:string='';
  longitud:string='';
  zoom:number;

  //FORO
  activoForo: boolean =true;
  edadforo:number;
  foroPermUsuariosCrearPost:boolean=true;

  //ENCUESTAS
  activoEncuestas: boolean =true;
  edadencuestasmin:number;

  //RRSS
  activoFacebook: boolean =true;
  fblink:string='';
  fblinkRequerido: boolean=false; //validar

  activoTwitter: boolean =false;
  twlink:string='';
  twlinkRequerido: boolean=false; //validar

  activoFlicker: boolean =false;
  fllink:string='';
  fllinkRequerido: boolean=false; //validar

  activoInstagram: boolean =true;
  iglink:string='';
  iglinkRequerido: boolean=false; //validar

  activoYoutube: boolean =false;
  ytlink='';
  ytlinkRequerido: boolean=false; //validar

  media:string='';
  mediaRequerido: boolean=false; //validar

  //AGENTES
  activoAgentes: boolean =true;
  activoTipos: boolean =true;
  isPrivado:boolean=false;
  //INTERCOMUNICACION USUARIOS
  permUsuariosPublicar: boolean =true;
  permUsuariosComentar: boolean =true;

  //form: FormGroup;
  loading = false;
  isAddMode: boolean;
  codigo: string;
  imagenAvatar: any;
  imagenCabecera: any;
  apItems: string[];
  public avatarPreview;
  public cabeceraPreview;
  imagenAnt: any;
  submitted = false;
  formImErr = false;
  avatarBorrado = false;
  cabeceraBorrada = false;
  departamentos = [];
  aperturaRangos = [];
  imagenConfiguracion: any;
  isNotInsA = false;
  isNotInsC = false;
  modalReference: NgbModalRef;
  idiomasGuardar: boolean = false;
  idiomaEditando: any;
  public codigos: any[] = [];
  camposSelectedLang: number = 0;
  containersCampos: number[] = [];
  camposValoresDescripcion = [];
  camposValoresColaboradores = [];
  camposValoresFAQ = [];
  jsonCamposIdomas = [];
  dataLoaded: boolean = false;

  public listaIdiomas = [];
  public selectedLangs = [];
  public listaCampos = [];

  idOrg:string;

  //Combo AgentesTipo
  public tiposAgentesDatos: TipoAgente[];
  public selectedAgenteItem: TipoAgente;
  public tipoAgente: TipoAgente;
  public selectedAgenteTipoId: number;
  selectedAgenteValue: number = 0;
  public selectedAgenteRequerido: boolean= false; //validar

  //Combo Departamentos
  public departamentosDatos: DepartamentoEnte[];
  public selectedDepartamento: DepartamentoEnte;
  public departamento: DepartamentoEnte;
  public selectedDepartamentoId: number;

  //Permisos
  notificacionesDisabled = false;
  faqDisabled = false;
  gesDocDisabled = false;
  contactosDisabled = false;
  facebookDisabled = false;
  twitterDisabled = false;
  flickrDisabled = false;
  instagramDisabled = false;
  youtubeDisabled = false;
  agendaDisabled = false;
  mensajesDisabled = false;
  foroDisabled = false;
  encuetasDisabled = false;
  reservasDisabled = false;
  agentesDisabled = false;
  colaboradoresDisabled = false;

  publicarDisabled = false;
  comentarDisabled = false;

  //PopUp
  public show = false;
  agentesSinAsignarLista = [];
  showMiembros:boolean;
  listaUsuariosMiembros=[];
  listaTMP = [];
  updateSubsNeeded = false;

  public showAdmins = false;
  usuariosAdminsLista = [];

  listaTMPAdmins = [];
  updateAdminsNeeded = false;

  regexLink =
    '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$';

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpeg', '.jpg'],
    maxFileSize: 1048576,
  };

  constructor(
    //private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private menuService: MenuService,
    private agenteService: AgentesService,
    private configuracionService: ConfiguracionService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private idiomasService: IdiomasService,
    private departamentoService: DepartamentosService,
    private departamentoEnteService: DepartamentosEnteService,
    private router: Router,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.codigo = this.route.snapshot.params['idorg']; //lehen id zegoen.
    this.idOrg = this.route.snapshot.params['idorg'];
    this.isAddMode = !this.codigo;
    this.dataLoaded = this.isAddMode;
    //Load Apertura Reservas Options
    this.aperturaRangos = [
      this.translateService.instant('organizaciones.opsiempre'),
      this.translateService.instant('organizaciones.opdiario'),
      this.translateService.instant('organizaciones.opsemanal'),
      this.translateService.instant('organizaciones.opmensual'),
    ];

    //Load Permisos given By Admin
    this.configuracionService
      .GetConfig()
      .pipe()
      .subscribe((result) => {
        console.log('PERMISOS LOADED =');
        console.log(result.data[0]);
        this.notificacionesDisabled = !result.data[0].activoNotificacionesA;
        this.faqDisabled = !result.data[0].activoFAQA;
        this.gesDocDisabled = !result.data[0].activoGesDocA;
        this.contactosDisabled = !result.data[0].activoContactosA;
        this.facebookDisabled = !result.data[0].activoFacebookA;
        this.twitterDisabled = !result.data[0].activoTwitterA;
        this.flickrDisabled = !result.data[0].activoFlickerA;
        this.instagramDisabled = !result.data[0].activoInstagramA;
        this.youtubeDisabled = !result.data[0].activoYoutubeA;
        this.agendaDisabled = !result.data[0].activoAgendaA;
        this.mensajesDisabled = !result.data[0].activoMensajesA;
        this.foroDisabled = !result.data[0].activoForoA;
        this.encuetasDisabled = !result.data[0].activoEncuestasA;
        this.reservasDisabled = !result.data[0].activoReservasA;
        this.agentesDisabled = !result.data[0].activoAgentesA;
        this.colaboradoresDisabled = !result.data[0].activoColaboradoresA;
      });

    //Load all available Langs
    this.idiomasService
      .GetAll()
      .pipe()
      .subscribe((result) => {
        //console.log('ListaIdomas');
        //console.log(result.data)
        for (var i in result.data) {
          // Añadir a idiomas propiedad selected
          result.data[i].selected = false;
          this.listaIdiomas.push(result.data[i]);
        }
        this.dataLoaded = true;

        //Load Organization Selected Langs
        //Must load AFTER getIdiomas
        if (!this.isAddMode) {
          this.idiomasService
            .GetById(this.route.snapshot.params['idorg'])
            .pipe()
            .subscribe((result) => {
              //console.log('Loading Organization selected Langs ');
              for (var i in result.data) {
                //console.log(result.data[i]);
                this.selectedLangs.push(result.data[i]);
                this.containersCampos.push(result.data[i].codigo);
                for (var j in this.listaIdiomas) {
                  if (this.listaIdiomas[j].codigo == result.data[i].codigo) {
                    this.listaIdiomas[j].selected = true;
                  }
                }
              }
            });
        }
      });

    if (!this.isAddMode) {
      //EDIT ORGANIZATION

      //Load Campos of selected Idiomas
      this.idiomasService
        .getCamposByIdOrg(this.route.snapshot.params['idorg'])
        .pipe()
        .subscribe((result) => {
          // console.log('CAMPOS LOADED');
          // console.log(result);
          for (var i in result.data) {
            //console.log(result.data[i]);
            this.camposValoresDescripcion[result.data[i].idIdioma] = result.data[i].descripcion;
            this.camposValoresColaboradores[result.data[i].idIdioma] = result.data[i].colaboradores;
            this.camposValoresFAQ[result.data[i].idIdioma] = result.data[i].faq;
          }
        });

      //Load Organization
      console.log('Load Organization');
      this.agenteService
        .GetById(this.idOrg)
        .pipe()
        .subscribe((result) => {
          //console.log('Cargando datos...');
          //console.log(result);
          this.menuService.titulo = result.data[0].nombre;
          this.selectedAgenteItem = result.data[0].tipoAgente;
          this.selectedAgenteValue = result.data[0].tipoAgente;
          this.idPadre = result.data[0].idPadre;

          this.nombre = result.data[0].nombre
          this.logoDocumentoAgregar = result.data[0].imagenAvatar;
          this.cabeceraDocumentoAgregar = result.data[0].imagenCabecera;
          this.activoOrganizacion = result.data[0].organizacionActiva;

          //email
          this.email = result.data[0].email;
          this.smtp = result.data[0].smtp;
          this.puerto = result.data[0].puerto;
          this.emailUsuario = result.data[0].emailUsuario;
          this.contrasena = result.data[0].emailPassword;
          this.activoGuardarPassword = result.data[0].cambiarPasswordActivo;

          //info
          this.activoNotificaciones = result.data[0].infoNotificacionesActivas;
          this.activoFarmacias = result.data[0].infoFarmaciasActivas;
          this.activoFAQ = result.data[0].infoFaqActivo;
          this.activoGesDoc = result.data[0].infoGestionDocumentalActiva;
          this.activoContactos = result.data[0].infoContactosActivo;

          //agenda
          this.activoAgenda = result.data[0].agendaActiva;
          this.agendaEdadMin = result.data[0].agendaEdadMinima;

          //MENSAJERIA
          this.activoMensajeria = result.data[0].mensajeriaActiva;
          this.activoIncidenciasDepUnico = result.data[0].mensajeriaIncidenciasDepUnico;

          //RESERVAS
          this.activoReservas = result.data[0].reservasActivas;
          this.activoConfirmReserva = result.data[0].reservasConfirmacion;
          this.activoMostrarUsuarioCalendario = result.data[0].reservasUsuarioEnCalendario;
          this.mindiasresrva = result.data[0].reservasDiasMinimos;
          this.apertura = result.data[0].reservasApertura;
          this.correosReservas = result.data[0].reservasCorreos;

          //COORDENADAS
          this.latitud = result.data[0].coordLatitud;
          this.longitud = result.data[0].coordLongitud;
          this.zoom = result.data[0].coordZoom;

          //FORO
          this.activoForo = result.data[0].foroActivo;
          this.edadforo = result.data[0].foroEdadMinima;
          this.foroPermUsuariosCrearPost = result.data[0].foroPermUsuariosCrearPost;

          //ENCUESTAS
          this.activoEncuestas = result.data[0].encuestasActivas;
          this.edadencuestasmin = result.data[0].encuestasEdadMinima;

          //RRSS
          this. activoFacebook = result.data[0].rrssFacebookActivo;
          this.fblink = result.data[0].rrssFacebookUrl;

          this.activoTwitter = result.data[0].rrssTwitterActivo;
          this.twlink = result.data[0].rrssTwitterUrl;

          this.activoFlicker = result.data[0].rrssFlickerActivo;
          this.fllink = result.data[0].rrssFlickerUrl;

          this.activoInstagram = result.data[0].rrssInstagramActivo;
          this.iglink = result.data[0].rrssInstagramUrl;

          this.activoYoutube = result.data[0].rrssYoutubeActivo;
          this.ytlink = result.data[0].rrssYoutubeUrl;

          this.media = result.data[0].rrssMultimedia;

          this.activoAgentes = result.data[0].agentesActivos;
          this.activoTipos = result.data[0].agentesTiposActivos;
          this.isPrivado = result.data[0].isPrivado;

          
          //Load Iamges  
          if (result.data[0].imagenAvatar) {
            this.imagenAvatar = result.data[0].imagenAvatar;
            this.imagenAnt = result.data[0].imagenAvatar;
            this.avatarPreview = result.data[0].imagenAvatar;
            //this.createImageFromBase64(this.imagenAvatar, 'avatar');
          } else {
            this.imagenAnt = [];
            this.imagenAvatar = '';
          }
          if (result.data[0].imagenCabecera) {
            this.imagenCabecera = result.data[0].imagenCabecera;
            this.imagenAnt = result.data[0].imagenCabecera;
            this.cabeceraPreview = result.data[0].imagenCabecera;
            //this.createImageFromBase64(this.imagenCabecera, 'cabecera');
          } else {
            this.imagenAnt = [];
            this.imagenCabecera = '';
          }

          //Load Perms Publicar Comentar
          this.permUsuariosPublicar = result.data[0].permUsuariosPublicar;
          this.permUsuariosComentar = result.data[0].permUsuariosComentar;

          //Combos
          this.selectedDepartamentoId = result.data[0].idDepartamento;

          //Load TiposAgenteCombo
          this.agenteService
            .getAllTiposAgenteList()
            .pipe(first())
            .subscribe((result: any) => {
              this.tiposAgentesDatos = result;
              // console.log('Tipos de Agentes Loaded : ');
              // console.log(result);
              // console.log('Buscando' + this.selectedAgenteTipoId);
              this.tiposAgentesDatos.forEach((agente) => {
                if (agente.id == this.selectedAgenteTipoId) {
                  console.log('Encontrado!');
                  this.selectedAgenteItem = agente;
                  //this.form.patchValue(['selectedItem']);  //TODO MARI
                }
              });
            });

          //Load DepartamentoCombo
          console.log('Cargando Departamentos');
          this.departamentoEnteService
            .getAllDepartamentosList(this.route.snapshot.params['idorg'])
            .pipe(first())
            .subscribe((result: any) => {
              // console.log('LLegan departamentos');
              // console.log(result);
              this.departamentosDatos = result;
              if (this.selectedDepartamentoId != 0 && this.selectedDepartamentoId!=null) {
                console.log(
                  'EL dep que voy a consultar es: ' +
                    this.selectedDepartamentoId
                );
                this.departamentoEnteService
                  .GetDepById(this.selectedDepartamentoId)
                  .pipe()
                  .subscribe((result: any) => {
                    // console.log('DEPARTAMENTO:');
                    // console.log(result);
                    this.departamento = result.data[0];
                    console.log(
                      'Tipo departamento que me llega seleccinado es: '
                    );
                    // console.log(result.data[0]);
                    // console.log(this.departamento.id);
                    this.selectedDepartamento = new DepartamentoEnte(
                      this.departamento.id,
                      this.departamento.nombre,
                      this.departamento.activo,
                      this.departamento.idOrg
                    );
                    //this.form.patchValue(this.departamento);  
                  });
              }
            });
        });
    } 
  }

  actualizarTipoAgente(e) {
    console.log(e);
    this.selectedAgenteValue = e;
    /* TODO MARI
    this.form.patchValue({
      tipoAgente: this.selectedAgenteValue,
    });
    console.log(this.form.get('tipoAgente').value);
    */
  }

  actualizarDepartamento(e) {
    console.log(e);
    this.selectedDepartamentoId = e;
    /* TODO MARI
    this.form.patchValue({
      departamento: this.selectedDepartamentoId,
    });
    console.log(this.form.get('departamento').value);*/
  }

  asignarValoresModelo()
  {
    //balio guztiak modeloari esleitu
    this.myOrganizacion.id=this.idOrg;
    this.myOrganizacion.nombre = this.nombre;
    this.myOrganizacion.organizacionActiva = this.activoOrganizacion;
    this.myOrganizacion.imagenAvatar = this.imagenAvatar;
    this.myOrganizacion.imagenCabecera = this.imagenCabecera;

    this.myOrganizacion.idPadre = this.idPadre;

    this.myOrganizacion.tipoAgente = this.selectedAgenteValue;

    //idiomas
    this.myOrganizacion.idIdiomas=1;
    this.myOrganizacion.idCamposIdiomas=1;

    //email
    this.myOrganizacion.email=this.email;
    this.myOrganizacion.smtp=this.smtp;
    this.myOrganizacion.emailUsuario=this.emailUsuario;
    this.myOrganizacion.emailPassword=this.contrasena;
    this.myOrganizacion.cambiarPasswordActivo=this.activoGuardarPassword;
    this.myOrganizacion.puerto=this.puerto;
    //INFORMACION
    this.myOrganizacion.infoNotificacionesActivas=this.activoNotificaciones;
    this.myOrganizacion.infoFarmaciasActivas=this.activoFarmacias;
    this.myOrganizacion.infoFaqActivo=this.activoFAQ;
    this.myOrganizacion.infoGestionDocumentalActiva=this.activoGesDoc;
    this.myOrganizacion.infoContactosActivo=this.activoContactos;
    //AGENDA
    this.myOrganizacion.agendaActiva= this.activoAgenda;
    this.myOrganizacion.agendaEdadMinima =this.agendaEdadMin;
    //MENSAJERIA
    this.myOrganizacion.mensajeriaActiva=this.activoMensajeria;
    this.myOrganizacion.mensajeriaIncidenciasDepUnico=this.activoIncidenciasDepUnico;
    if (this.selectedDepartamentoId>0)    this.myOrganizacion.idDepartamento=this.selectedDepartamentoId;
    //else this.myOrganizacion.idDepartamento=1;
    //RESERVAS
    this.myOrganizacion.reservasActivas=this.activoReservas;
    this.myOrganizacion.reservasConfirmacion=this.activoConfirmReserva;
    this.myOrganizacion.reservasUsuarioEnCalendario=this.activoMostrarUsuarioCalendario;
    this.myOrganizacion.reservasDiasMinimos=this.mindiasresrva;
    this.myOrganizacion.reservasApertura=this.apertura; //
    this.myOrganizacion.reservasCorreos=this.correosReservas;

    //coordenadas
    this.myOrganizacion.coordenadasLatitud=this.latitud;
    this.myOrganizacion.coordenadasLongitud=this.longitud;
    this.myOrganizacion.coordenadasZoom=this.zoom;
    
    //foro
    this.myOrganizacion.foroActivo=this.activoForo;
    this.myOrganizacion.foroEdadMinima=this.edadforo;
    this.myOrganizacion.foroPermUsuariosCrearPost=this.foroPermUsuariosCrearPost;

     //encuestas
     this.myOrganizacion.encuestasActivas=this.activoEncuestas;
     this.myOrganizacion.encuestasEdadMinima=this.edadencuestasmin;
 
     //RRSS
     this.myOrganizacion.rrssFacebookActivo=this.activoFacebook;
     this.myOrganizacion.rrssFacebookUrl=this.fblink;
     this.myOrganizacion.rrssTwitterActivo=this.activoTwitter;
     this.myOrganizacion.rrssTwitterUrl=this.twlink;
     this.myOrganizacion.rrssFlickerActivo=this.activoFlicker;
     this.myOrganizacion.rrssFlickerUrl=this.fllink;
     this.myOrganizacion.rrssInstagramActivo=this.activoInstagram;
     this.myOrganizacion.rrssInstagramUrl=this.iglink;
     this.myOrganizacion.rrssYoutubeActivo=this.activoYoutube;
     this.myOrganizacion.rrssYoutubeUrl=this.ytlink;
     this.myOrganizacion.rrssMultimedia=this.media;

       //agentes
    this.myOrganizacion.agentesActivos=this.activoAgentes;
    this.myOrganizacion.agentesTiposActivos=this.activoTipos;
    this.myOrganizacion.isPrivado=this.isPrivado;
    //intercomunicacion usuarios
    this.myOrganizacion.permUsuariosPublicar=this.permUsuariosPublicar;
    this.myOrganizacion.permUsuariosComentar=this.permUsuariosComentar

    
    this.myModel.organizacion = this.myOrganizacion;
  }


  onClickGuardar()
  {
    this.alertService.clear();

    var reEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    this.nombreRequerido = (this.nombre == '' || this.nombre == null);

    this.selectedAgenteRequerido = (this.selectedAgenteValue == 0 || this.selectedAgenteValue == null);

    /*
    this.emailUsuarioRequerido = (this.emailUsuario=='' ||this.emailUsuario==null);
    this.contrasenaRequerido = (this.contrasena=='' || this.contrasena==null);
    this.correosReservasRequerido = (this.correosReservas=='' || this.correosReservas==null);

    if (this.activoFacebook)   this.fblinkRequerido = (this.fblink=='' || this.fblink==null);
    if (this.activoTwitter)  this.twlinkRequerido = (this.twlink=='' || this.twlink==null);
    if (this.activoFlicker) this.fllinkRequerido = (this.fllink=='' || this.fllink==null);
    if (this.activoInstagram) this.iglinkRequerido = (this.iglink=='' || this.iglink==null);
    if (this.activoYoutube) this.ytlinkRequerido = (this.ytlink=='' || this.ytlink==null);
    this.mediaRequerido = (this.media=='' || this.media==null);*/

    if (this.email.length>0)  this.emailValidado = !(reEmail.test(this.email));
    if (this.emailUsuario.length>0)   this.emailUsuarioValidado = !(reEmail.test(this.emailUsuario));
    this.numIdiomasValidado = (this.selectedLangs.length==0);

    this.correcto=!this.nombreRequerido && !this.selectedAgenteRequerido
                  //&& !this.emailValidado
                   //&& !this.emailUsuarioRequerido 
                  // && !this.emailUsuarioValidado 
                   && !this.numIdiomasValidado;
                  // !this.contrasenaRequerido && !this.correosReservasRequerido &&
                  // !this.fblinkRequerido && !this.twlinkRequerido && !this.fllinkRequerido && 
                  // !this.iglinkRequerido && !this.ytlinkRequerido && !this.mediaRequerido  ;

    if (this.correcto)
    {
      this.loading = true;
      this.editarAgente();
    }
    else     this.alertService.error(   this.translateService.instant('botones.faltanDatos') );

    if (this.numIdiomasValidado) this.alertService.warn(   this.translateService.instant('botones.idiomaRequerido') );
    if (this.selectedAgenteRequerido) this.alertService.warn(  this.translateService.instant('agentes.validartipoagente'));
  }

  editarAgente()
  {
    if (!this.isNotInsA) {
      this.createImageFromBase64(this.imagenAvatar, 'avatar');
      this.logoDocumentoAgregar = this.imagenAvatar;
    }
    if (!this.isNotInsC) {
      this.createImageFromBase64(this.imagenCabecera, 'cabecera');
      this.cabeceraDocumentoAgregar = this.imagenCabecera;
    }

    this.rellenarTraducciones();
    this.asignarValoresModelo(); //myModel eta myModel.organizacion modeloei balioak asignatu
    
    this.myModel.listaTraducciones = this.listaTraduccionesEnviar;

    //EDITAR agente
    this.agenteService.editarAgente(this.myModel).subscribe((result) => {
            
      if (!result.error ) 
      {
        this.alertService.success(
          this.translateService.instant('botones.editadocorrectamente'),
          { keepAfterRouteChange: true }
        );
        //this.router.navigate(['../../'], { relativeTo: this.route });
        this.router.navigate(['agentes']);
      } else {
        this.alertService.error(
          this.translateService.instant('enlaces.erroreditar')
        );
      }
      this.loading = false;
    });

  }

  private rellenarTraducciones()
  {
    let orgTrad = new OrganizacionTraduccion();
    for (let elem in this.selectedLangs) {
      console.log(this.selectedLangs[elem].codigo);
      orgTrad = new OrganizacionTraduccion();
      orgTrad.filled = false;
      orgTrad.idIdioma = this.selectedLangs[elem].codigo;
      orgTrad.nombreIdioma = this.selectedLangs[elem].nombre;
      orgTrad.descripcion = this.camposValoresDescripcion[this.selectedLangs[elem].codigo];
      orgTrad.colaboradores = this.camposValoresColaboradores[this.selectedLangs[elem].codigo];
      //orgTrad.politicaDePrivacidad = this.camposValoresPrivacidad[this.selectedLangs[elem].codigo];
      //orgTrad.faq = this.camposValoresFAQ[this.selectedLangs[elem].codigo]
      orgTrad.id='0';
      this.listaTraduccionesEnviar.push(
        orgTrad
      );
    }
  }

  

  public valueDepartamentoChange(value: any): void {
    console.log('El tipo Departamento cambia a : ' + value.id);
    this.selectedDepartamento = value;
    this.selectedDepartamentoId = value.id;
  }

  public selectedLangsChange(isTrue, lang) {
    console.log('SelectedLangs');
    console.log(this.selectedLangs);
    if (isTrue) {
      this.selectedLangs.push(lang);
      //Añadimos un div IdiomasCampos más con los campos
      this.containersCampos.push(lang.codigo);
    } else {
      const index = this.selectedLangs.indexOf(lang);
      this.selectedLangs.splice(index, 1);

      const index2 = this.containersCampos.indexOf(lang.codigo);
      this.containersCampos.splice(index2, 1);
      //Delete that Lang JsonForm
      //TODO this.jsonCamposIdomas.push()
    }
    this.selectedLangs.sort((a, b) => a - b);
    this.idiomasGuardar = true;
  }

  //Procesado de Imagenes
  createImageFromBase64(image, img) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    console.log(objectURL);
    if (img == 'avatar')
      this.avatarPreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    else
      this.cabeceraPreview = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
  onClickEliminarImagen(content) {
    this.modalReference = this.modalService.open(content, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }
  public selectEventHandler(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {
      if (!file.validationErrors) {
        this.formImErr = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid,
          };

          that.avatarPreview = image.src.toString();
          that.imagenAvatar = image.src.toString().split(',', 2)[1];
          that.avatarBorrado = false;
          that.isNotInsA = false;
        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErr = true;
      }
    });
  }
  public selectEventHandlerCabecera(e: SelectEvent): void {
    const that = this;

    e.files.forEach((file) => {
      if (!file.validationErrors) {
        this.formImErr = false;
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid,
          };

          that.cabeceraPreview = image.src.toString();
          that.imagenCabecera = image.src.toString().split(',', 2)[1];
          that.cabeceraBorrada = false;
          that.isNotInsC = false;
        };
        reader.readAsDataURL(file.rawFile);
      } else {
        this.formImErr = true;
      }
    });
  }

  public removeEventHandler(e: RemoveEvent): void {
    this.formImErr = false;
    this.imagenAvatar = '';
    this.avatarPreview = null;
  }
  public removeEventHandlerCabecera(e: RemoveEvent): void {
    this.formImErr = false;
    this.imagenCabecera = '';
    this.cabeceraPreview = null;
  }
  eliminarImagen(opt) {
    if (opt == 'avatar') {
      this.avatarBorrado = true;
      this.imagenAvatar = null;
      this.avatarPreview = null;
    } else {
      this.cabeceraBorrada = true;
      this.imagenCabecera = null;
      this.cabeceraPreview = null;
    }
    this.modalReference.close();
  }
  
  valueIdiomaCamposChange(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    // console.log(value.codigo);
    // console.log(this.camposValoresDescripcion);
    // console.log(this.camposValoresColaboradores);
    // console.log(this.camposValoresFAQ);
    this.camposSelectedLang = value.codigo;
  }

  public loadAvailableAgents() {
    this.agenteService
      .getAllWithoutFather(this.idOrg)
      .pipe(first())
      .subscribe((result: any) => {
        // console.log('LLEGAN AGENTES HUERFANOS O MIOS');
        // console.log(result);
        this.agentesSinAsignarLista = result.data;
        this.agentesSinAsignarLista.forEach((val) =>
          this.listaTMP.push(Object.assign({}, val))
        );
        console.log(this.agentesSinAsignarLista.length);
      });
  }

  public loadAdminsFromEnte() {
    this.agenteService
      .getAdminsFromEnte(this.idOrg)
      .pipe(first())
      .subscribe((result: any) => {
        // console.log('LLEGAN Admins');
        // console.log(result);

        this.usuariosAdminsLista = result.data;
        this.usuariosAdminsLista.forEach((val) =>
          this.listaTMPAdmins.push(Object.assign({}, val))
        );
        //console.log( this.agentesSinAsignarLista.length);
      });
  }

  public openGestionSubs(): void {
    this.show = !this.show;
    if (this.agentesSinAsignarLista.length == 0) this.loadAvailableAgents();
  }
  public openGestionAdmins(): void {
    this.showAdmins = !this.showAdmins;
    if (this.usuariosAdminsLista.length == 0) this.loadAdminsFromEnte();
  }

  onClickGestionDeps() {
    this.router.navigate(['departamentos-ente', { idorg: this.idOrg }]);
  }
  onClickGestionContactos() {
    this.router.navigate(['contactos-ente', { idorg: this.idOrg }]);
  }

  cerrarPopUp() {
    this.show = false;
  }
  cerrarPopUpAdmins() {
    this.showAdmins = false;
  }
  onClickAgregarMiembros(){
    this.showMiembros=true;
    this.agenteService.getMiembrosAgentePrivado(this.idOrg).subscribe((result)=>{
      console.log('Miembros lista');
      this.listaUsuariosMiembros = result.data;
    });
  }
  cerrarPopUpAgregarMiembros(){
    this.showMiembros=false;
    this.listaUsuariosMiembros=[];
  }
  btnActualizarMiembros(){
    //if(this.listaUsuariosMiembros.length==0)return;
    console.log('Actualizando Miembros');
    let model:RequestMiembrosPrivado= new RequestMiembrosPrivado();
    model.listaMiembros=this.listaUsuariosMiembros;
    model.idOrg=this.idOrg;
    this.agenteService.updateMiembrosPrivados(model).subscribe((result)=>{
      console.log(result);
      if(!result.error){
        this.alertService.success(
          this.translateService.instant('agentes.miembrosactualizados'),
          { keepAfterRouteChange: true }
        );
      }
      else{
        if(!result.error){
          this.alertService.error(
            this.translateService.instant('agentes.errormiembros'),
            { keepAfterRouteChange: true }
          );
      }
    }
    });;
  }
  emitterUsuarios(lista){
    console.log('Usuarios añadidos: ');
    console.log(lista);
    this.listaUsuariosMiembros = [...this.listaUsuariosMiembros,...lista];
    this.listaUsuariosMiembros = [...new Set(this.listaUsuariosMiembros)];
  }
  borrarMiembro(miembro){
    this.listaUsuariosMiembros = this.listaUsuariosMiembros.filter((elem)=>elem!=miembro);
  }
  
  btnActualizarSubAgentes() {
    console.log('Creando lista');
    this.agentesSinAsignarLista.forEach((value, index) => {
      console.log('LISTA = ' + this.agentesSinAsignarLista[index].isMine);
      console.log('LISTATMP= ' + this.listaTMP[index].isMine);
      if (
        this.agentesSinAsignarLista[index].isMine != this.listaTMP[index].isMine
      ) {
        console.log('ACTUALIZAQ!!');
        this.updateSubsNeeded = true;
        this.agentesSinAsignarLista[index].actualizar = true;
      }
    });
    // this.agentesSinAsignarLista.forEach(element => {
    //   this.listaTMP.forEach(elementTMP => {
    //     console.log('[Elelment= '+element.isMine+' / '+elementTMP.isMine+']');
    //     if(element.isMine!=elementTMP.isMine){
    //       this.updateSubsNeeded= true;
    //       element.actualizar=true;
    //     }
    //   });
    // });
    if (this.updateSubsNeeded) {
      console.log('Enviando lista');
      console.log(this.agentesSinAsignarLista);
      this.agenteService
        .updateSubAgentes2(this.agentesSinAsignarLista, this.idOrg)
        .pipe(first())
        .subscribe((result) => {
          if (!result.error) {
            this.show = false;
            this.alertService.success(
              this.translateService.instant('botones.editadocorrectamente'),
              { keepAfterRouteChange: true }
            );
          } else {
            this.alertService.error(
              this.translateService.instant('enlaces.erroreditar')
            );
            this.loading = false;
            this.show = false;
          }
        });
    } else {
      this.show = false;
    }
  }

  btnActualizarAdmins() {
    console.log('Creando listaAdmins de cambios...');
    this.usuariosAdminsLista.forEach((value, index) => {
      console.log('LISTA = ' + this.usuariosAdminsLista[index].isMine);
      console.log('LISTATMP= ' + this.listaTMPAdmins[index].isMine);
      if (
        this.usuariosAdminsLista[index].isMine !=
        this.listaTMPAdmins[index].isMine
      ) {
        console.log('ACTUALIZAQ!!');
        this.updateAdminsNeeded = true;
        this.usuariosAdminsLista[index].actualizar = true;
      }
    });

    if (this.updateAdminsNeeded) {
      console.log('Enviando lista Admins');
      console.log(this.usuariosAdminsLista);
      this.agenteService
        .updateAdmins(this.usuariosAdminsLista, this.idOrg)
        .pipe(first())
        .subscribe((result) => {
          if (!result.error) {
            this.showAdmins = false;
            this.alertService.success(
              this.translateService.instant('botones.editadocorrectamente'),
              { keepAfterRouteChange: true }
            );
          } else {
            this.alertService.error(
              this.translateService.instant('enlaces.erroreditar')
            );
            this.loading = false;
            this.showAdmins = false;
          }
        });
    } else {
      this.showAdmins = false;
    }
  }
}

class AgentesModificados {
  idOrg: string;
  idFather: string;
}

class RequestMiembrosPrivado {
  idOrg: string;
  listaMiembros:Usuario[];
}
