<div class="row">
    <div class="col-xl-6 col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>{{ "rss.editar" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <!--Lista idiomas-->
            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>
  
            <div class="caja">
              <kendo-dropdownlist
                #dropdownI
                [data]="listaIdiomas"
                textField="nombre"
                valueField="codigo"
                [(ngModel)]="selectedIdioma"
                (valueChange)="loadRss($event, dropdownI)"
              ></kendo-dropdownlist>
            </div>
          </div>
          <div class="form-group">
            <label>{{ "rss.nombre" | translate }}</label>
            <div class="caja">
              <!--Nombre Rss-->
              <kendo-textbox
                placeholder="{{ 'rss.nombre' | translate }}"
                [clearButton]="true"
                [(ngModel)]="nombreRss"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>

        <!--Descripcion-->
        <div class="form-group">
            <kendo-label
              text="{{ 'rss.descripcion' | translate }}">
              <textarea
                kendoTextArea
                style="resize: none"
                rows="5"
                [(ngModel)]="descripcionRss"
                placeholder="{{ 'recintos.ph_descripcion' | translate }}"
              >
              </textarea>
            </kendo-label>
          </div>

          <!--Url Rss-->
          <div class="form-group">
            <label>{{ "rss.url" | translate }}</label>
            <div class="caja">
              <kendo-textbox
                placeholder="{{ 'rss.ph_url' | translate }}"
                [clearButton]="true"
                [(ngModel)]="urlRss"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
          <div class="form-group">
            <!--Boton Publicar-->
            <button
              type="button"
              class="btn btn-success btn-sm mr-1"
              (click)="btnUpdateRss()"
            >
              {{ "botones.actualizar" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-danger btn-sm mr-1"
              (click)="btnCancelar()"
            >
              {{ "botones.cancelar" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
  