import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { not } from '@angular/compiler/src/output/output_ast';
import { Evento,EventoModel, Recurrencia } from '@app/_models/evento';
import { Categoria } from '@app/_models/categoria';
import { TranslateService } from '@ngx-translate/core';


const baseUrl = `${environment.apiUrl}/agenda`;


@Injectable({
  providedIn: 'root'
})
export class EventosService {

  constructor(private http: HttpClient,
    private translateService: TranslateService) { }

  public GetById(id: Number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }
  public getEntesAdministrados():Observable<any> {
    return this.http.get(environment.apiUrl+"/organizacion/getentesuser/" + "1");//idUser no necesario
  }

  public getPublicacionesFromEnte(idOrg:string):Observable<any> {
    return this.http.get(baseUrl+"/ente/"+idOrg );
  }

  public getEventoWithLang(idEvento:number,lang:number):Observable<any> {
    return this.http.get(baseUrl+"/get/"+idEvento+"/"+lang);
  }

  public loadCategorias(ididioma:string,idOrg:string):Observable<any> {
    return this.http.get(baseUrl+"/categorias/"+idOrg +"/"+ididioma);
  }

  public crearEvento(eventoModel:EventoModel):Observable<any> {

    eventoModel.evento.fechaInciio= new Date(this.dateToYYYYMMDDtHHmmSSz(eventoModel.evento.fechaInciio));
    eventoModel.evento.fechaFin= new Date(this.dateToYYYYMMDDtHHmmSSz(eventoModel.evento.fechaFin));

    console.log('Creando Evento...');

    return this.http.post(baseUrl+"/crearw/",JSON.stringify(eventoModel),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
}

public actualizarEvento(myEvento:Evento):Observable<any> {

  myEvento.fechaInciio= new Date(this.dateToYYYYMMDDtHHmmSSz(myEvento.fechaInciio));
  myEvento.fechaFin= new Date(this.dateToYYYYMMDDtHHmmSSz(myEvento.fechaFin));
  console.log('Actualizando Evento...');
  console.log(myEvento);
  return this.http.post(baseUrl+"/updateweb/",JSON.stringify(myEvento),
  {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      charset: 'utf-8',
    }),
    withCredentials: true,
  }
);
}
  public addTraduccionesEvento(listaTraducciones,idEvento):Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(baseUrl+"/addtradsw/"+idEvento.toString(),JSON.stringify(listaTraducciones),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
  }

  public getCategoriaById(idCategoria:number,lang:number):Observable<any> {
    return this.http.get(baseUrl+"/categorias/get/"+idCategoria +"/"+lang);
  }

  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  public crearCategoria(listaCategoriaTraducciones,idOrg:string):Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(baseUrl+"/categorias/crear/"+idOrg.toString(),JSON.stringify(listaCategoriaTraducciones),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
  }

  public actualizarCategoria(myCategoria:Categoria):Observable<any> {
    console.log('Enviando Traducciones...');

    return this.http.post(baseUrl+"/categorias/update",JSON.stringify(myCategoria),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
  }

  public actualizarPortada(idEvento: Number): Observable<any> {
    console.log('Enviando Portada...');
    
    return this.http.post(
      baseUrl + '/updateportada/',
      JSON.stringify(idEvento),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteCategorias(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarcategorias', formData, {
      withCredentials: true,
    });
  }

  public getInscripciones(idEvento:number):Observable<any> {
    return this.http.get(baseUrl+"/getinscripciones/"+idEvento);
  }
  public cancelarInscripcion(idInscripcion:number):Observable<any> {
    return this.http.get(baseUrl+"/cancelarinscripcion/"+idInscripcion);
  }
  

  getRecurrencias():Recurrencia[]{
    let listaRecurrencias: Recurrencia[] = [];
    let r1: Recurrencia = new Recurrencia();
    r1.id = 1;
    r1.nombre = this.translateService.instant('eventos.recurrenciaAnual');
    let r2: Recurrencia = new Recurrencia();
    r2.id = 2;
    r2.nombre = this.translateService.instant('eventos.recurrenciaMensual');
    let r3: Recurrencia = new Recurrencia();
    r3.id = 3;
    r3.nombre = this.translateService.instant('eventos.recurrenciaSemanal');
    let r4: Recurrencia = new Recurrencia();
    r4.id = 4;
    r4.nombre = this.translateService.instant('eventos.recurrenciaDiaria');
    listaRecurrencias.push(r1);
    listaRecurrencias.push(r2);
    listaRecurrencias.push(r3);
    listaRecurrencias.push(r4);
    console.log(listaRecurrencias);
    return listaRecurrencias;
  }
  
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
    }

    addZero(num){
      if(num<10) return '0'+num;
      else return num;
    }
}



