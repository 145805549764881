import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '@app/_services/menu.service';
import { IdiomasService } from '@app/_services/idiomas.service';

import {
  AlertService,
} from '@app/_services';
import  {Equipo, EquipoTrad, Jugador, Partido, Temporada} from '@app/_models/equipo'
import { EquiposService } from '@app/_services/equipos.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Usuario } from '@app/_models';

@Component({
  selector: 'app-temporada-editar',
  templateUrl: './temporada-editar.component.html'
})
export class TemporadaEditarComponent implements OnInit {

  idOrg:string;
  idTemporada:number;
  idEquipo:number;
  nombreTemporada:string='';
  private translate: TranslateService;
  lang;
  public seleccionados: number[] = [];
  listaPartidosJugados:Partido[]=[];

  constructor(
    private equiposService: EquiposService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('equipos.cabecera');
  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('equipos.edit_equipo');
    this.idEquipo =this.route.snapshot.params['idequipo'];
    this.idTemporada =this.route.snapshot.params['idtemporada'];
    this.nombreTemporada = this.route.snapshot.params['nombre'];
    this.lang = this.translateService.getDefaultLang();
  }

  onClickNuevoPartido(){
    this.router.navigate(['partido-crear',{ idequipo:this.idEquipo,idtemporada:this.idTemporada }]);
  }

  cellClick(e){
  }

  onClickEditar(){
  }

  onClickEliminar(){
    this.equiposService.deletePartidos(this.seleccionados);
  }

  updateNombreTemp(){
  }

  goBack(){
    this.router.navigate(['equipo-temporada',{ idorg:this.idOrg,idequipo:this.idEquipo }]);
  }


}
