import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '@app/_services/menu.service';
import {AlertService,} from '@app/_services';
import { EquiposService } from '@app/_services/equipos.service';
import { Equipo, Partido, Temporada } from '@app/_models/equipo';


@Component({
  selector: 'app-partidos-crear',
  templateUrl: './partidos-crear.component.html'
})
export class PartidosCrearComponent implements OnInit {

  idEquipo:number;
  idTemporada:number;
  translate;
  myPartido:Partido=new Partido();
  listaEquiposRivales:Equipo[]=[];
  selectedEquipoRival:Equipo;

  constructor(
    private equiposService: EquiposService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.idEquipo =this.route.snapshot.params['idequipo'];
    this.idTemporada =this.route.snapshot.params['idtemporada'];
  }

  ngOnInit(): void {
    console.log('TODO: Iniciando Carga de datos custom para partido');
    //Load Equipos Rivales
    //
    this.equiposService.getJugadoresLike(this.idEquipo,'Naka').subscribe((res)=>{
      console.log(res.data);
    });
  }

}
