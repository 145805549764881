import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import { ForoTopic, ForoTopicModel, ForoTopicTrad } from '@app/_models/foro';
import { Idioma } from '@app/_models/idioma';
import { AlertService, ForoService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-foro-topics-crear',
  templateUrl: './foro-topics-crear.component.html'
})
export class ForoTopicsCrearComponent implements OnInit {

  idOrg:string;
    //Listas
    listaIdiomas: Idioma[] = [];
    listaIdiomasTotales: Idioma[] = [];
    listaTraducciones: Idioma[] = [];
    listaTraduccionesEnviar: ForoTopicTrad[] = [];
    //Data
    imagenB64: string = '';
    selectedIdioma: Idioma;
    isProgramada: boolean;
    hasVideo:boolean=false;
    urlVideo:string='';
    isYoutubeVideo:boolean=false;
    isMembersOnly: boolean;
    myTopic: ForoTopic = new ForoTopic();
    filePath: string;
    public fecha: Date = new Date();
    public format = 'dd/MM/yyyy HH:mm';
    
    lang;
    private translate: TranslateService;
    idNoticia:number;

    model:ForoTopicModel= new ForoTopicModel();
    nombreTopic:string='';
    descripcionTopic:string='';
    topicActivo:boolean=true;

  constructor(
    public foroService:ForoService,public organizacionService:OrganizacionesService,
    public idiomaService:IdiomasService,
    public publicacionesService:PublicacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService) {
    this.idOrg = this.route.snapshot.params['idorg'];
   }

  ngOnInit(): void {
        //Load Langs
        this.idiomaService.GetAll().subscribe((result) => {
          this.listaIdiomasTotales = result.data;
          console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
          this.listaIdiomasTotales.forEach((x) => {
            const trad = new ForoTopicTrad();
            trad.filled = false;
            trad.idIdioma = x.codigo;
            trad.nombreIdioma = x.nombre;
            this.listaTraduccionesEnviar.push(trad);
          });
        });
        this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
          this.listaIdiomas = result.data;
          if (this.listaIdiomas.length > 0)
            this.selectedIdioma = this.listaIdiomas[0];
        });
  }

  btnCrearTopic(){
    console.log('Creando Topic');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }
    if (this.filePath == null) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validaciondatos')
      );
      return;
    }
    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: ForoTopicTrad = new ForoTopicTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.topicName = seleccionado.topicName;
        trad.topicDetails = seleccionado.topicDetails;
      }
    });
    console.log(this.listaTraduccionesEnviar);
    //Subir Foto  y EnviarNoticia
    this.myTopic.idOrg = this.idOrg;
    this.myTopic.activo = this.topicActivo;
    this.myTopic.topicName = this.nombreTopic;
    this.myTopic.topicDetails = this.descripcionTopic;
    this.myTopic.avatar = this.filePath.split(',')[1]; //Borramos cabecera data: base64
    this.model.listaTraducciones = this.listaTraduccionesEnviar;
    this.model.topic = this.myTopic;
    console.log('Evinado recinto');
    this.foroService.crearTopic(this.model).subscribe((result) => {
      if (!result.error) {
        this.alertService.success(
          this.translateService.instant('recintos.success')
        );
        this.router.navigate(['foro-topics',{ idorg:this.idOrg }]);
      } else {
        this.alertService.error(
          this.translateService.instant('recintos.error')
        );
        return;
      }
    });
  }

  btnCancelar(){
    this.router.navigate(['foro-topics',{ idorg:this.idOrg }]);
  }

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombreTopic.length <= 0 ||
      this.descripcionTopic.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.topicName = this.nombreTopic;
          x.topicDetails = this.descripcionTopic;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }


}
