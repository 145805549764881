import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BloqueInput, CategoriaBloque, PlantillaEstadisticas, TipoComputable } from '@app/_models/equipo';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';


const baseUrl = `${environment.apiUrl}/equipoplantilla`;

@Injectable({
  providedIn: 'root'
})
export class EquiposPlantillaService {


  
  constructor(private http: HttpClient) { }


  public getPlantillasFromEnte(idorg:string): Observable<any> {
    return this.http.get(baseUrl+ '/getallplantillas/'+idorg);
  }

  public getPlantillaCompleta(idPlantilla:number): Observable<any> {
    return this.http.get(baseUrl+ '/getplantilla/'+idPlantilla);
  }

  getTiposComputables():any[]{
    
    let lista:TipoComputable[]=[];
    let tipoTMP1 :TipoComputable= new TipoComputable();
    tipoTMP1.nombre='Partidos jugados';
    tipoTMP1.id=1;
    lista.push(tipoTMP1);
    let tipoTMP2 :TipoComputable= new TipoComputable();
    tipoTMP2.nombre='Goles Jugador';
    tipoTMP2.id=2;
    lista.push(tipoTMP2);
    let tipoTMP3 :TipoComputable= new TipoComputable();
    tipoTMP3.nombre='Partidos Perdidos';
    tipoTMP3.id=3;
    lista.push(tipoTMP3);
    return lista;
  }


  public crearPlantilla(myPlantilla:PlantillaEstadisticas):Observable<any>{

    return this.http.post(baseUrl + '/create/', JSON.stringify(myPlantilla), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public updatePlantilla(myPlantilla:PlantillaEstadisticas):Observable<any>{

    return this.http.post(baseUrl + '/update/', JSON.stringify(myPlantilla), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public setPlantillaEnte(idPlantilla:number,idOrg:string): Observable<any> {
    return this.http.get(baseUrl+ '/setplantilla/'+idPlantilla+'/'+ idOrg);
  }

  public updateOrdenCategorias(myCategorias:CategoriaBloque[]):Observable<any>{

    return this.http.post(baseUrl + '/updateordencategorias/', JSON.stringify(myCategorias), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }
  public updateInput(myBloque:BloqueInput):Observable<any>{

    return this.http.post(baseUrl + '/updateinput/', JSON.stringify(myBloque), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public updateCategoria(myCategoria:CategoriaBloque):Observable<any>{
    return this.http.post(baseUrl + '/updatecat/', JSON.stringify(myCategoria), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }
  
}
