<div class="row">
    <div class="col-7">
        <div class="card">
            <div class="card-header">
                <h3>{{'foro.posts' | translate}}</h3>
            </div>
            <div class="card-body">
                <kendo-grid
                [kendoGridBinding]="listaPosts"
                kendoGridSelectBy="id"
                [sortable]="true"
                [navigable]="true"
                [filterable]="true"
                (cellClick)="cellClick($event)"
                [(selectedKeys)]="seleccionados"
                (selectedKeysChange)="selectionChange($event)"
                [pageSize]="5"
                [pageable]="true"
              >
                <ng-template kendoGridToolbarTemplate position="top">
                  <button
                    kendoButton
                    (click)="onClickEditar()"
                    class="btn mr-1 btn-success btn-sm mr-1"
                  >
                    {{ "botones.editar" | translate }}
                  </button>
                  <button
                    kendoButton
                    (click)="onClickNuevo()"
                    class="btn mr-1 btn-primary btn-sm mr-1"
                  >
                    {{ "botones.nuevo" | translate }}
                  </button>
                  <button
                    kendoButton
                    (click)="onClickEliminar(content)"
                    class="btn mr-1 btn-danger btn-sm mr-1"
                    [disabled]="isDeleting"
                  >
                    {{ "botones.eliminar" | translate }}
                  </button>
                </ng-template>
                <kendo-grid-checkbox-column
                  width="2%"
                  showSelectAll="true"
                ></kendo-grid-checkbox-column>
                <kendo-grid-column
                field="updated"
                title="{{ 'publicaciones.grid_lbl_fecha' | translate }}"
                width="10%"
              >
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  <kendo-grid-date-filter-cell
                    [showOperators]="false"
                    [column]="column"
                    [filter]="filter"
                  >
                  </kendo-grid-date-filter-cell>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.updated | date: "dd/MM/yyyy hh:mm:ss" }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
              width="7%"
              [style]="{ 'text-align': 'left' }"
              field="nombreUsuario"
              title="{{ 'foro.nombreusuario' | translate }}"
            >
            </kendo-grid-column>
                <kendo-grid-column
                  width="15%"
                  [style]="{ 'text-align': 'left' }"
                  field="title"
                  title="{{ 'foro.titulopost' | translate }}"
                >
                </kendo-grid-column>
                <kendo-grid-column
                  width="15%"
                  [style]="{ 'text-align': 'left' }"
                  field="post"
                  title="{{ 'foro.respuesta' | translate }}"
                >
                </kendo-grid-column>


        
              </kendo-grid>
            </div>
        </div>
    </div>
    <div class="col-5">
        <div class="card">
            <div class="card-header">
                <h3>{{'foro.mensajes' | translate}}</h3>
            </div>
            <div class="card-body">
                <kendo-grid
                [kendoGridBinding]="listaMensajes"
                kendoGridSelectBy="id"
                [sortable]="true"
                [navigable]="true"
                [filterable]="true"
                (cellClick)="cellClick($event)"
                [(selectedKeys)]="seleccionadosMensajes"
                [pageSize]="15"
                [pageable]="true"
              >
              <ng-template kendoGridNoRecordsTemplate>
                {{ "foro.sinmensajes" | translate }}

             </ng-template>
                <!-- <kendo-grid-checkbox-column
                  width="1%"
                  showSelectAll="true"
                ></kendo-grid-checkbox-column> -->
                <kendo-grid-column
                field="updated"
                title="{{ 'publicaciones.grid_lbl_fecha' | translate }}"
                width="15%"
              >
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  <kendo-grid-date-filter-cell
                    [showOperators]="false"
                    [column]="column"
                    [filter]="filter"
                  >
                  </kendo-grid-date-filter-cell>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.updated | date: "dd/MM/yyyy hh:mm:ss" }}
                </ng-template>
              </kendo-grid-column>
                <kendo-grid-column
                  width="10%"
                  [style]="{ 'text-align': 'left' }"
                  field="nombreUsuario"
                  title="{{ 'foro.nombreusuario' | translate }}"
                >
                </kendo-grid-column>
                <kendo-grid-column
                  width="15%"
                  [style]="{ 'text-align': 'left' }"
                  field="respuesta"
                  title="{{ 'foro.respuesta' | translate }}"
                >
                </kendo-grid-column>
                <kendo-grid-column
                width="20%"
                [style]="{ 'text-align': 'left' }"
                title="{{ 'botones.eliminar' | translate }}"
                >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button
                    kendoButton
                    (click)="deleteMessage(dataItem)"
                    class="btn mr-1 btn-danger btn-sm mr-1"
                  >
                    {{ "botones.eliminar" | translate }}
                  </button>
                </ng-template>
                </kendo-grid-column>
        
              </kendo-grid>
            </div>
        </div>
    </div>

    <button
    type="button"
    class="btn btn-info btn-sm mr-1"
    (click)="btnCancelar()"
  >
    {{ "botones.cancelar" | translate }}
  </button>
</div>


<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ "organizaciones.eliminar" | translate }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        {{ "botones.no" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="eliminarRegistro(contentloading)"
      >
        {{ "botones.si" | translate }}
      </button>
    </div>
  </ng-template>
  
  <ng-template #contentloading let-modal>
    <div class="modal-body">
      <p>{{ "organizaciones.eliminando" | translate }}</p>
      <div class="spinner-border" role="status">
        <span class="sr-only"
          >{{ "organizaciones.eliminando" | translate }}...</span
        >
      </div>
    </div>
  </ng-template>