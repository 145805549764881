export class Rss{
    id:number;
    idOrg:string;
    nombre:string;
    descripcion:string;
    urlRss:string;
    listaTraducciones:RssTrad[]=[];
    idIdioma:number;
}
export class RssTrad{
    id:number;
    idIdioma:number;
    filled:boolean;
    nombre:string;
    descripcion:string;
    urlRss:string;
}