export class ForoTopicModel{
    topic:ForoTopic;
    listaTraducciones:ForoTopicTrad[];
}
export class ForoTopic{
    id:number;
    avatar:string;
    idOrg:string;
    topicName:string;
    topicDetails:string;
    activo:boolean;
    idIdioma:number;
    updateFoto:boolean;
}
export class ForoTopicTrad{
    id:number;
    idTopic:number;
    idIdioma:number;
    topicName:string;
    topicDetails:string;
    filled:boolean;
    nombreIdioma:string;
}

export class ForoPost{
    id:number;
    idTopic:number;
    title:string;
    post:string;
    userId:number;
    created:Date;
    updated:Date;
    picture:string;
    views:number;
    userAvatar:string;
    nombreUsuario:string;
    idIdioma:number;
    listaTraducciones:ForoPostTrad[]=[];
}
export class ForoPostTrad{
    id:number;
    idTopic:number;
    idIdioma:number;
    title:string;
    post:string;
    filled:boolean;
    nombreIdioma:string;
}
