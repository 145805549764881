import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { TipoAgente } from '@app/_models/tipoagente';
import {  Organizacion,  OrganizacionModel,  Recurrencia} from '@app/_models/organizacion';

const baseUrl = `${environment.apiUrl}/agente`;
@Injectable({
  providedIn: 'root'
})
export class AgentesService {

  constructor(private http: HttpClient) { }


    public GetById(id: String): Observable<any> {
      return this.http.get(baseUrl + '/' + id);
    }

    public GetAll(): Observable<any> {
      return this.http.get(baseUrl);
    }
    
    public getAllFromFather(id: String): Observable<any> {
      return this.http.get(baseUrl + '/all/' + id);
    }
    public getAllWithoutFather(id:string): Observable<any> {
      return this.http.get(baseUrl + '/allwithoutparent/'+id);
    }

    public crearAgente(organizacionModel:OrganizacionModel):Observable<any> {

      console.log('Creando Agente...');
  
      return this.http.post(baseUrl+"/crearagente/",JSON.stringify(organizacionModel),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
    }
  
    public editarAgente(organizacionModel:OrganizacionModel):Observable<any> {
  
      console.log('Editando Agente...');
      return this.http.post(baseUrl+"/editaragente/",JSON.stringify(organizacionModel),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
    }

    insert(data) {
      console.log('DATOS UPDATE AGENTE');
      console.log(JSON.stringify(data));
      return this.http.post<any>(
        baseUrl + '/crear',
        {
          nombre: data.nombreOrg,
          imagenAvatar: data.logo,
          imagenCabecera: data.cabecera,
          organizacionActiva: data.activoOrganizacion,
          email: data.email,
          smtp: data.smtp,
          puerto: data.puerto!=null?data.puerto:0,
          emailUsuario: data.usuario,
          emailPassword: data.contrasena,
          cambiarPasswordActivo: data.activoGuardarPassword,
          infoNotificacionesActivas: data.activoNotificaciones,
          infoFarmaciasActivas: data.activoFarmacias,
          infoFaqActivo: data.activoFAQ,
          infoGestionDocumentalActiva: data.activoGesDoc,
          infoContactosActivo: data.activoContactos,
          agendaActiva: data.activoAgenda,
          agendaEdadMinima: data.agendaEdadMin!=null?data.agendaEdadMin:0,
          mensajeriaActiva: data.activoMensajeria,
          mensajeriaIncidenciasDepUnico: data.activoIncidenciasDepUnico,
          idDepartamento: data.departamento?data.departamento:0,
          reservasActivas: data.activoReservas,
          reservasConfirmacion: data.activoConfirmReserva,
          reservasUsuarioEnCalendario: data.activoMostrarUsuarioCalendario,
          reservasDiasMinimos: data.mindiasresrva!=null?data.mindiasresrva:0,
          reservasApertura: data.apertura,
          reservasCorreos: data.correosReservas,
          coordenadasLatitud: data.latitud,
          coordenadasLongitud: data.longitud,
          coordenadasZoom: data.zoom!=null?data.zoom:0,
          agentesActivos: data.activoAgentes,
          agentesTiposActivos: data.activoTipos,
          permUsuariosPublicar: data.permUsuariosPublicar,
          permUsuariosComentar: data.permUsuariosComentar,
          foroActivo: data.activoForo,
          foroEdadMinima: data.edadforo!=null?data.edadforo:0,
          encuestasActivas: data.activoEncuestas,
          encuestasEdadMinima: data.edadencuestasmin!=null?data.edadencuestasmin:0,
          rrssFacebookActivo: data.activoFacebook,
          rrssFacebookUrl: data.fblink,
          rrssTwitterActivo: data.activoTwitter,
          rrssTwitterUrl: data.twlink,
          rrssFlickerActivo: data.activoFlicker,
          rrssFlickerUrl: data.fllink,
          rrssInstagramActivo: data.activoInstagram,
          rrssInstagramUrl: data.iglink,
          rrssYoutubeActivo: data.activoYoutube,
          rrssYoutubeUrl: data.ytlink,
          rrssMultimedia: data.media,
          idIdiomas: 1,
          idCamposIdiomas: 1,
          tipoAgente:data.tipoAgente!=null && data.tipoAgente!=''?data.tipoAgente:0,
          foroPermUsuariosCrearPost:data.foroPermUsuariosCrearPost
        },
        { withCredentials: true }
      );
    }
  
    update(data) {
      console.log('[AgenteService] Update ');
      console.log(JSON.stringify(data));
      return this.http.post<any>(
        baseUrl + '/editar',
        {
          id: data.codigo,
          nombre: data.nombreOrg,
          imagenAvatar: data.logo,
          imagenCabecera: data.cabecera,
          organizacionActiva: data.activoOrganizacion,
          email: data.email,
          smtp: data.smtp,
          puerto: data.puerto,
          emailUsuario: data.usuario,
          emailPassword: data.contrasena,
          cambiarPasswordActivo: data.activoGuardarPassword,
          infoNotificacionesActivas: data.activoNotificaciones,
          infoFarmaciasActivas: data.activoFarmacias,
          infoFaqActivo: data.activoFAQ,
          infoGestionDocumentalActiva: data.activoGesDoc,
          infoContactosActivo: data.activoContactos,
          agendaActiva: data.activoAgenda,
          agendaEdadMinima: data.agendaEdadMin,
          mensajeriaActiva: data.activoMensajeria,
          mensajeriaIncidenciasDepUnico: data.activoIncidenciasDepUnico,
          idDepartamento: data.departamento!=null && data.departamento!=''?data.departamento:0,
          reservasActivas: data.activoReservas,
          reservasConfirmacion: data.activoConfirmReserva,
          reservasUsuarioEnCalendario: data.activoMostrarUsuarioCalendario,
          reservasDiasMinimos: data.mindiasresrva,
          reservasApertura: data.apertura,
          reservasCorreos: data.correosReservas,
          coordenadasLatitud: data.latitud,
          coordenadasLongitud: data.longitud,
          coordenadasZoom: data.zoom,
          agentesActivos: data.activoAgentes,
          agentesTiposActivos: data.activoTipos,
          permUsuariosPublicar: data.permUsuariosPublicar,
          permUsuariosComentar: data.permUsuariosComentar,
          foroActivo: data.activoForo,
          foroEdadMinima: data.edadforo,
          encuestasActivas: data.activoEncuestas,
          encuestasEdadMinima: data.edadencuestasmin,
          rrssFacebookActivo: data.activoFacebook,
          rrssFacebookUrl: data.fblink,
          rrssTwitterActivo: data.activoTwitter,
          rrssTwitterUrl: data.twlink,
          rrssFlickerActivo: data.activoFlicker,
          rrssFlickerUrl: data.fllink,
          rrssInstagramActivo: data.activoInstagram,
          rrssInstagramUrl: data.iglink,
          rrssYoutubeActivo: data.activoYoutube,
          rrssYoutubeUrl: data.ytlink,
          rrssMultimedia: data.media,
          idIdiomas: 1,
          idCamposIdiomas: 1,
          tipoAgente:data.tipoAgente!=null && data.tipoAgente!=''?data.tipoAgente:0,
          foroPermUsuariosCrearPost:data.foroPermUsuariosCrearPost
        },
        { withCredentials: true }
      );
    }

    delete(data?: any): Observable<any> {
      console.log(JSON.stringify(data));
      var formData: any = new FormData();
      formData.append('codigos', data.codigos);
      console.log(JSON.stringify(formData));
      return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
        withCredentials: true,
      });
    }

    //TIPOS AGENTE
    public getAllTiposAgente(): Observable<any> {
      return this.http.get(baseUrl+'/tiposagente');
    }

    public getAllTiposAgenteList(): Observable<any> {
      return this.http.get<TipoAgente[]>(baseUrl+'/tiposagenteactivoslist');
    }

    public GetTipoById(idTipoAgente:number): Observable<any> {
      return this.http.get(baseUrl+'/tiposagente/'+idTipoAgente);
    }
    
    deleteTipos(data?: any): Observable<any> {
      console.log(JSON.stringify(data));
      var formData: any = new FormData();
      formData.append('codigos', data.codigos);
      console.log(JSON.stringify(formData));
      return this.http.post<JSON>(baseUrl + '/eliminartipos', formData, {
        withCredentials: true,
      });
    }

    insertTipoAgente(data) {
      console.log(JSON.stringify(data));
      return this.http.post<any>(
        baseUrl + '/creartipo',
        {
          nombre: data.nombre,
          descripcion: data.descripcion,
          activo: data.activo,
        },
        { withCredentials: true }
      );
    }
  
    updateTipoAgente(data) {
      console.log(JSON.stringify(data));
      return this.http.post<any>(
        baseUrl + '/editartipo',
        {
          id: data.codigo,
          nombre: data.nombre,
          descripcion: data.descripcion,
          activo: data.activo,
        },
        { withCredentials: true }
      );
    }

   updateSubAgentes2(listaAgents,idOrg):Observable<any>{
      console.log('Updating subagentes from org...:');
      return this.http.post<any>(
        baseUrl + '/editarsubs/'+idOrg,
        JSON.stringify(listaAgents),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            charset: 'utf-8',
          }),
          withCredentials: true,
        }
      );

      
    }
    public getAdminsFromEnte(id:string): Observable<any> {
     return this.http.get(baseUrl + '/admins/'+id);
   }

   updateAdmins(listaAdmins,idOrg):Observable<any>{
    console.log('Updating admins from ente...:');
    return this.http.post<any>(
      baseUrl + '/admins/update/'+idOrg,
      JSON.stringify(listaAdmins),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
    }
  //test
    updateMiembrosPrivados(model):Observable<any>{
      console.log('Updating miembros from Agente Privado...:');
      return this.http.post<any>(
        baseUrl + '/privado/updatemiembros/',
        JSON.stringify(model),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            charset: 'utf-8',
          }),
          withCredentials: true,
        }
      );
    }

    getMiembrosAgentePrivado(idOrg:string):Observable<any>{
      return this.http.get(baseUrl + '/privado/getmiembros/'+idOrg);
    }
}
