import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Rss } from '@app/_models/rss';

const baseUrl = `${environment.apiUrl}/rss`;

@Injectable({
  providedIn: 'root'
})
export class RssService {

  constructor(private http: HttpClient) {
  }

  getRssFromEnte(idOrg:string,lang:number=-1) {
    return this.http.get<any>(`${baseUrl}/get/`+idOrg + '/'+ lang, { withCredentials: true });
  }
  
  
  public crearRss(model: Rss): Observable<any> {
    return this.http.post(
      baseUrl + '/createrss/',
      JSON.stringify(model),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public updateRss(model: Rss): Observable<any> {
    return this.http.post(
      baseUrl + '/updaterss/',
      JSON.stringify(model),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteRss(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/delete', formData, {
      withCredentials: true,
    });
  }

  getRssByIdLang(idRss:number,lang:number):Observable<any>{
    return this.http.get<any>(`${baseUrl}/getone/`+idRss + '/'+ lang, { withCredentials: true });
  }

}
