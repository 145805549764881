import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Idioma } from '@app/_models/idioma';
import { iconoModulo, Modulo, ModulosRoot, ModuloTrads } from '@app/_models/menu-main';
import { AlertService } from '@app/_services/alert.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { MainMenuSerivice } from '@app/_services/menu-main.service';
import { MenuService } from '@app/_services/menu.service';
import { UsuariosService } from '@app/_services/usuarios.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modulo-directo',
  templateUrl: './modulo-directo.component.html',
})
export class ModuloDirectoComponent implements OnInit {
  
  public idModulo: number = 0;
  public numeroOrden: number = 1;
  public idiomas: any[] = [];
  public idiomasEnviar: ModuloTrads[] = [];
  public listaTraducciones: Idioma[] = [];
  public selectedIdioma;
  public direccionTipos: string[] = ['Perfil', 'Follow', 'Farmacias', 'Recorridos', 'Reservas','SearchUsers','Entidades'];
  public selectedDireccion: string = 'Perfil'
  // TODO cambiar a iconos de libreria propia una vez esten metidos
  public iconos: iconoModulo[] = [
    {
      nombreIcono: 'Perfil',
      codigoIcono: '&#xf2bd;'
    },
    {
      nombreIcono: 'Follow',
      codigoIcono: '&#xf234;'
    },
    {
      nombreIcono: 'Farmacias',
      codigoIcono: '&#xf469;'
    },
    {
      nombreIcono: 'Recorridos',
      codigoIcono: '&#xf4d7;'
    },
    {
      nombreIcono: 'Reservas',
      codigoIcono: '&#xf2e7;'
    },
    {
      nombreIcono: 'SearchUsers',
      codigoIcono: '&#xf2e7;'
    },
    {
      nombreIcono: 'Entidades',
      codigoIcono: '&#xf2e7;'
    },
  ];
  public selectedIcono: string = '&#xf2e7;';
  public elementosSuperiores: ModulosRoot[] = [
    {
      nombre: 'root',
      id: 0
    }
  ];
  public selectedPadre = 0;
  public nombre: string = "";
  private translate: TranslateService;
  // Flags
  public isUpdate: boolean = false;
  constructor(
    private idiomaService: IdiomasService,
    translate: TranslateService,
    private mainMenuService: MainMenuSerivice,
    private menuService: MenuService,
    private route: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private alertService: AlertService
    ) {
      this.idModulo = this.route.snapshot.params['idModulo'];
      this.translate = translateService;
      this.menuService.titulo = this.translate.instant('menu-main.cabecera');
      this.getIdiomas();
    }
    
    ngOnInit() {
    }
    getIdiomas() {
      this.idiomaService.GetAll().subscribe(result => {
        console.log(result.data);
        this.idiomas = result.data;
        this.selectedIdioma = this.idiomas[0];
        this.idiomas.forEach(idioma => {
          const trad = new ModuloTrads();
          trad.filled = false;
          trad.idIdioma = idioma.codigo;
          trad.nombreIdioma = idioma.nombre;
          this.idiomasEnviar.push(trad);
        });
        this.getCategorias();
        if (this.idModulo > 0) {
          this.isUpdate = true;
        this.getModulos();
      } else {
        this.getOrden(0);
      }
    });
  }

  getCategorias() {
    this.mainMenuService.getCategoriasByIdLang(this.selectedIdioma.codigo).subscribe(result => {
      this.elementosSuperiores = [...this.elementosSuperiores, ...result.data];
    })
  }

  getModulos() {
    this.mainMenuService.getModuloByIdLang(this.idModulo, this.selectedIdioma.codigo).subscribe(result => {
      this.nombre = result.data[0].nombre;
      this.selectedDireccion = result.data[0].direccion;
      this.selectedIcono = result.data[0].icono;
      this.selectedPadre = result.data[0].idPadre;
    });
  }

  getOrden(idPadre: number) {
    this.mainMenuService.getOrden(idPadre).subscribe(result => {
      if (result.data.length !== 0) {
        this.numeroOrden = result.data[0].numeroOrden + 1;
      } else {
        this.numeroOrden = 1;
      }
    });
  }


  onSubmit() {
    if (this.nombre == "") {
      this.alertService.error(this.translateService.instant('restaurante.debeAsignarseUnNombre'));
      return;
    }
    this.sanitazeName();
    if (!this.isUpdate) {
      this.completarIdiomas();
      if (!this.isIdiomaSet()) {
        this.alertService.error(this.translateService.instant('menu-main.errortrads'));
        return;
      }
    }
    let modulo = this.createModulo();
    console.log(modulo);
    if (!this.isUpdate) {
      this.mainMenuService.createModulo(modulo).subscribe(response => {
        this.onClickCancelar(null);
      });
    } else {
      this.mainMenuService.updateModulo(modulo).subscribe(response => {
        this.onClickCancelar(null);
      });
    }
  }

  isIdiomaSet() {
    for (let index = 0; index < this.idiomasEnviar.length; index++) {
      const element = this.idiomasEnviar[index];
      if (element.filled) {
        return true;
      }
    }
    return false;
  }

  sanitazeName() {
    this.nombre = this.nombre.replace(/[^a-zA-Z0-9 ]/g, '');
  }

  createModulo(): Modulo {
    let modulo: Modulo = new Modulo();
    if (this.isUpdate)
      modulo.id = this.idModulo;
    modulo.nombre = this.nombre;
    modulo.icono = this.selectedIcono;
    modulo.tipo = 0;
    modulo.idPadre = this.selectedPadre;
    modulo.direccion = this.selectedDireccion;
    if (!this.isUpdate)
      modulo.orden = this.numeroOrden;
    modulo.listaTraducciones = this.isUpdate ? [] : this.idiomasEnviar;
    return modulo;
  }

  completarIdiomas() {
    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: ModuloTrads = new ModuloTrads();
    let encontrado: boolean = false;
    this.idiomasEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.idiomasEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
      }
    });
  }

  onClickCancelar($event) {
    this.router.navigate(["/menu-main"]);
  }

  btnAddTraduccion() {
    if (
      this.selectedIdioma == null ||
      this.nombre.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }

    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.idiomasEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombre;
        }
      });
    }
  }

  btnDeleteTraduccion(idioma: Idioma) {
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.idiomasEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );
  }

  // Handlers

  updateName(event) {
    this.nombre = event;
  }

  updateSelectedIdioma(event) {
    console.log(this.selectedIdioma);
    if (this.isUpdate) //Si es nuevo no existen
      this.mainMenuService
        .getModuloByIdLang(this.idModulo, this.selectedIdioma.codigo)
        .subscribe((result) => {
          this.nombre = result.data[0].nombre;
        });
    else {
      var auxi = this.idiomasEnviar.filter(x => x.idIdioma == this.selectedIdioma.codigo);
      if (auxi != undefined && auxi[0].nombre != undefined)
        this.nombre = auxi[0].nombre;
      else
        this.nombre = "";
    }
  }

  updateOrden(event) {
    this.getOrden(event);
  }
}
