<div class="row">
    <div class="col-lg-8">
      <div class="card">
          <div class="card-header">
              <h3>{{'rss.cebecera' | translate}}</h3>
          </div>
          <div class="card-body">
              <kendo-dropdownlist #dropdown
              [data]="listaEntesAdministrados"
              textField="nombre"
              valueField="id"
              [(ngModel)]="selectedOrg"
              (valueChange)="loadIdiomas($event, dropdown)">
          </kendo-dropdownlist>
  
          <kendo-dropdownlist #dropdown
          [data]="listaIdiomas"
          textField="nombre"
          valueField="codigo"
          [(ngModel)]="selectedIdioma"
          (valueChange)="loadRss($event, dropdown)">
      </kendo-dropdownlist>

          <kendo-grid
          [kendoGridBinding]="listaRss"
          kendoGridSelectBy="id"
          [sortable]="true"
          [navigable]="true"
          [filterable]="true"
          (cellClick)="cellClick($event)"
          [(selectedKeys)]="seleccionados"
          [pageSize]="15"
          [pageable]="true"
        >
          <ng-template kendoGridToolbarTemplate position="top">
            <button
              kendoButton
              (click)="onClickEditar()"
              class="btn mr-1 btn-success btn-sm mr-1"
            >
              {{ "botones.editar" | translate }}
            </button>
            <button
              kendoButton
              (click)="onClickNuevo()"
              class="btn mr-1 btn-primary btn-sm mr-1"
            >
              {{ "botones.nuevo" | translate }}
            </button>
            <button
              kendoButton
              (click)="onClickEliminar(content)"
              class="btn mr-1 btn-danger btn-sm mr-1"
              [disabled]="isDeleting"
            >
              {{ "botones.eliminar" | translate }}
            </button>
          </ng-template>
          <kendo-grid-checkbox-column
            width="1%"
            showSelectAll="true"
          ></kendo-grid-checkbox-column>
          <kendo-grid-column
            width="10%"
            [style]="{ 'text-align': 'left' }"
            field="nombre"
            title="{{ 'rss.nombre' | translate }}"
          >
          </kendo-grid-column>
      
          <kendo-grid-column
            width="10%"
            [style]="{ 'text-align': 'left' }"
            field="urlRss"
            title="{{ 'rss.urlrss' | translate }}"
          >
          </kendo-grid-column>
      
  
        </kendo-grid>
          </div>
      </div>
    </div>
      </div>
  
  
      <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title"></h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ "organizaciones.eliminar" | translate }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="modal.dismiss('cancel click')"
          >
            {{ "botones.no" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            (click)="eliminarRegistro(contentloading)"
          >
            {{ "botones.si" | translate }}
          </button>
        </div>
      </ng-template>
      
      <ng-template #contentloading let-modal>
        <div class="modal-body">
          <p>{{ "registros.eliminando" | translate }}</p>
          <div class="spinner-border" role="status">
            <span class="sr-only"
              >{{ "registros.eliminando" | translate }}...</span
            >
          </div>
        </div>
      </ng-template>