import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import { ForoPost, ForoTopic, ForoTopicModel, ForoTopicTrad } from '@app/_models/foro';
import { Idioma } from '@app/_models/idioma';
import { AlertService, ForoService, MenuService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
@Component({
  selector: 'app-foro-posts',
  templateUrl: './foro-posts.component.html'
})
export class ForoPostsComponent implements OnInit {
  listaPosts:ForoPost[]=[];
  listaMensajes:ForoPost[]=[];

  translate;
  idTopic:number;
  idOrg:string;
  public seleccionados: number[] = [];
  public seleccionadosMensajes: number[] = [];

  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  
  constructor(
    private foroService: ForoService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('foro.gesposts');
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idTopic = this.route.snapshot.params['idtopic'];
  }

  ngOnInit(): void {
    this.foroService.getPostsFromTopic(this.idTopic).subscribe((res)=>{
      console.log('Post:');
      console.log(res);
      this.listaPosts = res.data;
      if(this.listaPosts.length>0){
        this.foroService.getMensajesFromTopic(this.idTopic,this.listaPosts[0].id).subscribe((res)=>{
          this.listaMensajes = res.data;
        });
      }
    });
  }

  btnCancelar(){
    this.router.navigate(['foro-topics',{ idorg:this.idOrg }]);
  }

  cellClick(event){
    console.log(event);
  }

  selectionChange(event){
    console.log(event);
    this.foroService.getMensajesFromTopic(this.idTopic,event[0]).subscribe((res)=>{
      console.log(res.data);
      this.listaMensajes = res.data;
    });
  }

  deleteMessage(message){
    console.log('Eliminando mensajes');
    console.log(message);
    debugger
    this.foroService.deleterespuesta(message.id).subscribe((res)=>{
      if(!res.error){
        this.listaMensajes = this.listaMensajes.filter((x)=>x.id!=message.id);
      }
    });
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  eliminarRegistro(contentloading){
    console.log('Eliminar registro');
    this.foroService.deletePostsFromTopic({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.foroService.getPostsFromTopic(this.idTopic).subscribe((result) => {
            this.listaPosts = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

  onClickNuevo(){
    this.router.navigate(['foro-posts-crear',{ idorg:this.idOrg,idtopic:this.idTopic }]);
  }

}
