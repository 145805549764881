<!--Creación Categoria-->
<div [ngClass]="showPanelCategoria == false ? 'cont-filtro-desplegable card filtro-oculto' : 'cont-filtro-desplegable card filtro-desplegado'">
  <div class="card-header">
    <h3 _ngcontent-xdi-c377="">{{"equipos.crearcatbloque" | translate}}</h3>
    <button type="button" class="flt-cerrar" (click)="cerrarDesplegableCategoria()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="filtro-desplegable card-body flt">
    <div class="card-body">
      <div class="form-group">
        <!--Lista idiomas-->

        <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
        </kendo-label>

        <div class="caja">
          <kendo-dropdownlist
            #dropdownI
            [data]="listaIdiomas"
            textField="nombre"
            valueField="codigo"
            [(ngModel)]="selectedIdioma"
          ></kendo-dropdownlist>
        </div>
      </div>

      <div class="form-group">
        <label>{{ "recintos.lbl_nombre_sala" | translate }}</label>
        <div class="caja">
          <!--Titulo-->
          <kendo-textbox
            placeholder="{{ 'recintos.ph_nombre' | translate }}"
            [clearButton]="true"
            [(ngModel)]="myCategoriaTMP.nombre"
          >
            <ng-template kendoTextBoxPrefixTemplate> </ng-template>
          </kendo-textbox>
        </div>
      </div>


      <!-- Traducciones -->
      <div class="form-group">
        <button
          type="button"
          class="btn btn-primary btn-sm mr-1"
          (click)="btnAddTraduccion()"
        >
          {{ "publicaciones.anadir" | translate }}
        </button>
        <div class="listado-traducciones">
          <div class="traduccion" *ngFor="let idioma of listaTraducciones">
            {{ idioma.nombre }}
            <button
              type="button"
              class="btn btn-info btn-sm mr-1"
              (click)="btnDeleteTraduccion(idioma)"
            >
              X
            </button>
          </div>
        </div>
      </div>
    </div>

    <button type="button" (click)="addCategoryBlock()" class="btn btn-nuevo " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{ "botones.guardar" | translate }}
    </button>
  </div>
</div>



<!--Creación BloqueInput-->
<div [ngClass]="showPanelInput == false ? 'cont-filtro-desplegable card filtro-oculto' : 'cont-filtro-desplegable card filtro-desplegado'">
  <div class="card-header">
    <h3 _ngcontent-xdi-c377="">{{"equipos.crearbloqueinput" | translate}}</h3>
    <button type="button" class="flt-cerrar" (click)="cerrarDesplegableInput()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="filtro-desplegable card-body flt">
    <div class="card-body">
      <div class="form-group">
        <!--Lista idiomas-->

        <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
        </kendo-label>

        <div class="caja">
          <kendo-dropdownlist
            #dropdownI
            [data]="listaIdiomas"
            textField="nombre"
            valueField="codigo"
            [(ngModel)]="selectedIdioma"
          ></kendo-dropdownlist>
        </div>
      </div>

      <div class="form-group">
        <label>{{ "equipos.nombrevalor" | translate }}</label>
        <div class="caja">
          <!--Titulo-->
          <kendo-textbox
            placeholder="{{ 'recintos.ph_nombre' | translate }}"
            [clearButton]="true"
            [(ngModel)]="myBloqueTMP.nombre"
          >
            <ng-template kendoTextBoxPrefixTemplate> </ng-template>
          </kendo-textbox>
        </div>
      </div>

      <!--isCustom -->
      <kendo-label
      class="k-checkbox-label"
      text="{{ 'equipos.iscustom' | translate }}"
    ></kendo-label>
      <div class="caja">
        <kendo-switch [(ngModel)]="myBloqueTMP.isCustom"></kendo-switch>
      </div>
   

      <div *ngIf="!myBloqueTMP.isCustom;else custom" class="form-group">
        <!--Lista idiomas-->

        <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
        </kendo-label>

        <div class="caja">
          <kendo-dropdownlist
            #dropdownI
            [data]="listaTipposComputables"
            textField="nombre"
            valueField="id"
            [(ngModel)]="selectedComputable"
          ></kendo-dropdownlist>
        </div>
      </div>

      <ng-template #custom>
        <div class="card-body">
          <kendo-label
          text="{{ 'equipos.valordefecto' | translate }}"
        ></kendo-label>
          <kendo-textbox
          [style.width.px]="200"
          placeholder="Valor"
          [clearButton]="true"
          [(ngModel)]="myBloqueTMP.valorCustom"
        >
        </kendo-textbox>
        </div>
      </ng-template>


      <!-- Traducciones -->
      <div class="form-group">
        <button
          type="button"
          class="btn btn-primary btn-sm mr-1"
          (click)="btnAddTraduccionInput()"
        >
          {{ "publicaciones.anadir" | translate }}
        </button>
        <div class="listado-traducciones">
          <div class="traduccion" *ngFor="let idioma of listaTraducciones">
            {{ idioma.nombre }}
            <button
              type="button"
              class="btn btn-info btn-sm mr-1"
              (click)="btnDeleteTraduccionInput(idioma)"
            >
              X
            </button>
          </div>
        </div>
      </div>
    </div>
    <button (click)="addBloqueInput()" type="button" class="btn btn-nuevo " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{ "botones.guardar" | translate }}
    </button>
  </div>
</div>

<!--Update BloqueInput-->
<div [ngClass]="showUpdateInput == false ? 'cont-filtro-desplegable card filtro-oculto' : 'cont-filtro-desplegable card filtro-desplegado'">
  <div class="card-header">
    <h3 _ngcontent-xdi-c377="">{{"equipos.updatebloqueinput" | translate}}</h3>
    <button type="button" class="flt-cerrar" (click)="cerrarUpdateInput()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="filtro-desplegable card-body flt">
    <div class="card-body">
      <div class="form-group">
        <!--Lista idiomas-->

        <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
        </kendo-label>

        <div class="caja">
          <kendo-dropdownlist
            #dropdownI
            [data]="listaIdiomas"
            textField="nombre"
            valueField="codigo"
            [(ngModel)]="selectedIdioma"
            (valueChange)="idiomaChange($event, dropdownI)"
          ></kendo-dropdownlist>
        </div>
      </div>

      <div class="form-group">
        <label>{{ "equipos.nombrevalor" | translate }}</label>
        <div class="caja">
          <!--Titulo-->
          <kendo-textbox
            placeholder="{{ 'recintos.ph_nombre' | translate }}"
            [clearButton]="true"
            [(ngModel)]="myBloqueTMP.nombre"
          >
            <ng-template kendoTextBoxPrefixTemplate> </ng-template>
          </kendo-textbox>
        </div>
      </div>

      <!--isCustom -->
      <kendo-label
      class="k-checkbox-label"
      text="{{ 'equipos.iscustom' | translate }}"
    ></kendo-label>
      <div class="caja">
        <kendo-switch [(ngModel)]="myBloqueTMP.isCustom"></kendo-switch>
      </div>
   

      <div *ngIf="!myBloqueTMP.isCustom;else custom" class="form-group">
        <!--Lista idiomas-->

        <kendo-label text="{{ 'equipos.origendato' | translate }}">
        </kendo-label>

        <div class="caja">
          <kendo-dropdownlist
            #dropdownI
            [data]="listaTipposComputables"
            textField="nombre"
            valueField="id"
            [(ngModel)]="selectedComputable"
          ></kendo-dropdownlist>
        </div>
      </div>

      <ng-template #custom>
        <div class="card-body">
          <kendo-label
          text="{{ 'equipos.valordefecto' | translate }}"
        ></kendo-label>
          <kendo-textbox
          [style.width.px]="200"
          placeholder="Valor"
          [clearButton]="true"
          [(ngModel)]="myBloqueTMP.valorCustom"
        >
        </kendo-textbox>
        </div>
      </ng-template>

    </div>
    <button (click)="updateInputData()" type="button" class="btn btn-nuevo " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      {{ "botones.actualizar" | translate }}
    </button>
  </div>
</div>


<div class="cabecera-editor card">
    <div class="clearfix">
      <div class="cabecera-editor-titulo">
  
    
        <div class="form-group">

          <div class="caja">
            <!--Titulo-->
            <kendo-textbox
              placeholder="{{ 'recintos.ph_nombre' | translate }}"
              [clearButton]="true"
              [(ngModel)]="myPlantilla.nombre"
            >
              <ng-template kendoTextBoxPrefixTemplate> </ng-template>
            </kendo-textbox>
          </div>
        </div>
  
      </div>
  
      <div class="cabecera-editor-botones">
        <div class="btn-group float-right" role="group" aria-label="Basic example">
          <button (click)="btnCrearPlantilla()" [disabled]="isBusy" type="button" class="btn btn-nuevo">Guardar</button>
          <button type="button" class="btn btn-copiar">Guardar plantilla</button>
          <button type="button" class="btn btn-danger">Cancelar</button>
        </div>
      </div>
    </div>
  
  </div>
  <!--contenedor-->
<div class="clearfix contenedor-editor">


  <!--Una columna-->
  <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
  <div class="example-box card" *ngFor="let categoria of myPlantilla.listaCategoriaBloques" cdkDrag >
    <div class="card-header">

      <div class="clearfix">
        <div class="opcion-draganddrop">
          <i class="fas fa-expand-arrows-alt"></i>

        </div>

        <div class="cont-opciones-editor">


          <div class="btn-group" role="group" aria-label="Basic example">

            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i data-toggle="dropdown" class="icon-mas-alt"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <div class="d-flex justify-content-center bd-highlight">

                  <button (click)="addLineaInputs(categoria,1)" type="button" class="btn "><span class="uncol-icono"> <span class="col-icono"></span></span> </button>

                  <button (click)="addLineaInputs(categoria,2)" type="button" class="btn "><span class="doscol-icono"> <span class="col-icono"></span><span class="col-icono"></span></span> </button>

                  <button (click)="addLineaInputs(categoria,3)" type="button" class="btn "><span class="trescol-icono"> <span class="col-icono"></span><span class="col-icono"></span><span class="col-icono"></span></span> </button>

                </div>
              </div>
            </div>

            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i data-toggle="dropdown" class="icon-ajustes"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <button class="dropdown-item" type="button"><i class="icon-novisible"></i>Ocultar</button>
                <button class="dropdown-item" type="button"><i class="icon-duplicar"></i>Duplicar</button>
                <button (click)="btmELiminarCategoria(categoria)" class="dropdown-item" type="button"><i class="icon-equis"></i>Eliminar</button>
              </div>
            </div>
          </div>



        </div>

      </div>

      <h4 class="titulo-categoria-drag">
        {{categoria.nombre}}
      </h4>

    </div>



    <div class="card-body" >
      <!-- {{categoria.listaBloquesInput | json}} -->
      <!-- <div *ngFor="let lineatmp of categoria.listaBloquesInput">
        {{lineatmp.bloque1 |json}}
      </div> -->
      <div class="row" *ngFor="let linea of categoria.listaBloquesInput">
        
        <div class="col" *ngFor="let input of linea.listaInputs">

          <div  *ngIf="input.activo && !input.filled;else contenido">
            <div class="agregar-elemento" (click)="abrirDesplegableInput(categoria,linea,input)">
              <i class="icon-mas-alt"></i>
            </div>
          </div>
          <ng-template #contenido>
              <div *ngIf="input.filled && input.activo">
                <div class="elemento-agregado" (click)="abrirUpdateInput(categoria,linea,input)">
                  <div class="nombre-campo-elemento">
                    {{input.nombre}}
                  </div>
                  <div *ngIf="input.isCustom">
                    <p class="valor-pordefecto">{{ "equipos.valordefecto" | translate }} :</p>
                    <p class="valor-campo-elemento"> {{input.valorCustom}}</p>
                  </div>
                  <div *ngIf="!input.isCustom" >
                    <p class="valor-campo-elemento"> {{input.nombreTipoComputable}}</p>
                  </div>
                </div>
              </div>
          </ng-template>
        </div>
        


        <div class="btn-group cambiar-columnas">
          <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i data-toggle="dropdown" class="icon-columnas"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <div class="d-flex justify-content-center bd-highlight">

              <button (click)="resetLineaInput(linea,1)" type="button" class="btn "><span class="uncol-icono"> <span class="col-icono"></span></span> </button>

              <button (click)="resetLineaInput(linea,2)" type="button" class="btn "><span class="doscol-icono"> <span class="col-icono"></span><span class="col-icono"></span></span> </button>

              <button (click)="resetLineaInput(linea,3)" type="button" class="btn "><span class="trescol-icono"> <span class="col-icono"></span><span class="col-icono"></span><span class="col-icono"></span></span> </button>
            </div>
          </div>
          <h3 (click)="borrarLinea(categoria,linea)">X</h3>
        </div>

      </div>
        <!--End For Lineas-->
    </div>

  </div>
</div>
 


</div>
  <!--fin contenedor-->
  
  <!--botones abajo-->
  <div class="text-center">
    <button (click)="abrirDesplegableCategoria()" type="button" class="btn  btn-nuevo ">
      Añadir Categoria
    </button>
  </div>
