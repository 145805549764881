<div class="calendarFiltro w-100 row justify-content-end">
  <div class="calendarFiltro-input border rounded p-2 row w-100" (click)="showCalendar()"
    style="float: right; border-color: rgba(0,0,0,.08)!important;">
    <div class="col px-0 d-flex justify-content-end ">
      <label *ngIf="fechaIni != undefined && fechaFin == undefined">{{fechaIni | kendoDate}}</label>
      <label *ngIf="fechaFin != undefined && fechaFin != undefined">{{fechaIni | kendoDate}} - {{fechaFin
        | kendoDate}}</label>
      <i class="k-icon k-i-calendar ml-2"></i>
    </div>
  </div>
  <div id="calendarFiltro-calendario" class="row position-absolute bg-white" [hidden]="!mostrarCalendario"
    style=" width: 25rem; top: 6rem; left: 1rem;">
    <div id="col-mes" class="col-8 px-0">
      <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="classic"
        (click)="valueClickCalendar(0, $event)">
        <ng-template kendoCalendarMonthCellTemplate let-date>
          <span class="calendarFiltro-calendario-contenido-dia"
            [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(date)"> {{ date.getDate() }} </span>
        </ng-template>
        <ng-template kendoCalendarHeaderTitleTemplate let-title>
          <div> {{ title }} </div>
        </ng-template>
      </kendo-calendar>
    </div>
    <div id="col-mes-botones" class="col-4 pl-1 pr-0">
      <div class="calendarFiltro-botones  d-flex align-items-center flex-column">
        <div class="clearfix text-center">
          <button type="button" (click)="cambiarMeses(-1)"><i class="fa fa-chevron-left"></i></button>
          <button type="button" (click)="cambiarMeses(1)"><i class="fa fa-chevron-right"></i></button>
          <button type="button" (click)="cargarMeses()">{{ 'filtroPersonalizadoCalendario.hoy' | translate}}</button>
        </div>
        <div class="pl-2">
          <div class="clearfix"><button type="button" (click)="ultimas24HButton()">{{'filtroPersonalizadoCalendario.ultimas24H' | translate}}</button>
          </div>
          <div class="clearfix"><button type="button" (click)="ultimos7DiasButton()">{{'filtroPersonalizadoCalendario.ultimos7Dias' |
              translate}}</button></div>
          <div class="clearfix"><button type="button" (click)="ultimos30DiasButton()">{{'filtroPersonalizadoCalendario.ultimos30Dias' |
              translate}}</button></div>
          <div class="clearfix"><button type="button" (click)="ultimos60DiasButton()">{{'filtroPersonalizadoCalendario.ultimos60Dias' |
              translate}}</button></div>
          <div class="clearfix"><button type="button" (click)="ultimos90DiasButton()">{{'filtroPersonalizadoCalendario.ultimos90Dias' |
              translate}}</button></div>
        </div>
      </div>
    </div>
  </div>
</div>