<div class="row">
<!--CHARTS-->
<div class="col-4">
  <div class="card">
    <div class="card-header">
        <label><h3>{{'eventos.aforo' | translate}}</h3></label>
    </div>
    <div class="card-body">

  <div id="chartInscripciones">
  </div>

  </div>
  </div>
</div>
<!--GRID-->
<div class="col-8">
  <div class="card">
    <div class="card-header">
        <label><h3>{{'eventos.inscritos' | translate}}</h3></label>
    </div>
    <div class="card-body">
        <kendo-grid
        [data]="listaInscripciones"
        kendoGridSelectBy="id"
        [sortable]="true"
        [navigable]="true"
        [filterable]="true"
        (cellClick)="cellClick($event)"
        [(selectedKeys)]="seleccionados"
        [pageSize]="15"
        [pageable]="true"
        >
        <!-- <ng-template kendoGridToolbarTemplate position="top">
          <button
            kendoButton
            (click)="onClickEditar()"
            class="btn mr-1 btn-success btn-sm mr-1"
          >
            {{ "botones.crear" | translate }}
          </button>
        </ng-template> -->
        <kendo-grid-checkbox-column
          width="1%"
          showSelectAll="true"
          field="id"
        ></kendo-grid-checkbox-column>
        <kendo-grid-column
          width="15%"
          [style]="{ 'text-align': 'left' }"
          field="nombre"
          title="{{ 'eventos.nombre' | translate }}"
        >
        </kendo-grid-column>
        <kendo-grid-column
        width="15%"
        [style]="{ 'text-align': 'left' }"
        field="email"
        title="{{ 'eventos.email' | translate }}"
      >
      </kendo-grid-column>
      <kendo-grid-column
      title="{{ 'eventos.estado' | translate }}"
      width="10%"
      >
      <ng-template kendoGridCellTemplate let-dataItem>
          <button
          type="button"
          class="btn btn-danger btn-sm mr-1"
          (click)="cancelarInscripcion(dataItem)"
      >X</button>
      </ng-template>
      </kendo-grid-column>

        </kendo-grid>
    </div>
</div>
</div>
</div>

<button
kendoButton
(click)="goBack()"
class="btn mr-1 btn-primary"
>
{{ "botones.atras" | translate }}
</button>