import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import { ForoTopic } from '@app/_models/foro';
import { AlertService, ForoService, MenuService } from '@app/_services';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-foro-topics',
  templateUrl: './foro-topics.component.html',
})
export class ForoTopicsComponent implements OnInit {

  listaEntesAdministrados:Ente[]=[];
  listaTopics:ForoTopic[];
  selectedOrg:Ente;
  public seleccionados: number[] = [];
  lang;
  private translate: TranslateService;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  constructor(
    public foroService:ForoService,public organizacionService:OrganizacionesService,
    public publicacionesService:PublicacionesService,
    private menuService: MenuService,
    public router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.publicacionesService.getEntesAdministrados().subscribe((result) => {
      this.listaEntesAdministrados = result.data;
      if(this.listaEntesAdministrados.length>0){
        this.selectedOrg=this.listaEntesAdministrados[0];
        this.foroService.getTopicsFromEnte(this.selectedOrg.id).subscribe((res)=>this.listaTopics=res.data);
      }
    });
  }


  loadTopics(value, dropDownControl: DropDownListComponent) {
    console.log('Cargando idiomas de :' + value.id);
    this.foroService.getTopicsFromEnte(this.selectedOrg.id).subscribe((res)=>{
        this.listaTopics = res.data;
    });
  }

  cellClick(event){
    console.log(event);
    this.router.navigate(['foro-editar',{idorg:this.selectedOrg.id, idtopic:event.dataItem.id }]);
  }

  onClickEditar(){
    this.router.navigate(['foro-editar',{idorg:this.selectedOrg.id, idtopic:this.seleccionados[0] }]);
  }

  onClickNuevo(){
    this.router.navigate(['foro-crear',{idorg:this.selectedOrg.id }]);
  }

  onClickEliminar(content){
    console.log('Eliminando...');
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }
  eliminarRegistro(contentloading){
    console.log('Eliminar registro');
    this.foroService.deleteTopics({ codigos: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.foroService.getTopicsFromEnte(this.selectedOrg.id).subscribe((result) => {
            this.listaTopics = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

  onClickGesPosts(topic){
    this.router.navigate(['foro-posts',{idorg:this.selectedOrg.id, idtopic:topic.id }]);
  }
  
}
