<div class="col-xl-8 col-md-6">
    <div class="card">
        <div class="card-header">
            <h3>{{ "equipos.gestiontemporada" | translate }}</h3>
          </div>
        <div class="card-body">

              <div class="form-group">
                <label>{{ "equipos.nombretemp" | translate }}</label>
                <div class="caja">
                  <!--Titulo-->
                  <div class="row">
                    <div class="col">
                        <kendo-textbox
                          placeholder="2023-2024..."
                          [clearButton]="true"
                          [(ngModel)]="nombreTemporada"
                        >
                          <ng-template kendoTextBoxPrefixTemplate> </ng-template>
                        </kendo-textbox>
                    </div>
                    <div class="col">
                        <button
                        kendoButton
                        (click)="updateNombreTemp()"
                        class="btn mr-1 btn-success btn-sm mr-1"
                      >
                        {{ "botones.actualizar" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            <kendo-grid
            [kendoGridBinding]="listaPartidosJugados"
            [sortable]="true"
            [pageable]="true"
            [pageSize]="14"
            kendoGridSelectBy="id"
            [navigable]="true"
            filterable="menu"
            (cellClick)="cellClick($event)"
            [(selectedKeys)]="seleccionados"
          >
          <ng-template kendoGridNoRecordsTemplate>
            {{ "equipos.sinpartidos" | translate }}

         </ng-template>
            <ng-template kendoGridToolbarTemplate position="top">
              <button
                kendoButton
                (click)="onClickEditar()"
                class="btn mr-1 btn-success btn-sm mr-1"
              >
                {{ "botones.editar" | translate }}
              </button>
              <button
                kendoButton
                (click)="onClickNuevoPartido()"
                class="btn mr-1 btn-primary btn-sm mr-1"
              >
                {{ "botones.nuevo" | translate }}
              </button>
              <button
                kendoButton
                (click)="onClickEliminar(content)"
                class="btn mr-1 btn-danger btn-sm mr-1"
                [disabled]="isDeleting"
              >
                {{ "botones.eliminar" | translate }}
              </button>
            </ng-template>
            <kendo-grid-checkbox-column
              width="5%"
              showSelectAll="true"
            ></kendo-grid-checkbox-column>
            <kendo-grid-column
              width="20%"
              [style]="{ 'text-align': 'left' }"
              field="nombre"
              title="{{ 'equipos.jugadornombre' | translate }}"
            >
            </kendo-grid-column>
            <kendo-grid-column
              width="20%"
              [style]="{ 'text-align': 'left' }"
              field="apellidos"
              title="{{ 'equipos.jugadorapellidos' | translate }}"
            >
            </kendo-grid-column>
          </kendo-grid>
          <button
          kendoButton
          (click)="goBack()"
          class="btn mr-1 btn-info btn-sm mr-1"
          >
          {{ "botones.atras" | translate }}
          </button>
            </div>
            </div>
            </div>