import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Modulo } from '@app/_models/menu-main';
import { AlertService, MenuService } from '@app/_services';
import { MainMenuSerivice } from '@app/_services/menu-main.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropPosition, TreeItemDropEvent } from '@progress/kendo-angular-treeview';

@Component({
  selector: 'app-menu-main',
  templateUrl: './menu-main.component.html',
})
export class MenuMainComponent implements OnInit {

  public data: Modulo[] = [];
  public selectedData: any[] = [];
  private translate: TranslateService;
  private expandedIds: number[] = [];
  navigationSubscription: any;
  checkMode: any = "single";
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  modalReference: NgbModalRef;

  constructor(private translateService: TranslateService, public router: Router, public route: ActivatedRoute, public menuService: MenuService, public mainMenuService: MainMenuSerivice, private modalService: NgbModal, private alertService: AlertService) {
    this.translate = translateService;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        if (this.router.url === '/menu-main') {
          this.menuService.titulo = this.translate.instant('menu-main.cabecera');
        }
      }
    });
  }
  
  ngOnInit() {
    this.loadModulos();
  }
  
  loadModulos() {
    this.data = [];
    this.mainMenuService.getModulos().subscribe(result => {
      this.data = result.data.map(modulo => {
        if (modulo.idPadre === -1 || modulo.idPadre === 0) modulo.idPadre = undefined;
        return modulo;
      });
    });
  }
  cellClick($event) {
    switch ($event.dataItem.tipo) {
      case 0:
        this.router.navigate(['modulo-directo', { idModulo: $event.dataItem.id }]);
        break;
      case 1:
        this.router.navigate(['modulo-html', { idModulo: $event.dataItem.id }]);
        break;
      case 2:
        this.router.navigate(['modulo-categoria', { idModulo: $event.dataItem.id }]);
        break;
      case 3:
        this.router.navigate(['modulo-url', { idModulo: $event.dataItem.id }]);
      default:
        break;
    }
  }

  onClickNuevo() {
    this.router.navigate(['crear-modulo']);
  }

  onClickEliminar() {
    this.mainMenuService.deleteModulos
    console.log('Eliminando...');
    if (this.selectedData.length > 0) {
      this.modalReference = this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
    }
  }

  btnBorrarAceptar() {
    console.log(    this.selectedData);
    debugger
    this.mainMenuService.deleteModulos(this.selectedData).subscribe(result => {
      this.loadModulos();
      this.modalReference.close();
    });
  }

  handleDrop(event: TreeItemDropEvent): void {
    if (event.destinationItem.item.dataItem.tipo !== 2 && event.dropPosition === DropPosition.Over) {
      event.setValid(false);
      this.alertService.error(this.translate.instant("menu-main.errorOrderModulos"));
      return;
    }

    setTimeout(() => {
      this.ordenarArbol(this.data);
    }, 0);
  }

  ordenarArbol(orderedData: Modulo[]) {
    let orden: number = 1;
    orderedData.filter((modulo: Modulo) => { return !modulo.idPadre }).forEach((modulo: Modulo) => {
      modulo.orden = orden++;
      this.mainMenuService.updateOrden(modulo).subscribe(result => {
        if (!result.error) {
          this.updateTree(modulo);
        }
      });
    });
  }

  updateTree(moduloPadre) {
    // Si el padre no es una categoria sabemos al 100% que no tendra hijos
    if (moduloPadre.tipo !== 2)
      return;
    let modulosHijo: Modulo[] = this.data.filter((modulo: Modulo) => { return modulo.idPadre === moduloPadre.id });
    console.table(modulosHijo);
    if (modulosHijo.length < 0)
      return;
    let orden: number = 1;
    modulosHijo.forEach((modulo: Modulo) => {
      modulo.orden = orden++;
      this.mainMenuService.updateOrden(modulo).subscribe(result => {
        if (!result.error) {
          this.updateTree(modulo);
        }
      });
      this.updateTree(modulo);
    })
  }
}

