import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ente } from '@app/_models/ente';
import { Idioma } from '@app/_models/idioma';
import {Rss,RssTrad} from '@app/_models/rss';
import { AlertService, RssService } from '@app/_services';
import { IdiomasService } from '@app/_services/idiomas.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-rss-crear',
  templateUrl: './rss-crear.component.html'
})
export class RssCrearComponent implements OnInit {
    //Data
    nombreRss:string='';
    descripcionRss:string='';
    urlRss:string='';
    myRss:Rss=new Rss();
    //Listas
    listaEntesAdministrados: Ente[] = [];
    listaIdiomas: Idioma[] = [];
    listaIdiomasTotales: Idioma[] = [];
    listaTraducciones: Idioma[] = [];
    //Traducciones
    listaTraduccionesEnviar: RssTrad[] = [];
    selectedIdioma: Idioma;
  
    lang;
    private translate: TranslateService;
    idOrg: string;


  constructor(
    public rssService:RssService,
    public idiomasService:IdiomasService,
    public publicacionesService:PublicacionesService,
    public router: Router,
    translate: TranslateService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private alertService: AlertService) 
    { }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idOrg = this.route.snapshot.params['idorg'];
    //Load Langs
    this.idiomasService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new RssTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombre = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.idiomasService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0)
        this.selectedIdioma = this.listaIdiomas[0];
    });
  }

  btnCrearRss(){
    console.log('Creando RSS');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translate.instant('publicaciones.errortrads')
      );
      return;
    }
    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: RssTrad = new RssTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
        trad.descripcion = seleccionado.descripcion;
        trad.urlRss = seleccionado.urlRss;
      }
    });

    //Subir Foto  y EnviarNoticia
    this.myRss.idOrg = this.idOrg;
    this.myRss.listaTraducciones = this.listaTraduccionesEnviar;
    this.rssService.crearRss(this.myRss).subscribe((result) => {
      if (!result.error) {
        this.alertService.success(
          this.translateService.instant('registro.success')
        );
        this.router.navigate(['rss']);
      } else {
        this.alertService.error(
          this.translateService.instant('reciregistrontos.error')
        );
        return;
      }
    });
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombreRss.length <= 0 ||
      this.urlRss.length <= 0
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translate.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombreRss;
          x.descripcion = this.descripcionRss;
          x.urlRss = this.urlRss;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }

  btnCancelar(){
    this.router.navigate(['rss']);
  }

}
