import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '@app/_services/menu.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import {
  AlertService,
} from '@app/_services';
import { Recinto, RecintoTrad, Sala, SalaTrad } from '@app/_models/recinto';
import  {BloqueInput, Equipo, EquipoTrad, Jugador, JugadorTrad} from '@app/_models/equipo'
import { EquiposService } from '@app/_services/equipos.service';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Usuario } from '@app/_models';
@Component({
  selector: 'app-jugadores-editar',
  templateUrl: './jugadores-editar.component.html'
})
export class JugadoresEditarComponent implements OnInit {
  idOrg:string;
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];
  //Data
  myJugador:Jugador=new Jugador();
  selectedIdioma: Idioma;
  filePath: string;
  filePathFondo: string;
  private translate: TranslateService;
  lang;
  myPosicion:string='';
  myLateralidad:string='';
  myObservaciones:string='';
  myNacionalidad:string='';
  isUsuarioVinculado:boolean=false;
  usuarioVincular:Usuario= new Usuario();
  listaCustomPlantilla:BloqueInput[];

  constructor(
    private equiposService: EquiposService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('equipos.updatejugador');
  }

  ngOnInit(): void {
    this.idOrg = this.route.snapshot.params['idorg'];
    this.myJugador.idEquipo =this.route.snapshot.params['idequipo'];
    this.myJugador.id =this.route.snapshot.params['idjugador'];
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
    });

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0){
        this.selectedIdioma = this.listaIdiomas[0];
        this.equiposService.GetJugadorById(this.myJugador.id,this.selectedIdioma.codigo).subscribe((result)=>{
          this.myJugador = result.data[0];
          this.myJugador.fechaNacimiento = new Date(this.myJugador.fechaNacimiento);
          this.myJugador.fechaFinContrato = new Date(this.myJugador.fechaFinContrato);
          this.filePath = result.data[0].urlFoto;
          this.filePathFondo = result.data[0].urlFondo;
          if(result.data[0].email!=undefined){
            console.log('LOAD Usuario Vinculado =');
            console.log(result.data)
            this.usuarioVincular.email = result.data[0].email;
            this.usuarioVincular.nombreUsuario = result.data[0].nombreUsuario;
            this.isUsuarioVinculado=true;
          }
        });
      }
    });

    this.equiposService.getPlantillaDatosJugador(this.myJugador.id,this.idOrg).subscribe((result)=>{
      console.log('Datos Custom Jugador o Plantilla');
      console.log(result.data);
      this.listaCustomPlantilla = result.data;
    });
  }

  loadJugador(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Jugador Idioma :' + value.codigo);
    this.equiposService
      .GetJugadorById(this.myJugador.id, this.selectedIdioma.codigo)
      .subscribe((result) => {
        console.log(result.data);
        this.myJugador = result.data[0];
      });
  }

   actualizarJugador() {
    console.log('Actualizando Jugador');
    //Validación de los datos
    if(this.filePath!=this.myJugador.urlFoto){
      console.log('Obteniendo en BASE64 Foto...');
      this.myJugador.urlFoto = this.filePath.split(',')[1]; //Borramos cabecera data: base
      this.myJugador.updateFoto=true;
    }
    if(this.filePathFondo!=this.myJugador.urlFondo){
      console.log('Obteniendo en BASE64 Fondo...');
      this.myJugador.urlFondo = this.filePathFondo.split(',')[1]; //Borramos cabecera data: base
      this.myJugador.updateFondo=true;
    }
    console.log('Imagnges OK');
    this.myJugador.idIdioma = this.selectedIdioma.codigo;
    this.myJugador.listaDatosPlantilla = this.listaCustomPlantilla;
    this.equiposService.updatejugador(this.myJugador).subscribe((result) => {
      if (!result.error) {
        this.alertService.success(
          this.translateService.instant('registros.updatesuccess')
        );
        this.router.navigate(['equipos-editar',{idEquipo:this.myJugador.idEquipo, idorg:this.idOrg }]);
      } else {
        this.alertService.error(
          this.translateService.instant('registros.updateerror')
        );
        return;
      }
    });
  }
   btnCancelar(){
    this.router.navigate(['equipos-editar',{idEquipo:this.myJugador.idEquipo, idorg:this.idOrg }]);
  }

  vincularUsuario(){
    console.log("Vinculando Usuario");
    this.myJugador.idUser=this.usuarioVincular.id;
    this.isUsuarioVinculado=true;
    this.equiposService.vinculacionUsuario(this.myJugador).subscribe((result)=>{
      if(result.error)
        this.alertService.error("Error al vincular el usuario");
    });
  }

  desvincularUsuario(){
    console.log("Desvinculando usuario");
    this.myJugador.idUser =0;
    this.isUsuarioVinculado=false;
    this.equiposService.vinculacionUsuario(this.myJugador).subscribe((result)=>{
      if(result.error)
        this.alertService.error("Error al vincular el usuario");
    });
  }

  imagePreview(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    };
    reader.readAsDataURL(file);
  }
  imagePreviewFondo(e) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePathFondo = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  getUsuarios(lista){
    this.usuarioVincular =lista[0];
  }


}
