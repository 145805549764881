import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
//import { Evento,EventoModel, Recurrencia } from '@app/_models/evento';
import {  Organizacion,  OrganizacionModel,  Recurrencia} from '@app/_models/organizacion';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/organizacion`;

@Injectable()
export class OrganizacionesService {
  constructor(private http: HttpClient) {}

  public GetById(id: string): Observable<any> {
    return this.http.get(baseUrl + '/' + id);
  }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  public crearOrganizacion(organizacionModel:OrganizacionModel):Observable<any> {

    
    console.log('Creando Organizacion...');

    return this.http.post(baseUrl+"/crearorg/",JSON.stringify(organizacionModel),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
  }

  public editarOrganizacion(organizacionModel:OrganizacionModel):Observable<any> {

    
    console.log('Actualizando Organizacion...');
    debugger
    console.log(organizacionModel);

    return this.http.post(baseUrl+"/editarorg/",JSON.stringify(organizacionModel),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
  }

  insert(data) {
    console.log(JSON.stringify(data));
    return this.http.post<any>(
      baseUrl + '/crear',
      {
        id:0,
        nombre: data.nombreOrg,
        imagenAvatar: data.logo,
        imagenCabecera: data.cabecera,
        organizacionActiva: data.activoOrganizacion,
        email: data.email,
        smtp: data.smtp,
        puerto: data.puerto!=null?data.puerto:0,
        emailUsuario: data.usuario,
        emailPassword: data.contrasena,
        cambiarPasswordActivo: data.activoGuardarPassword,
        infoNotificacionesActivas: data.activoNotificaciones,
        infoFarmaciasActivas: data.activoFarmacias,
        infoFaqActivo: data.activoFAQ,
        infoGestionDocumentalActiva: data.activoGesDoc,
        infoContactosActivo: data.activoContactos,
        agendaActiva: data.activoAgenda,
        agendaEdadMinima: data.agendaEdadMin!=null?data.agendaEdadMin:0,
        mensajeriaActiva: data.activoMensajeria,
        mensajeriaIncidenciasDepUnico: data.activoIncidenciasDepUnico,
        idDepartamento: data.departamento,
        reservasActivas: data.activoReservas,
        reservasConfirmacion: data.activoConfirmReserva,
        reservasUsuarioEnCalendario: data.activoMostrarUsuarioCalendario,
        reservasDiasMinimos: data.mindiasresrva!=null?data.mindiasresrva:0,
        reservasApertura: data.apertura,
        reservasCorreos: data.correosReservas,
        coordenadasLatitud: data.latitud,
        coordenadasLongitud: data.longitud,
        coordenadasZoom: data.zoom!=null?data.zoom:0,
        agentesActivos: data.activoAgentes,
        agentesTiposActivos: data.activoTipos,
        permUsuariosPublicar: data.permUsuariosPublicar,
        permUsuariosComentar: data.permUsuariosComentar,
        foroActivo: data.activoForo,
        foroEdadMinima: data.edadforo!=null?data.edadforo:0,
        encuestasActivas: data.activoEncuestas,
        encuestasEdadMinima: data.edadencuestasmin!=null?data.edadencuestasmin:0,
        rrssFacebookActivo: data.activoFacebook,
        rrssFacebookUrl: data.fblink,
        rrssTwitterActivo: data.activoTwitter,
        rrssTwitterUrl: data.twlink,
        rrssFlickerActivo: data.activoFlicker,
        rrssFlickerUrl: data.fllink,
        rrssInstagramActivo: data.activoInstagram,
        rrssInstagramUrl: data.iglink,
        rrssYoutubeActivo: data.activoYoutube,
        rrssYoutubeUrl: data.ytlink,
        rrssMultimedia: data.media,
        idIdiomas: 1,
        idCamposIdiomas: 1,
        foroPermUsuariosCrearPost:data.foroPermUsuariosCrearPost
      },
      { withCredentials: true }
    );
  }

  update(data) {
    console.log(JSON.stringify(data));
    return this.http.post<any>(
      baseUrl + '/editar',
      {
        id: data.codigo,
        nombre: data.nombreOrg,
        imagenAvatar: data.logo,
        imagenCabecera: data.cabecera,
        organizacionActiva: data.activoOrganizacion,
        email: data.email,
        smtp: data.smtp,
        puerto: data.puerto,
        emailUsuario: data.usuario,
        emailPassword: data.contrasena,
        cambiarPasswordActivo: data.activoGuardarPassword,
        infoNotificacionesActivas: data.activoNotificaciones,
        infoFarmaciasActivas: data.activoFarmacias,
        infoFaqActivo: data.activoFAQ,
        infoGestionDocumentalActiva: data.activoGesDoc,
        infoContactosActivo: data.activoContactos,
        agendaActiva: data.activoAgenda,
        agendaEdadMinima: data.agendaEdadMin,
        mensajeriaActiva: data.activoMensajeria,
        mensajeriaIncidenciasDepUnico: data.activoIncidenciasDepUnico,
        idDepartamento: data.departamento!=undefined?data.departamento:0,
        reservasActivas: data.activoReservas,
        reservasConfirmacion: data.activoConfirmReserva,
        reservasUsuarioEnCalendario: data.activoMostrarUsuarioCalendario,
        reservasDiasMinimos: data.mindiasresrva,
        reservasApertura: data.apertura,
        reservasCorreos: data.correosReservas,
        coordenadasLatitud: data.latitud,
        coordenadasLongitud: data.longitud,
        coordenadasZoom: data.zoom,
        agentesActivos: data.activoAgentes,
        agentesTiposActivos: data.activoTipos,
        permUsuariosPublicar: data.permUsuariosPublicar,
        permUsuariosComentar: data.permUsuariosComentar,
        foroActivo: data.activoForo,
        foroEdadMinima: data.edadforo,
        encuestasActivas: data.activoEncuestas,
        encuestasEdadMinima: data.edadencuestasmin,
        rrssFacebookActivo: data.activoFacebook,
        rrssFacebookUrl: data.fblink,
        rrssTwitterActivo: data.activoTwitter,
        rrssTwitterUrl: data.twlink,
        rrssFlickerActivo: data.activoFlicker,
        rrssFlickerUrl: data.fllink,
        rrssInstagramActivo: data.activoInstagram,
        rrssInstagramUrl: data.iglink,
        rrssYoutubeActivo: data.activoYoutube,
        rrssYoutubeUrl: data.ytlink,
        rrssMultimedia: data.media,
        idIdiomas: 1,
        idCamposIdiomas: 1,
        foroPermUsuariosCrearPost:data.foroPermUsuariosCrearPost,
      },
      { withCredentials: true }
    );
  }

  delete(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  //Images functions
  deleteImagen(data) {
    return this.http.post<any>(baseUrl + '/editarImagen', {
      codigo: data.codigo,
      imagen: data.imagen,
    });
  }
  updateImagen(data) {
    return this.http.post<any>(baseUrl + '/eliminarImagen', {
      codigo: data.codigo,
      imagen: data.imagen,
    });
  }

  public getIdiomas(data): Observable<any> {
    return this.http.post<any>(baseUrl + '/idiomas', { id: data.id });
  }

//PopUps Gestión
public getAdminsFromEnte(id:string): Observable<any> {
  return this.http.get(baseUrl + '/admins/'+id);
}

updateAdmins(listaAdmins,idOrg):Observable<any>{
  console.log('Updating admins from ente...:');
  return this.http.post<any>(
    baseUrl + '/admins/update/'+idOrg,
    JSON.stringify(listaAdmins),
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    }
  );
  }

  public getAllWithoutFather(id:string): Observable<any> {
    return this.http.get(baseUrl + '/allwithoutparent/'+id);
  }

  updateSubAgentes2(listaAgents,idOrg):Observable<any>{
    console.log('Updating subagentes from org...:');
    return this.http.post<any>(
      baseUrl + '/editarsubs/'+idOrg,
      JSON.stringify(listaAgents),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );

    
  }

}
