import { ModuloUrlComponent } from './menu-main/modulo-url/modulo-url.component';
import { NgModule, APP_INITIALIZER,LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { CommonModule, DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/* SERVICES */
import {
  UsuariosService,
  MenuService,
  UsuariosTiposService,
  ContactosService,
  TransportesService,
  EnlacesService,
  RecorridosService,
  DepartamentosService,
  ConfiguracionService,
  ForoService,
  RssService,
} from '@app/_services';
import { ContactosenteService } from './_services/contactosente.service';
import { PublicacionesService } from './_services/publicaciones.service';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { AgentesService } from '@app/_services/agentes.service';
import { ColaboradoresService } from '@app/_services/colaboradores.service';
import { DepartamentosEnteService } from '@app/_services/departamentosente.service';
import { EventosService } from '@app/_services/eventos.service';
import { RecintosService } from '@app/_services/recintos.service';
import { RecorridosEnteService } from '@app/_services/recorridos-ente.service';
import { EcommerceService } from "@app/_services/ecommerce.service";
import { MensajeriaService } from "@app/_services/mensajeria.service";
import { FarmaciasService } from "@app/_services/farmacias.service";
import { CensoService } from "@app/_services/censo.service";
import { RestauranteService } from "@app/_services/restaurante.service";
import { MainMenuSerivice } from "@app/_services/menu-main.service";
import { EquiposService } from "@app/_services/equipos.service";
import  {EquiposPlantillaService} from '@app/_services/equipos-plantilla.service';
//Angular
import '@progress/kendo-angular-intl/locales/es/all';
import {
  DropDownsModule,
  MultiSelectModule,
  ComboBoxModule
} from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { EditorModule } from "@progress/kendo-angular-editor";
import { TreeViewModule } from '@progress/kendo-angular-treeview';

import 'hammerjs';
//Langs
import { load, IntlModule } from '@progress/kendo-angular-intl';
import { registerLocaleData } from '@angular/common';
import localeEU from '@angular/common/locales/eu';
import localeES from '@angular/common/locales/es';
import localeEN from '@angular/common/locales/en';
import "@progress/kendo-angular-intl/locales/bg/all";
import "@progress/kendo-angular-intl/locales/eu/calendar";

registerLocaleData(localeEU);
registerLocaleData(localeES);
registerLocaleData(localeEN);
//Kendo Langs
import '@progress/kendo-angular-intl/locales/en-GB/all';
import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/eu/all';

//Paginas
import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { ContactosComponent } from './contactos/contactos.component';
import { ContactosDetalleComponent } from './contactos/contactos-detalle/contactos-detalle.component';
import { TransportesComponent } from './transportes/transportes.component';
import { TransportesDetalleComponent } from './transportes/transportes-detalle/transportes-detalle.component';
import { EnlacesComponent } from './enlaces/enlaces.component';
import { EnlacesDetalleComponent } from './enlaces/enlaces-detalle/enlaces-detalle.component';
import { RecorridosComponent } from './recorridos/recorridos.component';
import { RecorridosDetalleComponent } from './recorridos/recorridos-detalle/recorridos-detalle.component';
import { DepartamentosComponent } from './departamentos/departamentos.component';
import { DepartamentosDetalleComponent } from './departamentos/departamentos-detalle/departamentos-detalle.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { PruebaComponent } from './prueba/prueba.component';
import { PatrocinadoresComponent } from './patrocinadores/patrocinadores.component';

import { OrganizacionesComponent } from './organizaciones/organizaciones.component';
import { OrganizacionesDetalleComponent } from './organizaciones/organizaciones-detalle/organizaciones-detalle.component';
import { OrganizacionesCrearComponent } from './organizaciones/organizaciones-crear/organizaciones-crear.component';
import { OrganizacionesEditarComponent } from './organizaciones/organizaciones-editar/organizaciones-editar.component';

import { ConfiguracionInicialComponent } from './configuracion-inicial/configuracion-inicial.component';
import { AgentesComponent } from './agentes/agentes.component';
import { AgentesdetalleComponent } from './agentes/agentesdetalle/agentesdetalle.component';
import { AgentesCrearComponent } from './agentes/agentes-crear/agentes-crear.component';
import { AgentesEditarComponent } from './agentes/agentes-editar/agentes-editar.component';

import { ColaboradoresComponent } from './colaboradores/colaboradores.component';
import { ColaboradoresdetalleComponent } from './colaboradores/colaboradoresdetalle/colaboradoresdetalle.component';
import { ColaboradoresCrearComponent } from './colaboradores/colaboradores-crear/colaboradores-crear.component';
import { ColaboradoresEditarComponent } from './colaboradores/colaboradores-editar/colaboradores-editar.component';


import { AgentesTiposComponent } from './agentes-tipos/agentes-tipos.component';
import { AgentesTiposDetallesComponent } from './agentes-tipos/agentes-tipos-detalles/agentes-tipos-detalles.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DepartamentosEnteComponent } from './departamentos-ente/departamentos-ente.component';
import { DepartamentosEnteDetallesComponent } from './departamentos-ente/departamentos-ente-detalles/departamentos-ente-detalles.component';
import { DepartamentosEnteCrearComponent } from './departamentos-ente/departamentos-ente-crear/departamentos-ente-crear.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ContactosEnteComponent } from './contactos-ente/contactos-ente.component';
import { ContactosEnteDetallesComponent } from './contactos-ente/contactos-ente-detalles/contactos-ente-detalles.component';
import { PatrocinadoresMultinivelComponent } from './patrocinadores-multinivel/patrocinadores-multinivel.component';
import { ContactosEnteCrearComponent } from './contactos-ente/contactos-ente-crear/contactos-ente-crear.component';
import { PublicacionesComponent } from './publicaciones/publicaciones.component';
import { EventosComponent } from './eventos/eventos.component';
import { EventosCrearComponent } from './eventos/eventos-crear/eventos-crear.component';
import { EncuestasComponent } from './encuestas/encuestas.component';
import { EncuestasCrearComponent } from './encuestas/encuestas-crear/encuestas-crear.component';
import { PublicacionesListaComponent } from './publicaciones/publicaciones-lista/publicaciones-lista.component';
import { PublicacionesEditarComponent } from './publicaciones/publicaciones-editar/publicaciones-editar.component';
import { EventosEditarComponent } from './eventos/eventos-editar/eventos-editar.component';
import { EncuestasEditarComponent } from './encuestas/encuestas-editar/encuestas-editar.component';
import { CategoriasPublicacionesComponent } from './publicaciones/categorias/categorias-publicaciones/categorias-publicaciones.component';
import { CategoriasPublicacionesCrearComponent } from './publicaciones/categorias/categorias-publicaciones-crear/categorias-publicaciones-crear.component';
import { CategoriasPublicacionesEditarComponent } from './publicaciones/categorias/categorias-publicaciones-editar/categorias-publicaciones-editar.component';
import { CategoriasEventosComponent } from './eventos/categorias/categorias-eventos/categorias-eventos.component';
import { CategoriasEventosCrearComponent } from './eventos/categorias/categorias-eventos-crear/categorias-eventos-crear.component';
import { CategoriasEventosEditarComponent } from './eventos/categorias/categorias-eventos-editar/categorias-eventos-editar.component';
import { CategoriasEncuestasComponent } from './encuestas/categorias/categorias-encuestas/categorias-encuestas.component';
import { CategoriasEncuestasCrearComponent } from './encuestas/categorias/categorias-encuestas-crear/categorias-encuestas-crear.component';
import { CategoriasEncuestasEditarComponent } from './encuestas/categorias/categorias-encuestas-editar/categorias-encuestas-editar.component';
import { DropdownIdiomasEnteComponent } from './reusables/dropdown-idiomas-ente/dropdown-idiomas-ente.component';
import { TestReusableComponent } from './reusables/test-reusable/test-reusable.component';
import { AppCrearComponent } from '@app/patrocinadores-multinivel/app-crear/app-crear.component';
import { AppEditarComponent } from '@app/patrocinadores-multinivel/app-editar/app-editar.component';
import { RecintosComponent } from './recintos/recintos/recintos.component';
import { RecintosCrearComponent } from './recintos/recintos-crear/recintos-crear.component';
import { RecintosEditarComponent } from './recintos/recintos-editar/recintos-editar.component';
import { SalasComponent } from './salas/salas/salas.component';
import { SalasCrearComponent } from './salas/salas-crear/salas-crear.component';
import { SalasEditarComponent } from './salas/salas-editar/salas-editar.component';
import { CalendarioReservasComponent } from './reservas/calendario-reservas/calendario-reservas.component';
import { CalendarioGestionReservasComponent } from './reservas/calendario-gestion-reservas/calendario-gestion-reservas.component';
import { ReservasCrearComponent } from './reservas/reservas-crear/reservas-crear.component';
import { ReservasEditarComponent } from './reservas/reservas-editar/reservas-editar.component';
import { SelectorUsuariosComponent } from './reusables/selector-usuarios/selector-usuarios.component';
import { FarmaciasComponent } from './farmacias/farmacias/farmacias.component';
import { RecorridosEnteComponent } from '@app/recorridos-ente/recorridos-ente/recorridos-ente.component';
import { RecorridosCrearComponent } from '@app/recorridos-ente/recorridos-crear/recorridos-crear.component';
import { RecorridosEditarComponent } from '@app/recorridos-ente/recorridos-editar/recorridos-editar.component';
import { ArticulosComponent } from './ecommerce/articulos/articulos.component';
import { ArticulosCrearComponent } from './ecommerce/articulos-crear/articulos-crear.component';
import { ArticulosEditarComponent } from './ecommerce/articulos-editar/articulos-editar.component';
import { CategoriasArticulosComponent } from './ecommerce/categorias-articulos/categorias-articulos.component';
import { CategoriasArticulosCrearComponent } from './ecommerce/categorias-articulos-crear/categorias-articulos-crear.component';
import { CategoriasArticulosEditarComponent } from './ecommerce/categorias-articulos-editar/categorias-articulos-editar.component';
import { PeticionesComponent } from "./membresia/peticiones/peticiones.component";
import { PeticionMensajeComponent } from './membresia/peticion-mensaje/peticion-mensaje.component';
import { SorteoComponent } from './publicaciones/sorteo/sorteo.component';
import { ReservasMesasComponent } from './restaurante/reservas-mesas/reservas-mesas.component';
import { ReservasComponent } from './restaurante/reservas/reservas.component';
import { ComidastipoComponent } from './restaurante/comidastipo/comidastipo.component';
import { ComidastipoCrearComponent } from './restaurante/comidastipo/comidastipo-crear/comidastipo-crear.component';
import { ComidastipoEditarComponent } from './restaurante/comidastipo/comidastipo-editar/comidastipo-editar.component';
import { RestaurantesComponent } from './restaurante/restaurantes/restaurantes.component';
import { ResturanteCrearComponent } from './restaurante/restaurantes/resturante-crear/resturante-crear.component';
import { ResturanteEditarComponent } from './restaurante/restaurantes/resturante-editar/resturante-editar.component';
import { MesasComponent } from './restaurante/mesas/mesas.component';
import { MesasDetalleComponent } from './restaurante/mesas/mesas-detalle/mesas-detalle.component';
import { ComedoresComponent } from './restaurante/comedores/comedores.component';
import { ComedoresDetalleComponent } from './restaurante/comedores/comedores-detalle/comedores-detalle.component';
import { ReservaAdminComponent } from './restaurante/reserva-admin/reserva-admin.component';
import { ReservaComponent } from './restaurante/reserva-admin/reserva/reserva.component';
import { ConfiguracionRestauranteComponent } from './restaurante/configuracion-restaurante/configuracion-restaurante.component';
import {  GridTraduccionesComponent } from "./genericos/grid-traducciones/grid-traducciones.component";
import { MesasCrearComponent } from './restaurante/mesas/mesas-crear/mesas-crear.component';
import { MesasEditarComponent } from './restaurante/mesas/mesas-editar/mesas-editar.component';
import { CalendariosRestauranteComponent } from './restaurante/calendario/calendarios-restaurante/calendarios-restaurante.component';
import { SortListamesasComponent } from "./reusables/sort-listamesas/sort-listamesas.component";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { FilterModule } from '@progress/kendo-angular-filter';
import { EcommerceConfiguracionComponent } from './ecommerce/ecommerce-configuracion/ecommerce-configuracion.component';
import { MenuMainComponent } from './menu-main/menu-main.component'
import { ModuloHtmlComponent } from './menu-main/modulo-html/modulo-html.component'
import { ModuloDirectoComponent } from './menu-main/modulo-directo/modulo-directo.component';
import { CreateModuloComponent } from './menu-main/create-modulo/create-modulo.component'
import { ModuloCategoriaComponent } from './menu-main/modulo-categoria/modulo-categoria.component'
import { FiltroFechasComponent } from './reusables/filtro-fechas/filtro-fechas.component';
//Angular Material
import {MatInputModule} from '@angular/material/input';
import { ModuloService } from './_services/modulos.service';
import { VideosComponent } from './videos/videos.component';
import { MenuModulosComponent } from './disenos/menu-modulos/menu-modulos.component';
import { AccesosDirectosComponent } from './disenos/accesos-directos/accesos-directos.component';
import { ModulosEditarComponent } from './disenos/modulos-editar/modulos-editar.component';
import { EquiposComponent } from './equipos/equipos/equipos.component';
import { JugadoresComponent } from './equipos/jugadores/jugadores.component';
import { EquiposCrearComponent } from './equipos/equipos-crear/equipos-crear.component';
import { EquiposEditarComponent } from './equipos/equipos-editar/equipos-editar.component';
import { JugadoresEditarComponent } from './equipos/jugadores-editar/jugadores-editar.component';
import { JugadoresPlantillaComponent } from './jugadores/jugadores-plantilla/jugadores-plantilla.component';
import { TemporadasComponent } from './equipos/temporadas/temporadas.component';
import { PlantillasComponent } from './jugadores/plantillas/plantillas.component';
import { PlantillaEditarComponent } from './jugadores/plantilla-editar/plantilla-editar.component';
import { GridUsuariosComponent } from './reusables/grid-usuarios/grid-usuarios.component';
import { TemporadaEditarComponent } from './equipos/temporadas/temporada-editar/temporada-editar.component';
import { PartidosCrearComponent } from './equipos/partidos/partidos-crear/partidos-crear.component';
import { InscripcionesEventoComponent } from './eventos/inscripciones-evento/inscripciones-evento.component';
import { ForoTopicsComponent } from './foro/foro-topics/foro-topics.component';
import { ForoTopicsCrearComponent } from './foro/foro-topics-crear/foro-topics-crear.component';
import { ForoTopicsEditarComponent } from './foro/foro-topics-editar/foro-topics-editar.component';
import { ForoPostsComponent } from './foro/foro-posts/foro-posts.component';
import { ForoPostCrearComponent } from './foro/foro-post-crear/foro-post-crear.component';
import { RssComponent } from './rss/rss.component';
import { RssCrearComponent } from './rss/rss-crear/rss-crear.component';
import { RssEditarComponent } from './rss/rss-editar/rss-editar.component';

//import { TestComponent } from './test/test.component';
//import { ListaUsuariosService } from './_services/listaUsuarios.service';


@NgModule({
  imports: [
    BrowserModule,
    IntlModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    SortableModule, 
    MultiSelectModule,
    //IDIOMAS
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GridModule,
    ComboBoxModule,
    DateInputsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    DropDownsModule,
    InputsModule,
    IconsModule,
    ButtonsModule,
    FormsModule,
    LabelModule,
    NgbModule,
    UploadsModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    LayoutModule,
    IconsModule,
    PopupModule,
    DialogsModule,
    ListViewModule,
    SchedulerModule,
    EditorModule,
    IconsModule,
    DragDropModule,
    FilterModule,
    MatInputModule,
    TreeViewModule,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    UsuariosComponent,
    EditarCrearUsuarioComponent,
    UsuariosTiposComponent,
    EditarCrearUsuarioTipoComponent,
    ContactosComponent,
    ContactosDetalleComponent,
    TransportesComponent,
    TransportesDetalleComponent,
    DetailsComponent,
    UpdateComponent,
    EnlacesComponent,
    EnlacesDetalleComponent,
    RecorridosComponent,
    RecorridosDetalleComponent,
    DepartamentosComponent,
    DepartamentosDetalleComponent,
    ConfiguracionComponent,
    PruebaComponent,
    PatrocinadoresComponent,
    OrganizacionesComponent,
    OrganizacionesDetalleComponent,
    OrganizacionesCrearComponent,
    OrganizacionesEditarComponent,
    ConfiguracionInicialComponent,
    AgentesComponent,
    AgentesdetalleComponent,
    AgentesCrearComponent,
    AgentesEditarComponent,
    ColaboradoresComponent,
    ColaboradoresdetalleComponent,
    ColaboradoresCrearComponent,
    ColaboradoresEditarComponent,
    AgentesTiposComponent,
    AgentesTiposDetallesComponent,
    DepartamentosEnteComponent,
    DepartamentosEnteDetallesComponent,
    DepartamentosEnteCrearComponent,
    ContactosEnteComponent,
    ContactosEnteDetallesComponent,
    PatrocinadoresMultinivelComponent,
    ContactosEnteCrearComponent,
    PublicacionesComponent,
    EventosComponent,
    EventosCrearComponent,
    EncuestasComponent,
    EncuestasCrearComponent,
    PublicacionesListaComponent,
    PublicacionesEditarComponent,
    EventosEditarComponent,
    EncuestasEditarComponent,
    CategoriasPublicacionesComponent,
    CategoriasPublicacionesCrearComponent,
    CategoriasPublicacionesEditarComponent,
    CategoriasEventosComponent,
    CategoriasEventosCrearComponent,
    CategoriasEventosEditarComponent,
    CategoriasEncuestasComponent,
    CategoriasEncuestasCrearComponent,
    CategoriasEncuestasEditarComponent,
    DropdownIdiomasEnteComponent,
    TestReusableComponent,
    AppCrearComponent,
    AppEditarComponent,
    RecintosComponent,
    RecintosCrearComponent,
    RecintosEditarComponent,
    SalasComponent,
    SalasCrearComponent,
    SalasEditarComponent,
    CalendarioReservasComponent,
    CalendarioGestionReservasComponent,
    ReservasCrearComponent,
    ReservasEditarComponent,
    SelectorUsuariosComponent,
    FarmaciasComponent,
    RecorridosEnteComponent,
    RecorridosCrearComponent,
    RecorridosEditarComponent,
    ArticulosComponent,
    ArticulosCrearComponent,
    ArticulosEditarComponent,
    CategoriasArticulosComponent,
    CategoriasArticulosCrearComponent,
    CategoriasArticulosEditarComponent,
    PeticionesComponent,
    PeticionMensajeComponent,
    SorteoComponent,
    ReservasMesasComponent,
    ReservasComponent,
    ComidastipoComponent,
    ComidastipoCrearComponent,
    ComidastipoEditarComponent,
    RestaurantesComponent,
    ResturanteCrearComponent,
    ResturanteEditarComponent,
    MesasComponent,
    MesasDetalleComponent,
    ComedoresComponent,
    ComedoresDetalleComponent,
    ReservaAdminComponent,
    ReservaComponent,
    ConfiguracionRestauranteComponent,
    GridTraduccionesComponent,
    MesasCrearComponent,
    MesasEditarComponent,
    CalendariosRestauranteComponent,
    SortListamesasComponent,
    EcommerceConfiguracionComponent,
    VideosComponent,
    MenuModulosComponent,
    AccesosDirectosComponent,
    ModulosEditarComponent,
    MenuMainComponent,
    ModuloHtmlComponent,
    ModuloDirectoComponent,
    ModuloUrlComponent,
    CreateModuloComponent,
    ModuloCategoriaComponent,
    EquiposComponent,
    JugadoresComponent,
    EquiposCrearComponent,
    EquiposEditarComponent,
    JugadoresEditarComponent,
    FiltroFechasComponent,
    JugadoresPlantillaComponent,
    TemporadasComponent,
    PlantillasComponent,
    PlantillaEditarComponent,
    GridUsuariosComponent,
    TemporadaEditarComponent,
    PartidosCrearComponent,
    InscripcionesEventoComponent,
    ForoTopicsComponent,
    ForoTopicsCrearComponent,
    ForoTopicsEditarComponent,
    ForoPostsComponent,
    ForoPostCrearComponent,
    RssComponent,
    RssCrearComponent,
    RssEditarComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [UsuariosService],
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MenuService,
    UsuariosTiposService,
    ContactosService,
    TransportesService,
    EnlacesService,
    RecorridosService,
    DepartamentosService,
    DepartamentosEnteService,
    ConfiguracionService,
    OrganizacionesService,
    IdiomasService,
    AgentesService,
    ColaboradoresService,
    ContactosenteService,
    PublicacionesService,
    EventosService,
    RecintosService,
    RecorridosEnteService,
    EcommerceService,
    MensajeriaService,
    FarmaciasService,
    CensoService,
    RestauranteService,
    DatePipe,
    ModuloService,
    MainMenuSerivice,
    EquiposService,
    EquiposPlantillaService,
    ForoService,
    RssService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
