<kendo-grid
[kendoGridBinding]="listaTemporadas"
[sortable]="true"
[pageable]="true"
[pageSize]="14"
kendoGridSelectBy="id"
[navigable]="true"
filterable="menu"
(cellClick)="cellClick($event)"
[(selectedKeys)]="seleccionados"
>
<ng-template kendoGridNoRecordsTemplate>
{{ "equipos.sintemporadas" | translate }}

</ng-template>
<ng-template kendoGridToolbarTemplate position="top">
  <button
    kendoButton
    (click)="onClickEditar()"
    class="btn mr-1 btn-success btn-sm mr-1"
  >
    {{ "botones.editar" | translate }}
  </button>
  <button
    kendoButton
    (click)="onClickNuevo()"
    class="btn mr-1 btn-primary btn-sm mr-1"
  >
    {{ "botones.nuevo" | translate }}
  </button>
  <button
    kendoButton
    (click)="onClickEliminar(content)"
    class="btn mr-1 btn-danger btn-sm mr-1"
    [disabled]="isDeleting"
  >
    {{ "botones.eliminar" | translate }}
  </button>
</ng-template>
<kendo-grid-checkbox-column
  width="5%"
  showSelectAll="true"
></kendo-grid-checkbox-column>

<kendo-grid-column
  width="20%"
  [style]="{ 'text-align': 'left' }"
  field="nombre"
  title="{{ 'equipos.nombretemp' | translate }}"
>
</kendo-grid-column>
</kendo-grid>

<button
kendoButton
(click)="goBack()"
class="btn mr-1 btn-info btn-sm mr-1"
>
{{ "botones.atras" | translate }}
</button>